<template>
	<div class="NavBar" style="pointer-events: none;">
		<div>
<!-- TODO: move styles to style block -->
			<div style="position: absolute; inset: 0; right: auto; width: 300px; height: 100%; display: flex; gap: 8px; align-items: center; padding: 15px; background: white; pointer-events: all;">
				<v-avatar size="32px" :color="color">
					<v-img
						:src="this.selectedComponent?.fields?.icon?.de?.fields?.file?.de?.url ?? ''"
						style="width: 20px; height: 20px; opacity: 0.5; margin: 10px;"
					/>
				</v-avatar>
				<div>
					<div style="font-size: 9.5pt !important; color: #999999; line-height: 11px;">
						MyServices
						<span style="opacity: 0.5" :title="$store.getters.featureBranch">{{this.version}}</span>
					</div>
					<div style="white-space: nowrap; width: 180px; overflow: hidden; text-overflow: ellipsis;" :style="[componentStyle]">{{ getComponentName() }}</div>
				</div>
				<v-spacer/>
				<v-icon v-if="!isStandalone" color="#aaa" style="cursor:pointer" @click="showDashboard">mdi-view-grid</v-icon>
			</div>

			<!-- Profile / Logout / Language Menu -->
			<div v-if="isStandalone" id="profileComponent" style="position: absolute; inset: 0; left: auto; width: 64px; height: 64px; background: white; pointer-events: all; padding: 18px 22px;">
				<v-icon color="#666" style="cursor:pointer" @click="$router.push('/')">mdi-logout</v-icon>
			</div>
			<div v-if="!isStandalone" id="profileComponent" style="position: absolute; inset: 0; left: auto; width: 64px; height: 64px; background: white; pointer-events: all;">
				<v-menu style="z-index:1000">
					<template v-slot:activator="{ props }">
						<div>
							<v-btn v-bind="props" id="btnUserProfile" icon elevation="0" style="background:none !important; border:0 !important; margin-left: 10px; margin-right: -10px; margin-top: 7px;">
								<img src="@/assets/icons/user.svg" width="28" height="28" style="cursor: pointer; border-radius: 20px; outline: 0 dotted rgb(255, 51, 102);"
									:style="{
										'outline-width': tokenExpiresInSeconds < 600 ? '4px' : '0px',
									}"
								/>
							</v-btn>
						</div>
					</template>
					<v-list>
						<v-list-item style="cursor: pointer; background: rgb(255, 51, 102); color: white;" v-if="tokenExpiresInSeconds < 600">
							<v-icon color="white">mdi-clock</v-icon>
							logout in {{ tokenExpiresInSeconds }} sec
						</v-list-item>
						<v-list-item @click="setLocale('de')" v-if="localeVisible">
							<template #prepend>
								<LanguageFlag modelValue="de" style="margin-right:5px" />
							</template>
							Deutsch
						</v-list-item>
						<v-list-item @click="setLocale('en')" v-if="localeVisible">
							<template #prepend>
								<LanguageFlag modelValue="en" style="margin-right:5px" />
							</template>
							English
						</v-list-item>
						<v-list-item @click="setLocale('fr')" v-if="localeVisible">
							<template #prepend>
								<LanguageFlag modelValue="fr" style="margin-right:5px" />
							</template>
							Français
						</v-list-item>
						<v-list-item @click="setLocale('it')" v-if="localeVisible">
							<template #prepend>
								<LanguageFlag modelValue="it" style="margin-right:5px" />
							</template>
							Italiano
						</v-list-item>
						<v-list-item @click="setLocale('nl')" v-if="localeVisible">
							<template #prepend>
								<LanguageFlag modelValue="nl" style="margin-right:5px" />
							</template>
							Nederlands
						</v-list-item>

						<v-divider v-if="haveUser" />
						<v-list-item class="accountAction" style="cursor: default;" v-if="haveUser && this.loggedInUser?.fields" data-cy="userEmail">
							<v-icon>mdi-account</v-icon>
							{{ this.$store.state.loggedInUser.fields.email.de }}
						</v-list-item>
						<v-list-item class="accountAction" v-if="haveUser && userIsImpersonating" @click="exitImpersonation()" data-cy="isImpersonation">
							<v-icon>mdi-drama-masks</v-icon>
							AS: {{ this.$store.state.selectedServiceProvider?.fields?.title?.de }}
							<v-icon style="float: right;">mdi-logout</v-icon>
						</v-list-item>
						<!--
							TODO: also set the linked sp do this!
							v-if="item.addl && item.addl.isHomebase && item.fields.mainAccountId && item.fields.mainAccountId.de"
						-->
						<v-list-item class="accountAction" v-if="canImpersonate" @click="impersonate($store.state.selectedServiceProvider)" data-cy="enterImpersonation">
							<v-icon>mdi-login</v-icon>
							OP AS: {{ this.$store.state.selectedServiceProvider?.fields?.title?.de }}
						</v-list-item>

						<v-divider v-if="haveUser" />
						<v-list-item v-if="haveUser" @click="showChangePasswordDialog()">
							<v-icon>mdi-key</v-icon>
							{{ $t('text.changePassword') }}
						</v-list-item>
						<v-list-item @click="openUrl('https://www.alturos.com/')">
							<v-icon>mdi-text-box-outline</v-icon>
							Imprint
						</v-list-item>
						<v-list-item @click="openUrl('http://www.alturos.com/privacy-statement/')">
							<v-icon>mdi-text-box-outline</v-icon>
							Privacy Policy
						</v-list-item>

						<v-list-item id="logout" data-cy="logout" v-if="haveUser" @click="logout()">
							<v-icon>mdi-lock</v-icon>
							{{ $t('text.logout') }}
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

      <div v-show="showChangePassword" class="dialogDiv">
        <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" closable flat class="notification" prominent v-model="errorAlertVisible"><span v-html="errorMessages"></span></v-alert>
        <v-alert :value="successTitle!==''" :v-if="successTitle!==''" type="success" closable flat class="notification" prominent v-model="successAlertVisible"><span v-html="successMessages"></span></v-alert>

        <v-form>
          <v-row>
            <v-col>
              <v-label><h3>{{$t('text.changePassword')}}</h3></v-label><br/>
              <v-form>
                  <v-label>{{$t('text.currentPassword')}}</v-label>
					<input type="text" style="display: none;" name="username" autocomplete="username" />
                  <v-text-field type="password" variant="outlined" density="compact"
                    v-model="currentPassword"
                    autocomplete="off"
                    :error-messages="currentPasswordErrors"
                    @update:modelValue="v$.currentPassword.$touch()"
                    @blur="v$.currentPassword.$touch()"
                    required></v-text-field>

                  <v-label>{{$t('text.newPassword')}}</v-label>
                  <v-text-field type="password" variant="outlined" density="compact"
                    v-model="password"
                    autocomplete="new-password"
                    :error-messages="passwordErrors"
                    @update:modelValue="v$.password.$touch()"
                    @blur="v$.password.$touch()"
                    required></v-text-field>

                  <v-label>{{$t('text.repeatPassword')}}</v-label>
                  <v-text-field type="password" variant="outlined" density="compact"
                    v-model="repeatPassword"
                    autocomplete="new-password"
                    :error-messages="repeatPasswordErrors"
                    @update:modelValue="v$.repeatPassword.$touch()"
                    @blur="v$.repeatPassword.$touch()"
                    required sameAs></v-text-field>
                </v-form>
              <v-row style="margin-left:5px">
                <v-btn id="btnCancel" class="gradientButton" elevation="0" style="margin-right:10px" @click="showChangePassword=false">{{$t('text.cancel')}}</v-btn>
                <v-btn id="btnChangePassword" class="gradientButton" elevation="0" :disabled="currentPassword===''||password===''||repeatPassword===''" @click="changePassword()">{{$t('text.changePassword')}}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
		</div>

    <!-- System Bar - Impersonation -->
    <v-system-bar v-if="showImpersonationInfo && !showSystemNotification()" color="rgb(0, 174, 239)" class="system-bar" style="top: auto; bottom: 0;">
          <v-spacer></v-spacer>      
          <v-icon class="text-white" style="margin-right: 4px;">mdi-drama-masks</v-icon>
          <span class="text-white"> 
            {{ $t('text.currentlyViewingAs') }} 
            {{ $t('text.serviceProvider') }} 
            {{ impersonatedName }}
            ({{ impersonatedEmail }})
          </span>
          <v-spacer></v-spacer>
    </v-system-bar>

     <!-- System Bar - System Notification -->
    <v-system-bar v-if="showSystemNotification()" color="#f27826" class="system-bar">
          <v-spacer></v-spacer>      
          <v-icon class="text-white">mdi-alert-circle</v-icon>
          <span class="text-white"> 
            {{ systemNotification[selectedLocale] }}
          </span>
          <v-spacer></v-spacer>
    </v-system-bar>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import Common from '@/mixins/Common.vue'
import TokenAware from '@/mixins/TokenAware.vue'
import LanguageFlag from './LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import { version } from '../../../package.json'

export default {
	components: { LanguageFlag, Dialog },
	name: 'NavBar',
	mixins: [ Common, TokenAware ],
	validations() {
		return {
			currentPassword: { required, minLength: minLength(8) },
			password: { required, minLength: minLength(8) },
			repeatPassword: { required, minLength: minLength(8), sameAsPassword: sameAs(this.password) },
		}
	},
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	data() { return {
		version: '1.0.0',
		haveComponent: false,
		haveUser: false,
		showChangePassword: false,
		currentPassword: '',
		password: '',
		repeatPassword: '',

		title: '',

		errorTitle: '',
		errorDetail: '',
		errorAlertVisible: false,
		successTitle: '',
		successDetail: '',
		successAlertVisible: false,

		componentStyle: {
			color: "#999999",
			fontWeight: "bold",
			fontSize: "13pt !important"
		},

		color: '#bfbfbf',
		icon: '',
		selectedComponent: {},
		user: {},
		systemNotification: null,
	}},
  computed: {
    impersonatedName() {
      return this.$store.state.impersonatedServiceProvider?.fields?.title?.[this.selectedLocale]
    },
    impersonatedEmail() {
      return this.$store.state.impersonatedServiceProvider?.fields?.mainUserAccount?.fields?.email?.de
    },
    canImpersonate() {
      let impersonateEnabled = false

      if (this.haveUser &&
          !this.userIsImpersonating &&
          this.userIsHomeOperator &&
          (this.$store.state.selectedServiceProvider?.fields?.mainAccountId?.de ||
          this.$store.state.selectedServiceProvider?.fields?.userAccount?.de?.sys?.id)) {

          impersonateEnabled = true

      }

      return impersonateEnabled
    },
    localeVisible() {
      return this.$route.path === '/' || this.$route.path === '/dashboard' || this.$route.path === '/registration' || this.$route.path === '/forgotPassword'
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail
    },
    componentColour() {
      return this.selectedComponent.color
    },
    currentPasswordErrors() {
      const errors = []
      const { $dirty, required, minLength } = this.v$.currentPassword

      if (!$dirty) return errors

      if (required.$invalid) errors.push(this.$t('text.passwordRequired'))
      if (minLength.$invalid) errors.push(this.$t('text.passwordMinChars'))

      return errors
    },
    passwordErrors() {
      const errors = []
      const { $dirty, required, minLength } = this.v$.password

      if (!$dirty) return errors

      if (required.$invalid) errors.push(this.$t('text.newPasswordRequired'))
      if (minLength.$invalid) errors.push(this.$t('text.newPasswordMinChars'))

      return errors
    },
    repeatPasswordErrors() {
      const errors = []
      const { $dirty, sameAsPassword } = this.v$.repeatPassword

      if (!$dirty) return errors

      if (sameAsPassword.$invalid) errors.push(this.$t('text.repeatPasswordSame'))
      if (required.$invalid) errors.push(this.$t('text.repeatPasswordRequired'))
      if (minLength.$invalid) errors.push(this.$t('text.repeatPasswordMinChars'))

      return errors
    },
		loggedInUser() {
			return this.$store.state.loggedInUser
		},
		isStandalone() {
			return this.$route.path.includes('/sa-')
		},
	},
	watch: {
		'$store.state.selectedLocale': function() {
			this.selectedLocale = this.$store.state.selectedLocale
		},
		'$store.state.selectedComponent': function() {
			this.selectedComponent = this.$store.state.selectedComponent
		},
		selectedComponent() {
			this.haveComponent = this.selectedComponent?.fields
			this.color = this.selectedComponent?.fields?.colour?.de ?? '#bfbfbf'
			this.componentStyle.color = this.selectedComponent?.fields?.colour?.de ?? '#bfbfbf'
			this.icon = this.selectedComponent?.fields ? this.getAssetUrl(this.selectedComponent.fields.icon.de) : ''
		},
		errorAlertVisible() {
			if (!this.errorAlertVisible) return
			setTimeout(this.closeAlert, 3000)
		},
		successAlertVisible() {
			if (!this.successAlertVisible) return
			setTimeout(this.closeAlert, 3000)
		},
		loggedInUser() { this.updateUserState() },
	},
	methods: {
		async setLocale(locale) {
			this.$root.$i18n.locale = locale
			this.$vuetify.locale.current = locale
			await this.$store.commit('setSelectedLocale', locale)
		},
		showChangePasswordDialog() {
			this.currentPassword = "";
			this.password = "";
			this.repeatPassword = "";
			this.showChangePassword = true;
		},
		async changePassword() {
			this.v$.$touch();

			this.isLoading = true;
			let emailAddress = this.$store.state.isImpersonation ? this.$store.state.selectedServiceProvider.fields.mainUserAccount.email : this.loggedInUser.fields.email.de

			var data = {
				email: emailAddress,
				currentPassword: this.currentPassword,
				password: this.password
			}

			try {
				const res = await this.$httpPost('/reset-password', data)

				this.successTitle = this.$t('text.PASSWORD_CHANGED');
				this.successDetail = this.$t('text.passwordChangeSuccess');

				this.successAlertVisible = true;
			}
			catch (error) {
				//this.showError(this.$t('text.passwordChangeError'))
			}
		},
		async getSystemNotification() {
			try {
				this.systemNotification = await this.$httpGet('/system-notification')
			}
			catch (error) {
				// silent ignore
			}
		},
		showSystemNotification() {
			return this.systemNotification && this.$route.path === '/dashboard'
		},
		async logout() {
			try {
				await this.$httpPost('/logout')
			}
			catch (error) {}

			if (this.$route.path !== "/") {
				this.$router.push("/")
			}

			this.haveUser = false;
			this.$emit("show-login", '');
		},
		showDashboard() {
			this.$emit("show-dashboard")
		},
		showComponent() {
			this.$emit("show-component");
		},
		getComponentName() {
			if (this.selectedComponent) {
				let componentName = this.selectedComponent?.fields?.title?.[this.selectedLocale] ?? ''
				if (!componentName) return 'Dashboard'
				return componentName
			}
		},
		closeAlert() {
			if (this.errorAlertVisible) {
				this.errorAlertVisible = false;
			}
			else if (this.successAlertVisible) {
				this.successAlertVisible = false;
				this.showChangePassword = false;
			}
		},
		openUrl(url) {
			window.open(url, '_blank')
		},
		updateUserState() {
			if (this.loggedInUser && this.loggedInUser.hasOwnProperty("kc_token")) {
				this.selectedComponent = this.$store.state.selectedComponent
				this.user = this.loggedInUser
				this.haveUser = true

				this.getSystemNotification()
			}
			else {
				this.selectedComponent = {}
				this.user = {}
				this.haveUser = false
			}
		},
	},
	async mounted() {
		this.$root.$i18n.locale = this.selectedLocale
		this.$vuetify.locale.current = this.selectedLocale
		this.version = version
		this.updateUserState()
	},
}
</script>

<style scoped>
.NavBar {
	--v-input-control-height: 45px;
}
.v-app-bar.v-app-bar--fixed {
  border-bottom: 1px solid #b4c3ca !important;
}
.gradient {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%);
  border: thin #dddddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.v-list-item {
	cursor: pointer !important;
}

.v-list-item__title { font-size: 12pt !important; }
.v-list-item__title .v-icon { margin-right: 5px; }
.dialogDiv {
  position:absolute;
  right:10px;
  top:55px;
  width:465px;
  background:#ffffff; 
  border-radius:5px;
  border:thin solid #cfcfcf;
  box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
  padding: 20px 20px 0;
  color:#acacac;
  z-index: 999;
  max-width: calc(100vw - 20px);
  pointer-events: auto;
}

.accountAction { background-color: #fafafa; }
.accountAction .v-list-item__title { font-size: 12px !important; }

.theme--light.v-divider {
  border-color: #b4c3ca !important;
  border-width: 0.5px !important;
  opacity: 1 !important;
  z-index: 1000;
}
/*
.navbarComponent { 
  width:100%;
  height: 62px;
  pointer-events: none;
}
*/
</style>

<template>
	<div>
		<div class="draggableContainer">
			<template v-if="images">
				<draggable v-model="images" @update:modelValue="onDragEnd"
					@start="drag=true"
					:move="onMove"
					@end="drag=false"
				>
					<v-card v-for="image in images" :key="image.sys.id"
						class="infoImageWrapper mr-2 mt-1" width="100px" height="100px" flat
						draggable
						:options="{}"
					>
						<img :src="imgSource(image)" width="100" height="100" />
						<v-btn class="editImg image-actions" size="small" @click="image.imageURL ? editImage(image.fields.media ? (image.fields.media.de.fields.file.de ? image.fields.media.de.fields.file.de.url : image.fields.media.de.fields.file.url ? image.fields.media.de.fields.file.url.de : image) : (image.fields.file.de.url ? image.fields.file.de.url : image)) : editImage(image)">
							<v-icon size="medium">mdi-pencil</v-icon>
						</v-btn>
						<v-btn class="removeImg image-actions" size="small" @click="deleteImage(image)">
							<v-icon size="medium">mdi-delete</v-icon>
						</v-btn>
					</v-card>
					<v-card flat class="addImage" data-cy="addImage" @click="$refs.mediaViewer.open()">
						<v-icon size="48px" color="#9da0a5">mdi-plus</v-icon>
					</v-card>
				</draggable>
			</template>
		</div>
		<p v-if="imageErrors.length" class="error-text">{{ imageErrors[0] }}</p>
		<p class="helpText">{{ $t('text.imagesDesc') }}</p>
		<MediaViewer ref="mediaViewer" @add-media="addMedia" />
	</div>
</template>

<script>
import { VueDraggableNext as draggable } from 'vue-draggable-next'
import MediaViewer from '../media/MediaViewer.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ImagesField',
	components: { draggable, MediaViewer },
	mixins: [ Common ],
	props: {
		modelValue: Array,
		required: { type: Boolean, default: false },
	},
	data: () => ({
		imageErrors: [],
		images: [],
	}),
	emits: [ 'toggle-loading', 'on-image-errors' ],
	watch: {
		imageErrors(n) {
			this.$emit('on-image-errors', n)
		},
		modelValue(n) {
			this.images = n
		},
		//images(n) {
		//	this.$emit('update:modelValue', n)
		//},
	},
	methods: {
		onMove({ draggedContext: { futureIndex } }) {
			return futureIndex < this.images.length
		},
		onDragEnd() {
			this.$emit('update:modelValue', this.images)
		},
		validateImage() {
			let isValid = true
			this.imageErrors = []
			if (this.required && this.images?.length === 0) {
				isValid = false
				this.imageErrors.push(this.$t('text.missingFieldsError'))
			}
			return isValid
		},
		addMedia() {
			if (this.$refs.mediaViewer.$data.selectedMedia) {
				let selectedMedia = this.$refs.mediaViewer.$data.selectedMedia

				if (selectedMedia && selectedMedia.length > 0) {
					for (var media of selectedMedia) {
						const imageIndex = this.images.findIndex(x => x.sys.id === media.sys.id)
						if (imageIndex === -1) {
							this.images.push(media)
						}
					}
				}

				this.$refs.mediaViewer.close()
				this.validateImage()
			}
		},
		deleteImage(image) {
			if (image.imageURL) {
				if (image.fields.media) {
					if (image.fields.media.de.fields.file.de)
						image = image.fields.media.de.fields.file.de.url
					// this probably takes care of some hirstorical broken data
					else if (image.fields.media.de.fields.file.url)
						image.fields.media.de.fields.file.url.de
				}
				else {
					if (image.fields.file.de.url)
						image = image.fields.file.de.url
				}
			}
			this.removeImage(image)
		},
		removeImage(imageUrl) {
			if (this.images && this.images.length > 0) {
				for (var i = 0; i < this.images.length; i++) {
					// check if new or update
					if (this.images[i].imageURL) {
						if (this.images[i].imageURL === imageUrl) {
							this.images.splice(i, 1)
						}
					}
					else if (this.images[i] === imageUrl) {
						this.images.splice(i, 1)
					}
				}
			}
			this.validateImage()
		},
		async editImage(image) {
			this.$emit('toggle-loading', true)
			// image is of type Asset (after SP update) - in this case we need to get the corresponding MediaAsset for the asset
			if (!image.fields.media) {
				await this.$refs.mediaViewer.getMedia(true)
				const providerMediaArray = this.$refs.mediaViewer.$data.providerMedia
				const providerMedia = providerMediaArray.find(media => media.fields.media.de.sys.id === image.sys.id)
				this.$refs.mediaViewer.$data.selectedMediaAsset = providerMedia
			}
			// image is of type MediaAsset (before SP update) 
			else {
				this.$refs.mediaViewer.$data.selectedMediaAsset = image
			}

			await this.$refs.mediaViewer.open()
			this.$emit('toggle-loading', false)

			if (this.$refs.mediaViewer.isOpen() && !this.$refs.mediaViewer.$data.isLoading) {
				this.$refs.mediaViewer.$data.isEditShortcut = true
				this.$refs.mediaViewer.showUpdate(this.$refs.mediaViewer.$data.selectedMediaAsset)
			}
		},
		imgSource(image) {
			if (image.fields?.media?.de?.fields?.file?.de?.url)
				return image.fields.media.de.fields.file.de.url
			else if (image.fields?.media?.de?.fields?.file?.url)
				return image.fields.media.de.fields.file.url
			else if (image.fields?.file?.de?.url)
				return image.fields.file.de.url
			else if (image.fields?.file?.url?.de)
				return image.fields.file.de.url.de
			return image
		},
	},
	mounted() {
		// TODO: reactive binding
		this.images = this.modelValue
	},
}
</script>

<style scoped>
.infoImageWrapper { margin: 0 13px 13px 0 !important; border-radius: 5px; background-color: #ffffff; }
.infoImageWrapper img { border-radius: 5px !important; }

.infoImageWrapper .removeImg { position: absolute; right: 5px; bottom: 5px; min-width: 0 !important; }
.infoImageWrapper:hover .removeImg { color: #000; }
.infoImageWrapper .editImg { position: absolute; left: 5px; bottom: 5px; min-width: 0 !important; }
.infoImageWrapper:hover .removeImg:hover { border: 1px solid #00aeef; }
.infoImageWrapper:hover .editImg:hover { border: 1px solid #00aeef; }
.infoImageWrapper:hover .removeImg:active { color: #5a5a5a; }

.image-actions { display: none; }
.infoImageWrapper:hover .image-actions { display: block; }

.addImage { width: 100px; height: 100px; border-radius: 5px; border:1px solid rgba(0,0,0,.2); background-color:#ffffff; margin: 0 13px 13px 0; display: flex; align-items: center; justify-content: center; }
.draggableContainer { margin-top: 5px; }
.draggableContainer > div { display: flex; flex-direction: row; flex-wrap: wrap; }
</style>
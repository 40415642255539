<template>
	<Disclosure :title="$t('text.linkServiceProvidersTitle')" :lock="!userMayEditFields" data-cy="linkServiceProviders" ref="SECTION_linkServiceProviders">
		<p class="subTitle" v-html="$t('text.linkServiceProvidersText')"/>
		<div class="field left-border">
			<div class="row">
				<v-text-field variant="outlined" density="compact" clearable hide-details
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="max-width: 35%;"
					class="mt-0"
					v-model="searchString"
					@click:clear="clear()"
					@keyup.enter="search()"
				/>
				<v-btn class="gradientButton" elevation="0" @click="search()">{{$t('text.search')}}</v-btn>
			</div>
			<!-- Search Service Providers -->
			<div v-if="searchServiceProviders.length > 0">
				<template v-for="serviceProvider in searchServiceProviders">
					<div :key="serviceProvider.sys.id" class="spOption" v-if="serviceProvider?.fields?.title?.de">
						<v-checkbox hide-details v-model="serviceProvider.selected" :label="serviceProvider.fields.title.de" />
					</div>
				</template>
				<v-btn class="greenButton" elevation="0" :disabled="!selected" @click="linkServiceProviders()"><v-icon>mdi-link-variant</v-icon> {{$t('text.linkServiceProviders')}}</v-btn>
			</div>
		</div>
		<!-- Linked Service Providers -->
		<div class="field left-border" v-if="selectedServiceProviders.length > 0">
			<p class="subTitle" v-html="$t('text.linkedServiceProviders')"/>
			<template v-for="serviceProvider in selectedServiceProviders">
				<div :key="serviceProvider.sys.id" class="spOption" v-if="serviceProvider?.fields?.title?.de">
					<v-checkbox hide-details v-model="serviceProvider.unselected" :label="serviceProvider.fields.title.de" />
				</div>
			</template>
			<v-btn class="redButton" elevation="0" :disabled="!unselected" @click="unlinkServiceProviders()"><v-icon>mdi-link-variant-off</v-icon> {{$t('text.unlinkServiceProviders')}}</v-btn>
		</div>
	</Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'

export default {
	name: 'ServiceProviderLink',
	components: { Disclosure },
	mixins: [ Common ],
	props: {
		serviceProvider: { type: Object, required: true },
		updateModel: Boolean,
	},
	data() { return {
		model: {},
		initData: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		searchString: '',
		searchServiceProviders: [],
		// selectedServiceProviders is used as a ref in the parent component
		selectedServiceProviders: [],
	}},
	computed: {
		selected() {
			for (var serviceProvider of this.searchServiceProviders) {
				if (!serviceProvider.selected) continue
				return true
			}
			return false
		},
		unselected() {
			for (var serviceProvider of this.selectedServiceProviders) {
				if (!serviceProvider.unselected) continue
				return true
			}
			return false
		},
	},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
		
			this.setInitData()
		},
	},
	created() {
		this.model = this.valueToModel(this.serviceProvider)
		
		if (this.model.fields.linkedServiceProviders?.de?.length > 0) {
			this.selectedServiceProviders = this.model.fields.linkedServiceProviders.de
			if (this.selectedServiceProviders) { this.selectedServiceProviders.sort(this.compare) }
		}

		this.setInitData()
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			this.initData = {
				selectedServiceProviders: JSON.parse(JSON.stringify(this.selectedServiceProviders))
			}
		},
		sendData() {
			// TODO: this is called 3 times and only on the first time the data is correct, the other times whatever was added is gone
			const data = {
				selectedServiceProviders: this.selectedServiceProviders
			}

			data.changed = !isEqual(data, this.initData)
			return data
		},
		linkServiceProviders() {
			for (var serviceProvider of this.searchServiceProviders) {
				if (serviceProvider.selected) {
				this.selectedServiceProviders.push(serviceProvider)
				}
			}

			this.searchServiceProviders = []
			this.searchString = ''
		},
		unlinkServiceProviders() {
			for (let i = this.selectedServiceProviders.length - 1; i >= 0; i--) {
				if (this.selectedServiceProviders[i].unselected) {
					this.selectedServiceProviders.splice(i, 1)
				}
			}
		},
		clear() {
			this.searchServiceProviders = []
		},
		async search() {
			this.$emit('loading', true)
			try {
				this.searchString = this.searchString ? this.searchString : "" //Set searchString to empty string if it is null (it is null after Search field is cleared)

				const res = await this.$httpPost(`/search`, {
					clientId: this.$store.state.selectedClient.sys.id,
					providerId: this.$store.state.selectedServiceProvider.sys.id,
					contentType: "serviceProvider",
					searchString: this.searchString,
					homebaseOnly: true,
				})
				
				this.searchServiceProviders = res.serviceProviders
				if (this.searchServiceProviders) {
					this.searchServiceProviders.sort(this.compare)
				}
				//MYS-1036: Remove current service provider from search results
				let spIndex = -1

				if (res.serviceProviders && res.serviceProviders.length > 0) {
					spIndex = res.serviceProviders.findIndex(x => x.sys.id === this.serviceProvider.sys.id)

					if (spIndex > -1) {
						this.searchServiceProviders.splice(spIndex, 1)
					}

					//MYS-1258: Prevent the same SP being linked multiple times
					if (this.selectedServiceProviders && this.selectedServiceProviders.length > 0) {
						for (let selectedSP of this.selectedServiceProviders) {
							spIndex = res.serviceProviders.findIndex(x => x.sys.id === selectedSP.sys.id)
							if (spIndex > -1) {
								this.searchServiceProviders.splice(spIndex, 1)
							}
						}
					}
				}
			}
			catch (error) {
				console.log(error)
				// if (error.response?.status === 401)
				// 	return this.$emit("show-login")
				// 	this.showError(error.response ? error.response?.data.error : error)
			}
			this.$emit('loading', false)
		},
	},
}
</script>

<style scoped>
.row { display: flex; gap: 10px; align-items: center; }
.spOption { display: flex; }
.spOption > * { margin: 0 !important; }
</style>
import { SqlEntityRepository } from '@mikro-orm/postgresql'
import { requestGlobal } from './inject-util'

// automatically loads, assigns / creates and persists an entity
export async function autoUpsert(repo: SqlEntityRepository<any>, data: any) {
	let entity = await repo.findOne(data.id)
	if (entity) {
		data.updatedBy = requestGlobal.userSub
		requestGlobal.em.assign(entity, data as any)
	}
	else {
		data.createdBy = requestGlobal.userSub
		entity = await repo.create(data)
	}
	await requestGlobal.em.persistAndFlush(entity)
	return entity
}
<template>
	<Disclosure :title="$t('text.tickets')" :error="sectionMessage.error" :message="sectionMessage.message"
		data-cy="ticketInfo" ref="SECTION_ticketInfo"
	>
		<div v-if="hasField('ticketInfo','taxClass', model)" class="field left-border">
			<v-label>{{ $t('text.taxClass') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
			<v-select
				variant="outlined" density="compact" return-object
				:hide-details="taxClassErrors.length === 0"
				:error-messages="taxClassErrors"
				:disabled="productIsExternal"
				:items="taxClasses"
				:item-title="item => item?.fields?.title ? item.fields.title[$store.state.serviceLocale] : ''"
				v-model="model.fields.taxClass.de"
				data-cy="taxClass"
			/>
			<p class="helpText" v-html="$t('text.taxClassDesc')"/>
		</div>

		<div v-if="hasField('ticketInfo','tickets', model)" class="field left-border">
			<v-label>{{ $t('text.tickets') }}&nbsp;<span class="error-text">({{ $t('text.required') }})</span></v-label>

			<!-- From Price -->
			<div style="display: flex; gap: 10px;">
				<p class="helpText" v-html="$t('text.fromPriceDesc')"/>
				<mys-switch
					v-model="fromPriceEnabled"
					:label="$t('text.fromPrice')"
					data-cy="fromPrice"
					style="flex-shrink: 0;"
				/> 
			</div>

			<!-- External System -->
			<div v-if="hasField('ticketInfo','externalSystem', model)" class="pt-4 pb-4">
				<v-label>{{ $t('text.externalSystem') }}</v-label>
				<v-select
					variant="outlined" density="compact" return-object 
					:hide-details="externalSystemErrors.length===0"
					:error-messages="externalSystemErrors"
					:disabled="productIsExternal"
					:items="externalSystems"
					v-model="model.fields.ticket.de.fields.external.system" 
					@update:modelValue="setExternalSystem"
					data-cy="externalSystem"
				/>
				<p class="helpText" v-html="$t('text.externalSystemHelp')" />
			</div>

      <!-- 
        TODO: uncomment when the PEAK Import API supports the access type for external events

        <div v-if="hasField('ticketInfo','externalSystem', model)" class="pt-4 pb-4">
        <v-label>{{ $t('text.ticketAccessType') }}</v-label>
        <v-select
            outlined density="compact" return-object hide-details
            :items="accessTypes"
            v-model="model.fields.ticket.de.fields.external.accessType" 
            :item-text="item => item.title"
            data-cy="externalSystemAccessType"
        />
        <p class="helpText" v-html="$t('text.ticketAccessTypeHelp')"/>
      </div> 
      -->

			<!-- Ticket Options -->
			<template v-if="model.fields.ticket?.de?.fields?.ticketOptions?.de">
				<div class="prices" v-for="(ticketOption, index) in model.fields.ticket.de.fields.ticketOptions.de" :key="index">
					<v-btn class="removeRow" v-if="index > 0" @click="removeTicketRow(index)">
						<v-icon color="white">mdi-delete</v-icon>
					</v-btn>
					<div style="display: flex; gap: 10px; width: 100%;">
						<div style="flex: 1 0;">
							<v-label>{{ $t('text.ticketType') }}</v-label>
							<TicketTypeField
								v-model="ticketOption.fields.ticketType.de"
								:error="ticketTypeError(ticketOption)" 
								:disabled="productIsExternal"
								:data-cy="'ticketType_' + index"
							/>
						</div>
						<div style="flex: 1 0;">
							<v-label>{{ $t('text.reduction') }}</v-label>
							<ReductionField
								v-model="ticketOption.fields.reduction.de"
								:disabled="productIsExternal"
								:error="reductionError(ticketOption)" 
								:data-cy="'reduction_' + index"
							/>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="flex: 1 0; ">
							<v-label>{{ $t('text.price') }}</v-label>
							<v-text-field hide-details
								variant="outlined" density="compact"
								:disabled="productIsExternal"
								v-model="model.fields.ticket.de.fields.ticketOptions.de[index].fields.price.de"
								:data-cy="'price_' + index"
								style="background-color: #ffffff;"
								@keypress="isDecimal"
							/>
						</div>
						<mys-switch
							style="flex: 1 0; margin-top: 23px !important;"
							:label="$t('text.fromPrice')"
							v-model="ticketOption.fields.fromPriceOption.de"
							@update:modelValue="setFromPriceOption(ticketOption.sys.id)"
						/>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="flex: 1 0;" v-if="hasField('ticketInfo','minimumTickets', model)">
							<v-label>{{ $t('text.minTickets') }}</v-label>
							<v-text-field variant="outlined" density="compact"
								:hide-details="checkMinMax(ticketOption).length === 0"
								:error-messages="checkMinMax(ticketOption)"
								:disabled="productIsExternal"
								style="background-color: #ffffff;"
								type="number" min="1"
								v-model="ticketOption.fields.minimumTickets.de"
								@keypress="isNaturalNumber($event)"
							/>
						</div>

						<div style="flex: 1 0;" v-if="hasField('ticketInfo','maximumTickets', model)">
							<v-label>{{ $t('text.maxTickets') }}</v-label>
							<v-text-field variant="outlined" density="compact" hide-details
								style="background-color: #ffffff;"
								type="number" min="1"
								:disabled="productIsExternal"
								v-model="ticketOption.fields.maximumTickets.de"
								@keypress="isNaturalNumber($event)"
							/>
						</div>
					</div>
					<div v-if="hasField('ticketInfo','contingentQuantity', model)">
						<v-label>{{ $t('text.noOfPeople') }}</v-label>
						<v-text-field variant="outlined" density="compact" hide-details style="background-color:#ffffff"
							type="number" min="1"
							:disabled="productIsExternal"
							v-model="ticketOption.fields.contingentQuantity.de"
							@keypress="isNaturalNumber($event)"
						/>
						<p class="helpText" v-html="$t('text.ticketsDesc')" v-if="index == 0" />
					</div>

          <!-- External System Settings: Skidata -->
          <div v-if="hasField('ticketInfo','externalSystem', model) && model.fields.ticket.de.fields.external?.system === 'Skidata'" class="external">
            <v-row class="pt-0">
              <v-col class="pt-0">
                <v-label>{{ $t('text.dtaProductId') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff" 
                  :error-messages="skidataProductIdError(ticketOption)"
                  v-model="ticketOption.fields.external.dtaProductId"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.dtaConsumerId') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff" 
                  :error-messages="skidataConsumerIdError(ticketOption)"
                  v-model="ticketOption.fields.external.dtaConsumerId"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.dtaConfiguration') }}</v-label>
               <v-select style="background-color:#ffffff"
                  variant="outlined" 
                  hide-details 
                  :error-messages="skidataClientConfigError(ticketOption)"
                  :items="skidataClientConfig"
                  :item-title="item => item?.id"
                  :item-value="item => item?.id"
                  v-model="ticketOption.fields.external.dtaCustomerConfig"
                  data-cy="dtaCustomerConfig"
                />
              </v-col>
            </v-row>
          </div>

          <!-- External System Settings: Axess -->
          <div v-if="hasField('ticketInfo','externalSystem', model) && model.fields.ticket.de.fields.external?.system === 'Axess'" class="external">
            <v-row class="pt-0">
              <v-col class="pt-0">
                <v-label>{{ $t('text.axessTicketType') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.ticketTypeNumber" 
                  :error-messages="axessTicketTypeNumberError(ticketOption)"
                  @keypress="isNaturalNumber($event)"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.axessPool') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.poolNumber" 
                  :error-messages="axessPoolNumberError(ticketOption)"
                  @keypress="isNaturalNumber($event)"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.axessPersonType') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.personTypeNumber" 
                  :error-messages="axessPersonTypeNumberError(ticketOption)"
                  @keypress="isNaturalNumber($event)"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.axessIDNumber') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.idNumber" 
                  :error-messages="axessIdNumberError(ticketOption)"
                  @keypress="isNaturalNumber($event)"/>
              </v-col>
            </v-row>
          </div>

          <!-- External System Settings: SBBv3 -->
          <div v-if="hasField('ticketInfo','externalSystem', model) && model.fields.ticket.de.fields.external?.system === 'SBBv3'" class="external">
            <v-row class="pt-0">
              <v-col class="pt-0">
                <v-label>{{ $t('text.sbbv3id1') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.id1" 
                  :error-messages="sbbv3Id1Error(ticketOption)"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.sbbv3id2') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.id2" 
                  :error-messages="sbbv3Id2Error(ticketOption)"/>
              </v-col>
              <v-col class="pt-0">
                <v-label>{{ $t('text.sbbv3id3') }}</v-label>
                <v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
                  v-model="ticketOption.fields.external.id3" 
                  :error-messages="sbbv3Id3Error(ticketOption)"/>
              </v-col>
            </v-row>
          </div>

				</div>
			</template>
			<v-btn id="btnAddTicketRow" class="gradientButton" elevation="0"
				style="height: 40px; font-size: 12pt; margin-right: 10px;" @click="addTicketRow"
			>
				<v-icon size="24px">mdi-plus</v-icon>
				{{ $t('text.addTicketOption') }}
			</v-btn>
		</div>
	</Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import TicketTypeField from './TicketTypeField.vue'
import ReductionField from './ReductionField.vue'
import eventBus from '@/utils/eventBus.js'
import isEqual from 'lodash/isEqual'

export default {
	name: 'TicketInfo',
	components: { Disclosure, TicketTypeField, ReductionField },
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
		updateModel: Boolean,
		productIsExternal: Boolean,
	},
	data: () => ({
		model: {},
		initData: {},
		data: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		fromPriceEnabled: true,
		taxClassErrors: [],
		taxClasses: [],
		externalSystems: ['Skidata','Axess','SBBv3'],
		externalSystemErrors: [],
		accessTypes: [],
		skidataClientConfig: [],

		spTrustLevel: 1,
	}),
  watch: {
    'model.fields.ticket.de.fields.ticketOptions.de': {
      handler: function (ticketOptions) {
        const hasTicket = ticketOptions?.[0]?.fields?.reduction?.de !== null && ticketOptions?.[0]?.fields?.ticketType?.de !== null
        eventBus.$emit("has-ticket", hasTicket)
      },
      deep: true
    },
    updateModel() {
      // we need to update the child model when the product is created
      this.model = this.valueToModel(this.product)
      this.setInitData()
    },
  },
  methods: {
    valueToModel(v) {
      let model = JSON.parse(JSON.stringify(v ?? {}))
      if (this.hasField('ticketInfo','externalSystem', model)) {
        if (!model.fields.ticket.de.fields.external) {
          model.fields.ticket.de.fields.external = {
            system: '',
            accessType: ''
          }
        }
      }

      return model
    },
    setInitData() {
      const initModel = JSON.parse(JSON.stringify(this.model))

      this.initData = {
        personalisationType: initModel.fields.personalisationType
      }

      if (this.hasField('ticketInfo', 'tickets', this.model)) {
        this.initData.ticket = initModel.fields.ticket
        this.initData.fromPriceEnabled = initModel.fields.fromPriceEnabled
      }

      if (this.hasField('ticketInfo','taxClass', this.model)) {
        this.initData.taxClass = initModel.fields.taxClass
      }
    },
    sendData() {
      this.resetPersonalisationIds()

      this.data = {
        personalisationType: this.model.fields.personalisationType
      }

      // if minimum and maximum tickets as well as nr of people are explicitly set to 0 then default to 1 and 6 for min/max and 1 for nr of people
      if (this.model.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0) {
        for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          if (ticketOption.fields.minimumTickets.de == 0) {
            ticketOption.fields.minimumTickets.de = 1
          }
          if (ticketOption.fields.maximumTickets.de == 0) {
            ticketOption.fields.maximumTickets.de = 6
          }
          if (ticketOption.fields.contingentQuantity.de == 0) {
            ticketOption.fields.contingentQuantity.de = 1
          }
        }
      }

      // problem: we send the entire ticket below but some fields within the ticket should only be sent if hasField for those is true (ex: min/max contingents/nr of people for ADDONS)

      if (this.hasField('ticketInfo', 'tickets', this.model)) {
        this.data.ticket = this.model.fields.ticket
        this.data.fromPriceEnabled = {de: this.fromPriceEnabled }
      }

      if (this.hasField('ticketInfo','taxClass', this.model)) {
        this.data.taxClass = this.model.fields.taxClass
      }

      this.data.changed = !isEqual(this.data, this.initData)

      return this.data
    },
		// Ticket Personalisations - set id's to empty strings; we need to do this because
		// the id-s need to be empty strings initially for the backend, but they need to be
		// numbers for the frontend component
		resetPersonalisationIds() {
			const ticketOptions = this.model.fields.ticket?.de?.fields?.ticketOptions?.de
			if (ticketOptions?.length > 0) {
				// Reset ID's
				const ticket = this.model.fields.ticket.de
				if (typeof ticket.sys.id !== 'string') {
					ticket.sys.id = ''
				}
				for (let ticketOption of ticketOptions) {
					if (typeof ticketOption.sys.id !== 'string') {
						ticketOption.sys.id = ''
					}
				}

				let ticketPersonalisations
				const lastOption = ticketOptions[ticketOptions.length - 1]
				if (lastOption.fields?.ticketPersonalisations?.de) {
					ticketPersonalisations = lastOption.fields.ticketPersonalisations.de
					for (let ticketPersonalisation of ticketPersonalisations) {
						if (typeof ticketPersonalisation.sys.id !== 'string') {
							ticketPersonalisation.sys.id = ''
						}
						const personalisationGroup = ticketPersonalisation?.fields?.personalisationGroup?.de
						if (personalisationGroup?.sys?.id) {
							if (typeof personalisationGroup.sys.id !== 'string') {
								personalisationGroup.sys.id = ''
							}
						}
					}
				}

				// TODO: remove this when we support personalisation per ticket option again
				// Set ticketpersonalistions on all ticket options
				for (let ticketOption of this.product.fields.ticket.de.fields.ticketOptions.de) {
					if (ticketOption.fields.ticketPersonalisations?.de) {
						ticketOption.fields.ticketPersonalisations.de = ticketPersonalisations
					}
				}
			}
		},
    checkMinMax(ticketOption) {
      let errors = []
      if (ticketOption.fields.minimumTickets.de > ticketOption.fields.maximumTickets.de) {
        errors = [this.$t('text.minMaxTicketsError')]
      } else {
        errors = []
      }
      return errors
    },
    validateAllFields(action) {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)

      if (this.productIsExternal) return allFieldsAreValid

      if (!this.validateTaxClass()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.taxClassRequired'))
      }
      if (!this.validateExternalSystem()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.externalSystemRequired'))
      }
      if (!this.validateTicket()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
      }

      return allFieldsAreValid
    },
    validateTaxClass() {
      let isValid = true
      this.taxClassErrors = []
      if (!this.model.fields.taxClass.de || !Object.keys(this.model.fields.taxClass.de).length) {
        isValid = false
        this.taxClassErrors.push(this.$t('text.taxClassRequired'))
      }
      return isValid
    },
     validateExternalSystem() {
      if (!this.hasField('ticketInfo','externalSystem', this.model)) return true
      let isValid = true
      this.externalSystemErrors = []
      if (!this.model.fields.ticket.de.fields.external.system) {
        isValid = false
        this.externalSystemErrors.push(this.$t('text.externalSystemRequired'))
      }
      return isValid
    },
    validateTicket() {
      let isValid = true

      if (this.hasField('ticketInfo', 'tickets', this.model) && this.model.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0) {
        for (const ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          if (!ticketOption.fields?.reduction?.de?.fields?.title?.de?.length ||
              !ticketOption.fields?.ticketType?.de?.fields?.title?.de?.length ||
              ticketOption.fields.ticketType.de.sys.id === '' ||
              ticketOption.fields.reduction.de.sys.id === '' ||
              (ticketOption.fields.price?.de !== 0 && !ticketOption.fields.price?.de) ||
              ticketOption.fields.price.de === '') {
              isValid = false
          }

          if (isValid &&
              this.hasField('ticketInfo', 'minimumTickets', this.model) &&
              this.hasField('ticketInfo', 'maximumTickets', this.model) &&
              ticketOption.fields.minimumTickets.de > ticketOption.fields.maximumTickets.de) {
            isValid = false
          }
          if (isValid && this.model.fields.ticket.de.fields.external?.system?.length) {
            switch (this.model.fields.ticket.de.fields.external.system) {
              case 'Skidata':
                isValid = this.validateSkidata(ticketOption)
                break
              case 'Axess':
                isValid = this.validateAxess(ticketOption)
                break
              case 'SBBv3':
                isValid = this.validateSBBv3(ticketOption)
                break
            }

            if (!isValid) {
              this.sectionMessage.error = this.$t('text.externalSystemRequired')
            }
          }
        }
      }

      return isValid
    },
    validateSkidata(ticketOption) {
      if (this.skidataProductIdError(ticketOption).length || 
          this.skidataConsumerIdError(ticketOption).length || 
          this.skidataClientConfigError(ticketOption).length) {
            return false
      }
      return true
    },
    validateAxess(ticketOption) {
      if (this.axessTicketTypeNumberError(ticketOption).length || 
          this.axessPersonTypeNumberError(ticketOption).length || 
          this.axessPoolNumberError(ticketOption).length || 
          this.axessIdNumberError(ticketOption).length) {
            return false
      }
      return true
    },
    validateSBBv3(ticketOption) {
      if (this.sbbv3Id1Error(ticketOption).length || 
          this.sbbv3Id2Error(ticketOption).length || 
          this.sbbv3Id3Error(ticketOption).length) {
            return false
      }
      return true
    },
    removeTicketRow(rowIndex) {
      if (rowIndex > 0) {
        this.model.fields.ticket.de.fields.ticketOptions.de.splice(rowIndex, 1)
      }
    },
    addTicketRow() {
      if (!this.model.fields.ticket || this.model.fields.ticket.de.error) {
        this.model.fields["ticket"] = {de: {sys: {id: ''}, fields: {ticketOptions: {de: []}}}}
      }
      let ticketPersonalisations = []
      if (this.model.fields.ticket.de.fields.ticketOptions.de.length > 0) {
        ticketPersonalisations = this.model.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations?.de
      }

      this.counter++;

      let newTicketOption = {
        sys: {id: `id_v${(this.model.fields.ticket.de.fields.ticketOptions.de.length + 1)}`.toString()},
        fields: {
          title: {de: ''},
          ticketType: {de: null},
          reduction: {de: null},
          price: {de: ''},
          ticketPersonalisations: {de: ticketPersonalisations ? ticketPersonalisations : []},
          minimumTickets: {de: 1},
          maximumTickets: {de: 6},
          contingentQuantity: {de: 1},
          fromPriceOption: {de: false}
        },
        selected: false
      }

      if (this.model.fields.ticket.de.fields.hasOwnProperty('external')) {
        if (this.model.fields.ticket.de.fields.external.system === 'Skidata') {
          newTicketOption.fields.external = {
                  dtaProductId: '',
                  dtaConsumerId: '',
                  dtaCustomerConfig: ''
              }
        } else if (this.model.fields.ticket.de.fields.external.system === 'Axess') {
          newTicketOption.fields.external = {
                  ticketTypeNumber: '',
                  personTypeNumber: '',
                  poolNumber: '',
                  idNumber: ''
              }
        } else if (this.model.fields.ticket.de.fields.external.system === 'SBBv3') {
          newTicketOption.fields.external = {
                  id1: '',
                  id2: '',
                  id3: ''
              }
        }
      }

      this.model.fields.ticket.de.fields.ticketOptions.de.push(newTicketOption);
    },
    setTicketInfoDetails() {
      if (!this.model.fields.ticket) {
        this.addTicketRow()
      } else {
        let ticketOptions = this.model.fields.ticket?.de?.fields?.ticketOptions?.de ? this.model.fields.ticket.de.fields.ticketOptions.de : []
        if (ticketOptions && ticketOptions.length > 0) {
          for (let ticketOption of ticketOptions) {
            if (!ticketOption.fields.ticketType?.de) ticketOption.fields.ticketType = {de: null}
            if (!ticketOption.fields.reduction?.de) ticketOption.fields.reduction = {de: null}
            if (!ticketOption.fields.fromPriceOption?.de) ticketOption.fields.fromPriceOption = {de: false}
            if (!ticketOption.fields.contingentQuantity?.de) ticketOption.fields.contingentQuantity = {de: 1}
          }
        }
      }
    },
    ticketTypeError(ticketOption) {
      if (this.productIsExternal) {
        return false
      }
      return !ticketOption?.fields?.ticketType?.de?.fields || !Object.keys(ticketOption.fields.ticketType.de).length
    },
    reductionError(ticketOption) {
      if (this.productIsExternal) {
        return false
      }
      return !ticketOption?.fields?.reduction?.de?.fields || !Object.keys(ticketOption.fields.reduction.de).length
    },
    setFromPriceOption(ticketOptionId) {
      for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
        ticketOption.fields['fromPriceOption'].de = ticketOption.sys.id === ticketOptionId && ticketOption.fields['fromPriceOption'].de
      }
    },
    // TODO: only used once for price - make it go away
    isDecimal: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
        evt.preventDefault()
        return false
      }
      return true;
    },

    //MYS-3469: External Events
    async setExternalSystem() {
      this.getTicketAccessTypes()

      if (!this.model.fields.ticket?.de?.fields.ticketOptions?.de?.length > 0) return

      if (this.model.fields.ticket.de.fields.external.system === 'Skidata') {
        for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          ticketOption.fields.external = {
            dtaProductId: ticketOption.fields.external?.dtaProductId?.length ? ticketOption.fields.external.dtaProductId : '',
            dtaConsumerId: ticketOption.fields.external?.dtaConsumerId?.length ? ticketOption.fields.external.dtaConsumerId : '',
            dtaCustomerConfig: ticketOption.fields.external?.dtaCustomerConfig?.length ? ticketOption.fields.external.dtaCustomerConfig : ''
          }
        }
        await this.getSkidataClientConfig()

      } else if (this.model.fields.ticket.de.fields.external.system === 'Axess') {
        for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          ticketOption.fields.external = {
            ticketTypeNumber: ticketOption.fields.external?.ticketTypeNumber?.length ? ticketOption.fields.external.ticketTypeNumber : '',
            poolNumber: ticketOption.fields.external?.poolNumber?.length ? ticketOption.fields.external.poolNumber : '',
            personTypeNumber: ticketOption.fields.external?.personTypeNumber?.length ? ticketOption.fields.external.personTypeNumber : '',
            idNumber: ticketOption.fields.external?.idNumber?.length ? ticketOption.fields.external.idNumber : ''
          }
        }
      } else if (this.model.fields.ticket.de.fields.external.system === 'SBBv3') {
        for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          ticketOption.fields.external = {
            id1: ticketOption.fields.external?.id1?.length ? ticketOption.fields.external.id1 : '',
            id2: ticketOption.fields.external?.id2?.length ? ticketOption.fields.external.id2 : '',
            id3: ticketOption.fields.external?.id3?.length ? ticketOption.fields.external.id3 : ''
          }
        }
      }
    },
    getTicketAccessTypes() {
      this.accessTypes = []
      if (this.model.fields.ticket.de.fields.external?.system === 'Skidata') {
        this.accessTypes = [
          { id:'Standard',title:this.$t('text.accessTypeSkidataPermission') },
          { id:'Reservierung',title:this.$t('text.accessTypeSkidataPickup') }
        ]
      }
    },
    async getSkidataClientConfig() {
        if (this.skidataClientConfig?.length > 0) return
        
        this.loading = true
        const response = await this.$httpGet(`/peak/skidata-client-config/${this.$store.state.selectedClient.fields.installationId.de}`)
        this.skidataClientConfig = response ? Object.keys(response).map((key) => response[key]) : []
        this.loading = false
    },

    skidataProductIdError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.dtaProductId?.length ? this.$t('text.externalSystemRequired') : ''
    },
    skidataConsumerIdError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.dtaConsumerId?.length ? this.$t('text.externalSystemRequired') : ''
    },
    skidataClientConfigError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.dtaCustomerConfig?.length ? this.$t('text.externalSystemRequired') : ''
    },
    axessTicketTypeNumberError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.ticketTypeNumber?.length ? this.$t('text.externalSystemRequired') : ''
    },
    axessPersonTypeNumberError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.personTypeNumber?.length ? this.$t('text.externalSystemRequired') : ''
    },
    axessPoolNumberError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.poolNumber?.length ? this.$t('text.externalSystemRequired') : ''
    },
    axessIdNumberError(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.idNumber?.length ? this.$t('text.externalSystemRequired') : ''
    },
    sbbv3Id1Error(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.id1?.length ? this.$t('text.externalSystemRequired') : ''
    },
    sbbv3Id2Error(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.id2?.length ? this.$t('text.externalSystemRequired') : ''
    },
    sbbv3Id3Error(ticketOption) {
      if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
      return !ticketOption.fields.external.id3?.length ? this.$t('text.externalSystemRequired') : ''
    },
  },
  async created() {
    this.model = this.valueToModel(this.product)
    this.spTrustLevel = this.serviceProvider.fields.trustLevel?.de ?? 1
    if (!this.model.fields.fromPriceEnabled) {
      this.model.fields.fromPriceEnabled = {de: true}
    }
    this.fromPriceEnabled = this.model.fields.fromPriceEnabled.de

   
    this.taxClasses = this.$store.state.selectedClient.fields.taxClasses.de.sort(this.compare)
    this.setTicketInfoDetails()

    if (this.hasField('ticketInfo','externalSystem', this.model)) {
      await this.setExternalSystem()
    }
    
    this.setInitData()

    eventBus.$on("personalisation-type-changed", (personalisationType) => {
      this.model.fields.personalisationType.de = personalisationType
    })
    // listen on ticket-personalisations-changed event and update the ticket personalisations
    eventBus.$on("ticket-personalisations-changed", (ticketPersonalisations) => {
      for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
        ticketOption.fields.ticketPersonalisations.de = ticketPersonalisations
      }
    })
  },
  beforeUnmount () {
    eventBus.$off('personalisation-type-changed')
    eventBus.$off('ticket-personalisations-changed')
  },
}
</script>

<style scoped>
.external {
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 25px 10px 10px 10px;
  width: 100%;
  background-color: #efefef;
}
.prices {
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
}
.prices label { margin-top: 5px; margin-bottom: -10px; }
</style>
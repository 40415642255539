<template>
    <v-container fluid class="pa-0">
     
      <Disclosure title="Change Log" :expanded="true">
        <v-data-table fixed-header hide-default-footer
          :headers="headers"
          :items="auditEntries"
          :mobile-breakpoint="700"
          item-key="name"
          class="elevation-0"
          style="border-radius: 0"
          >
  
          <template v-slot:item="{item}">
            <tr @click="showDetail(item)">
              <td>{{ item.summary }}</td>
              <td>{{ item.user.fields.name.de }}</td>
              <td>{{ formatDateTime(item.createdAt) }}</td>
            </tr>
          </template>
          
        </v-data-table>
      </Disclosure>
  
      <!-- Audit Entry Details -->
          <Dialog ref="auditDetailDialog"
              :showClose="true"
        title="Change Detail"
              :height="'90%'"
              :width="'60%'">
              <template #content>
          <v-container class="pa-5">
            <div class="pa-5">
              <v-row class="detail"><span class="label">Summary</span>{{selectedItem.summary}}</v-row>
              <v-row class="detail"><span class="label">Updated by</span>{{selectedItem.user.fields.name.de}}</v-row>
              <v-row class="detail"><span class="label">Updated at</span>{{ formatDateTime(selectedItem.createdAt) }}</v-row>
            </div>
  
            <div class="audit-entry-container">
              <v-row style="padding:12px">
                <div class="audit-entry-header-box">
                  <div class="audit-entry-header"><h3>Original</h3></div>
                </div>
                <div class="audit-entry-header-box">
                  <div class="audit-entry-header"><h3>Changes</h3></div>
                </div>
              </v-row>
  
              <div v-for="key of Object.keys(selectedItem.detail.original)" :key="key" class="audit-entry-row">
                  <!-- Original Data Column -->
                  <div class="audit-entry">
                    <DiffData :contentType="selectedItem.contentType" :propKey="key" :entry="selectedItem.detail.original[key].de"/>
                  </div>
  
                  <!-- New Data Column -->
                  <div class="audit-entry">
					<!-- TODO: this does not seem to work nicely as some changes are just shown as an id - will probably need to change -->
                    <DiffData :contentType="selectedItem.contentType" :propKey="key" :entry="selectedItem.detail.new[key].de"/>
                  </div>
              </div> 
            </div> 
  
          </v-container>
              </template>
          </Dialog>
    </v-container>
  </template>
  
  <script>
  import Disclosure from '@/components/common/Disclosure.vue'
  import Dialog from '@/components/common/Dialog.vue'
  import DiffData from '@/components/auditLog/DiffData.vue'
  import Common from '@/mixins/Common.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  
  export default {
      name: "AuditLog",
      mixins: [ Common ],
    components: { Disclosure, Dialog, DiffData },
      
      props: {
          entryId: { type: String },
          contentType: { type: String },
          reloadLogs: { type: Boolean } 
      },
  
      data() {
          return {
              auditEntries: [],
        selectedItem: {}
          }
      },
      
    watch: {
      entryId() {
        this.getAuditLog()
      },
      reloadLogs() {
        this.getAuditLog()
      }
    },
  
      computed: {
          headers () {
              return [
                  { text: 'Summary', value: "", sortable: false },
                  { text: 'Updated By', value: "", sortable: false, width: '25%' },
                  { text: 'Updated At', value: "", sortable: false,  width: '15%' }
              ]
          }
      },
  
    mounted() {
      this.getAuditLog()
    },
    
      methods: {
          async getAuditLog() {
            if (this.entryId?.length > 0) {
                try {
                    this.auditEntries = await this.$httpGet(`/audit-log/${this.entryId}`)
                    // this.auditEntries = await this.$httpGet(`/audit-log-client`, { entryId: this.entryId, clientId: this.$store.state.selectedClient.sys.id })
                    console.log(this.auditEntries)
                    for (let entry of this.auditEntries) {
                        //Format Descriptions
                        console.log('log entry', entry)
                        if (entry.detail.new.longDescription) {
                            for (const locale of this.$store.state.locales) {
                                entry.detail.new.longDescription[locale.code] = documentToHtmlString(entry.detail.new.longDescription[locale.code])
                                entry.detail.new.longDescription[locale.code] = entry.detail.new.longDescription[locale.code].replace(/<p>/g, '').replace(/<\/p>/g, '')
                                
                                // in the case of the Create action there is no original data
                                if (entry.detail.original.longDescription) {
                                    entry.detail.original.longDescription[locale.code] = documentToHtmlString(entry.detail.original.longDescription[locale.code])
                                    entry.detail.original.longDescription[locale.code] = entry.detail.original.longDescription[locale.code].replace(/<p>/g, '').replace(/<\/p>/g, '')
                                }
                            }
                        }
                        
                        if (entry.detail.new.importantInformation) {
                            for (const locale of this.$store.state.locales) {
                                entry.detail.new.importantInformation[locale.code] = documentToHtmlString(entry.detail.new.importantInformation[locale.code])
                                entry.detail.new.importantInformation[locale.code] = entry.detail.new.importantInformation[locale.code].replace(/<p>/g, '').replace(/<\/p>/g, '')
                                
                                // in the case of the Create action there is no original data
                                if (entry.detail.original.importantInformation) {
                                    entry.detail.original.importantInformation[locale.code] = documentToHtmlString(entry.detail.original.importantInformation[locale.code])
                                    entry.detail.original.importantInformation[locale.code] = entry.detail.original.importantInformation[locale.code].replace(/<p>/g, '').replace(/<\/p>/g, '')
                                }
                            }
                        }
                        if (entry.detail.original.location || entry.detail.new.location) {
                            // Format the location to show lat/long properties of the object
                            entry.detail.original.location = { de: `Latitude: ${entry.detail.original.location?.de?.lat || ''}, Longitude: ${entry.detail.original.location?.de?.lon || '' }` }
                            entry.detail.new.location = { de: `Latitude: ${entry.detail.new.location?.de?.lat || ''}, Longitude: ${entry.detail.new.location?.de?.lon || '' }` }
                        }
                    }
                    console.log(this.auditEntries)
                    // we only want to show client assignment changes for the selected client, i.e. product category changes of another client should not be shown
                    this.auditEntries = this.auditEntries.filter(entry => entry.contentType !== 'clientAssignment' || entry.client == this.$store.state.selectedClient.sys.id)
                }
                catch (error) {
                    this.showError(error)
                }
            }
          },
          
      async showDetail(item) {
        this.selectedItem = item
        this.$refs.auditDetailDialog.show = true
      },
      }
  }
  </script>
  
  <style scoped>
h3 { font-weight: normal; color: rgb(17, 27, 43); }
.label { width: 15%; font-size: 13pt; font-weight: bold; color: rgb(17, 27, 43); }
.detail { font-size: 13pt; color: rgb(17, 27, 43); }
.audit-entry-container { -moz-box-flex: 1; flex-grow: 1; max-width: 100%; margin-top:20px; }
.audit-entry-header-box { max-width: 50%; height: 48px; display: flex; flex: 1 0 0%; position: relative; background-color: rgb(247, 249, 250); border: 1px solid rgb(219, 219, 219); }
.audit-entry-header { max-width: 50%; position: absolute; inset: 0px; cursor: pointer; padding-left: 20px; padding-top: 13px; }
.audit-entry-row { display: flex; padding: 0px; }
.audit-entry { max-width: 50%; width: 50%; padding: 20px; flex: 1; border: 1px solid rgb(219, 219, 219); }
.audit-entry-name { line-height: 1.5; color: rgb(90, 101, 124); }
  </style>
  

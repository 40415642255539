export const Action = { 
  VIEW: 'view',
  CREATE: 'create', 
  UPDATE: 'update'
}

export const EntryStatus = {
  ACTIVE: 'active',
  PENDING: 'pending',
  DEACTIVATED: 'deactivated'
}

export const CommissionType = {
	CATEGORY: 'attribute_set',
	SALES_CHANNEL: 'service_provider',
	PRODUCT: 'product'
}

import { requestGlobal } from '../../util/inject-util'
import { CheckPolicy, Resource, RT } from '../../util/permission-util'
import { register } from '../../util/rmi-server-util'
import Executive from '../../util/rmi-util'
import { Role, User } from './core.entities'
import { autoUpsert } from '../../util/orm-util'

export { User, Role } from '../entities'

export default class CoreExecutive extends Executive {

	initServer() {
	}

	@CheckPolicy()
	async getUser(@Resource(RT.USER) id: string): Promise<User> {
		return await requestGlobal.db.user.findOneOrFail(id, { populate: [ 'role' ] })
	}

	// TODO: upsert mixes the create and update operations
	//       this makes permission checking awkward
	//       -> we will probably have to add a special case where when we cannot find an object,
	//          we will have to check if the user has permission to create it
	@CheckPolicy()
	async upsertUser(@Resource(RT.USER) data: User): Promise<void> {
		// TODO: could we do this more elegantly and add this function on the repo?
		await autoUpsert(requestGlobal.db.user, data)
	}

	@CheckPolicy()
	async deleteUser(@Resource(RT.USER) id: string): Promise<void> {
		await requestGlobal.db.user.nativeDelete({ id })
	}

	// TODO: add @CheckPolicy()
	async getRole(@Resource(RT.ROLE) id: string): Promise<Role> {
		return await requestGlobal.db.role.findOneOrFail(id)
	}

	async upsertRole(@Resource(RT.ROLE) data: Role): Promise<void> {
		await autoUpsert(requestGlobal.db.role, data)
	}

	async deleteRole(@Resource(RT.ROLE) id: string): Promise<void> {
		await requestGlobal.db.role.nativeDelete({ id })
	}
}

register(CoreExecutive)
// TODO: remove all these imports in the client build
//       how?
//       - semi-explicitely mention them in a regex?
//       - can we remove all (but only in controller files!)?
//         every import in front of an @Controller() or 'extends RmiController'?
import { Controller, Get, Param, ParseIntPipe } from '@nestjs/common'
import { CoreService } from './core.service'
import { User } from 'src/entities'
import RmiController from '../rmi/RmiController'

// TODO: decorator for RMI?
@Controller()
export class CoreController extends RmiController {
	constructor(
		private readonly coreService: CoreService,
	) { super(coreService) }

	// http://localhost:3000/user/3
	@Get('user/:id')
	async getUser(@Param('id', ParseIntPipe) id: number): Promise<User> {
		return this.coreService.getUser(id)
	}

	// TODO: should we rather put this in the service?
	async getUserRmi(id: number): Promise<User> {
		return this.coreService.getUser(id)
	}
}
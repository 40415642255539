<template>
	<!-- We only show translations for content fields if the contentMode of the package is set to 'product'. Certain properties of the model that are content-only should be marked with the @Content() decorator. -->
	<div v-if="!(ticketOnly && field?.contentOnly)">
		<Field
			v-for="locale in effectiveLocales"
			:key="locale"
			v-bind="$props"
			v-model="model"
			:fieldLocale="locale"
			:required="requiredLocales ? requiredLocales.includes(locale) : undefined"
			@update:modelValue="this.$emit('update:modelValue', $event)"
			@input.stop
		/>
	</div>
</template>

<script>
// This comp is being used by TranslationWizard to show multiple fields for multiple locales automatically.
// It also covers the concern of 'which field is even translateable'

export default {
	name: 'TranslateableField',
	props: {
		modelValue: [ String, Number, Object, Array, Boolean ],
		// TODO: rename to typeId and fieldId
		typeName: String,
		fieldName: String,
		disabled: Boolean,
		dataCy: String,
		fieldLocale: String,

		// optional overrides
		title: String,
		required: { type: Boolean, default: undefined },
		localized: { type: Boolean, default: undefined },
		helpText: String,
		infoText: String,
		options: Array,
		locales: Array,
		requiredLocales: { type: Array, default: undefined },
		ticketOnly: { type: Boolean, default: false },
	},
	data: () => ({
		model: null,
		field: null,
		type: null,
	}),
	computed: {
		effectiveLocales() {
			if (this.field?.localized) {
				if (this.locales) return this.locales
				if (this.fieldLocale) [ this.fieldLocale ]
				if (this.$store.state.serviceLocale) return [ this.$store.state.serviceLocale ]
			}
			return [ 'de' ]
		},
	},
	watch: {
		modelValue(n) {
			this.model = n
		},
	},
	mounted() {
		this.model = this.modelValue
		if (this.typeName) {
			// TODO: this is copied from Field - generalize
			// TODO: inject the models instead of taking them from the store
			const type = this.$store.state.fieldModels.find(({ name }) => name === this.typeName)
			if (!type) return console.warn('Field: type not found [' +  this.typeName + ']')
			this.field = type?.fields[this.fieldName]
			if (!this.field) return console.warn('Field: field not found [' + this.typeName + '.' + this.fieldName + ']')
			// TODO: this needs to be dynamic and not checked against hardcoded fields. Idea: extend the model definitions by providing an annotation on fields that are content-related and should be hidden in ticket-only mode. Then check against this annotation here.
			if (this.ticketOnly && (this.field.name == 'shortDescription' || this.field.name == 'longDescription')) {
				// do not show these fields for ticket only content
			}
			this.type = this.field.type
			if (this.model == undefined) {
				console.warn('Field: model is undefined for [' + this.typeName + '.' + this.fieldName + ']')
			}
		}
	},
}
</script>
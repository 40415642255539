// see documentation here:
// https://chillimetrix.alturos.com/confluence/display/MYS/MYS+-+Pattern+-+RMI

// Executive classes form the top layer of the services.
// they also replace the classical controllers in the MVC pattern,
// while the technical concerns to the controller are handled by the rmi-controller.

// UI:				TestRmi >
// Framework (UI):		TestExecutive(container) >
// Framework (Server):		rmi.controller > rmi.service >
// Executive Layer:				TestExecutive >
// other names: Business, Logic, ..
// Technical Layer:					TestTechnical >
// other names? Primitive, Domain, Lego, Blocks, ..
// Storage Layer:						PackagePeaksolutionDatabase >
//											MagentoDatabase

//import { Entry2 } from './entry'
import { jsonToObject, objectToJson } from './serialization-util'

export class BadRequestError extends Error {
	constructor(public detail: any) {
		super(detail)
	}
}

export class RmiRequest {
	targetClass: string
	targetState: any
	method: string
	args: any[]
}

// TODO: what about different scopes?
//       currently every instance will add itself
export const controllers = {}

export default class RmiController {
	public isServer: boolean
	public endpoint = '/api2nest/RMI'
//	private container

	constructor(container) {
		return this.wrap(null, container)
	}

	wrap(state = null, container = null) {
		if (state) {
// TODO: this is dangerous
//			Object.assign(this, state)
		}

//		this.container = container
		this.isServer = typeof window === 'undefined'
		if (this.isServer) {
// TODO: this feels hacky - is there a better way with nest? DI?
			controllers[this.constructor.name] = this
			this.initServer()
		}
		else {
			if (container?.$store) {
				return new Proxy(this, {
					get: function(target, prop: string, receiver) {
						const val = target[prop]
						// TODO: maybe only if the function is decorated with @Rmi?
						if (typeof val === 'function') {
							return async function(...args) {
								return await handle(target, prop, args, container)
							}
						}
						return val
					},
					/*
					ownKeys: function(target) {
						console.log('== ownKeys')
						return Object.keys(target)
					},
					*/
					getOwnPropertyDescriptor: function(target, prop) {
						// we remove visibility of isServer
						if (prop == 'isServer') return { configurable: true, enumerable: false }
						return Reflect.getOwnPropertyDescriptor(target, prop)
					},
				})
			}
			else if (container?.el) {
				console.warn(this.constructor.name, ': given container component does not have $store. Not proxying. container:', container)
			}
			else {
				console.warn(this.constructor.name, ': no container given. For RMI to work, you need to pass the container component.')
			}
		}
	}

	// 1. client sends request to server
	async requestToJson(object: RmiRequest) {
		return objectToJson(object)
	}

	// 2. server receives request
	async jsonToRequest(text: string): Promise<RmiRequest> {
		return jsonToObject(text)
	}

	// TODO: maybe the server should respond with a wrapper object instead?
	// 3. server sends response to client
	async resultToJson(object: any) {
		return objectToJson(object)
	}

	// 4. client receives response
	async jsonToResult(text: string) {
		return jsonToObject(text)
	}

	isStreamMethod(request: { method: string, args: any[]}) {
		if (request.args.some(a => a == 'f()')) return true
		const method = request.method.toLocaleLowerCase()
		return method.includes('stream') || method.includes('chunked')
	}

	// Executives may be cached, these methods provide some facilities for that.

	// getKey() may for example return the client id if we want an instance per client id.
	getKey() {}
	// expired() may return true if the instance is no longer valid.
	expired() { return false }

	// lifecycle methods
	initServer() {}
	destroyServer() {}
}

// This is pure client side code (also some code above is).
// TODO: preferrably this would be located somewhere in the dashboard folder
// TODO: inject this logic via the "container" so it can completely stay in the client implementation?
//       but then we would probably have to use a mixin on every view which is not nice either
async function handle(target: RmiController, prop, originalArgs, container?: any, retry = 0) {
	async function handleRetry() {
		await new Promise(r => setTimeout(r, 3000))
		return await handle(target, prop, originalArgs, container, retry + 1)
	}

	const s = container?.$store?.state
	const user = s?.loggedInUser
	const client = s?.selectedClient
	const app = s?.selectedApplication

	let method = 'post'
	const targetClass = target.constructor.name
	// we remove the functions from the args and use it as a callback
	const streamCallback = originalArgs.find(a => typeof a == 'function')
	const args = originalArgs.map(a => typeof a == 'function' ? 'f()' : a)
	const argString = (args?.map(a => ('' + a)
		.replace('[object Object]', 'o')
		.substring(0, 12)
	))?.join(',')
	const endpoint = target.endpoint ?? '/api/RMI'
	const url = endpoint + '?' + targetClass + '.' + prop + '(' + argString + ')'
	const response = await fetch(url, {
		method,
		headers: {
			'Authorization': 'Bearer ' + user?.kc_token,
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
			'mys-user-id': user?.sys?.id ?? '',
			'mys-user-type': user?.fields?.type?.de ?? '',
			'mys-client-id': client?.sys?.id ?? '',
			'mys-app-id': app?.sys?.id,
			'mys-tx-id': Date.now() + '',
		},
		body: await target.requestToJson({
			targetClass,
			targetState: null, //target,
			method: prop,
			args,
		}),
	})

	if (response.headers.get('X-Rmi-Mode') == 'stream') {
	//if (response.headers.get('Transfer-Encoding') == 'chunked') {
		if (response.status == 500 && retry < 10) return handleRetry()
		return await readResponseChuncked(target, response, streamCallback)
	}

	const text = await response.text()
	// TODO: may also want to do this on 503 and 502
	// empty response indicates server currently down -> retry
	if (response.status == 500 && text == '' && retry < 10) return handleRetry()
	const result = await target.jsonToResult(text)
	// TODO: can we make properly typed errors happen somehow?
	if (response.status == 400) throw result
	if (response.status == 500 && result.isUnexpectedError) throw result
	return result
}

async function readResponseChuncked(target: RmiController, response: Response, callback: Function): Promise<any> {
	const reader = response.body.getReader()
	let partText = ''
	let part
	let r
	while (true) {
		if (!r) r = await reader.read()
		if (r.done && !r.value?.length) break
		// sum up data until it is a valid json object (in case a part is delivered in multiple chunks)
		partText = partText + new TextDecoder().decode(r.value)
		try {
			// we only use the standard parser to check if it is valid JSON
			const test = JSON.parse(partText)
			// and then use the proper parser
			part = await target.jsonToResult(partText)
			partText = ''

			// we already start reading before calling the callback, but just barely:
			// after some time we check if we are done, if not, we call the callback
			r = undefined
			let wasLast
			await Promise.all([
				(async () => {
					r = await reader.read()
					if (r.done) wasLast = true
				})(),
				(async () => {
					await new Promise(r => setTimeout(r, 20))
					if (wasLast) return
					callback(part)
				})(),
			])
		}
		catch (e) {
			// if we encountered a parse error, we continue to read the next chunk
			if (e instanceof SyntaxError) continue
			throw e
		}
	}
	return part
}
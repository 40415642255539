<template>
	<Application :loading="loading" v-model:errorTitle="errorTitle" :errorDetail="errorDetail" v-model:successTitle="successTitle" :successDetail="successDetail">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<v-row justify="center" style="overflow:hidden;">
				<h1>{{ title }}</h1>
			</v-row>
			<v-spacer />
			<v-btn class="gradientButton mr-2" elevation="0" @input="noToggle($event)" data-cy="addTicketType" @click="showAddOptionDialog('ticketType')">
				<v-icon size="24px">mdi-plus</v-icon>
				<span class="d-none d-lg-inline">{{this.$t('text.addTicketType')}}</span>
				<span class="d-lg-none">{{this.$t('text.type')}}</span>
			</v-btn>
			<v-btn class="gradientButton" elevation="0" @input="noToggle($event)" data-cy="addReduction" @click="showAddOptionDialog('reduction')">
				<v-icon size="24px">mdi-plus</v-icon>
				<span class="d-none d-lg-inline">{{this.$t('text.addReduction')}}</span>
				<span class="d-lg-none">{{this.$t('text.category')}}</span>
			</v-btn>
		</template>

		<div class="container-grid">
			<!-- TicketTypes for service provider in client options-->
			<v-card class="tableCard">
				<v-data-table fixed-header hide-default-footer disable-sort
					:headers="ticketHeaders"
					:items="ticketTypes"
					:items-per-page="999999"
					class="elevation-0"
					:item-value="item => item"
				>
					<template v-slot:item="{ item }">
						<tr :class="{clientOption: item.source === 'CLIENT'}" @click="!item.source ? showEditOptionDialog(item, 'ticketType') : ''" :key="item.sys.id">
							<td>{{ returnTitle(item) }}</td>
							<td>
								<v-chip v-if="item.fields.status" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
								<v-chip v-else :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
							</td>
						</tr>
					</template>
					<template #bottom></template>
				</v-data-table>
			</v-card>

			<!-- Reductions for service provider in client options-->
			<v-card class="tableCard">
				<v-data-table fixed-header hide-default-footer disable-sort
					:headers="reductionHeaders"
					:items="reductions"
					:items-per-page="999999"
					class="elevation-0"
					:item-value="item => item"
				>
					<template v-slot:item="{ item }">
						<tr :class="{clientOption: item.source === 'CLIENT'}" @click="!item.source ? showEditOptionDialog(item, 'reduction') : ''" :key="item.sys.id">
							<td><v-icon v-if="item.defaultAssignment"> mdi-minus-circle</v-icon>    {{ returnTitle(item) }}</td>
							<td>
								<v-chip v-if="item.fields.status" style="margin-right:10px" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
								<v-chip v-else style="margin-right:10px" :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
							</td>
						</tr>
					</template>
					<template #bottom></template>
				</v-data-table>
			</v-card>
		</div>

		<Dialog ref="serviceAddOptionDialog"
			:title="addOptionTitle"
			:confirmLabel="$t('text.addOption')"
			:cancelLabel="$t('text.discardChanges')"
			:confirm-handler="addOption"
			:cancel-handler="resetOption" 
			:show-close="false"
		>
			<template #content>
				<div style="width: 100%;">
					<div v-for="(value, locale) in name" :key="locale" style="display: flex; gap: 10px;">
						<LanguageFlag :modelValue="locale" style="margin-top: 17px;" />
						<v-text-field
							data-cy="addOptionField" 
							variant="outlined"
							v-model.trim="name[locale]"
						/>
					</div>
				</div>
			</template>
		</Dialog>

		<Dialog ref="serviceEditOptionDialog"
			:title="editOptionTitle"
			:confirmLabel="$t('text.saveChanges')"
			:cancelLabel="$t('text.discardChanges')"
			:deleteLabel="$t('text.deleteOption')"
			:confirm-handler="editOption"
			:cancel-handler="resetOption"
			:delete-handler="deleteOption"
			:show-close="false"
		>
			<template #content>
				<div style="width: 100%;">
					<div v-for="(value, locale) in editItem.fields.title" :key="locale"
						style="display: flex; gap: 10px;"
					>
						<LanguageFlag :model-value="locale" style="margin-top: 20px;" />
						<v-text-field class="col-10 col-lg-11" data-cy="editOptionField" variant="outlined" v-model="editItem.fields.title[locale]"></v-text-field>
					</div>
				</div>
			</template>
		</Dialog>
	</Application>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'
import Application from '../Application.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'

export default {
	name: 'ServiceOptionsForProvider',
	components: { Dialog, Application, LanguageFlag },
  	mixins: [ Common ],
	props: {
		serviceProviderClicked: Boolean,
		title: String,
	},
	data: () => ({
		itemType: 'all',
		serviceOptions: [],
		ticketTypes: [],
		reductions: [],

		loading: false,

		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',

		optionType: '',

		name: {},
		editItem: {},
		originalItem: {},

		toggle_none: null,
	}),
	computed: {
		ticketHeaders () {
			return [
				{ title: this.$t('text.ticketTypes'), align: 'left', sortable: true, key: 'item_type' },
				{ text: 'Status', width:'100px' },
			]
		},
		reductionHeaders () {
			return [
				{ title: this.$t('text.reductions'), sortable: true, key: 'item_type' },
				{ title: 'Status', width:'100px', key: 'status' },
			]
		},
		editOptionTitle() {
			if (this.optionType === 'ticketType') {
				return this.$t('text.editTicketType')
			}
			else if (this.optionType === 'reduction') {
				return this.$t('text.editReduction')
			}
			return ''
		},
		addOptionTitle() {
			if (this.optionType === 'ticketType') {
				return this.$t('text.addTicketType')
			}
			else if (this.optionType === 'reduction') {
				return this.$t('text.addReduction')
			}
			return ''
		}
	},
	methods: {
		goback() {
			this.$emit('go-back')
		},
		noToggle(event){
			this.toggle_none = event
		},
		returnTitle (item) {
			return item.fields?.title ? item.fields?.title[this.selectedLocale] : item.fields?.title.de ? item.fields?.title.de : 'NO_TITLE'
		},
		mergeOptions(spOptions, clientOptions) {
			let lookup = {}
			if (spOptions) {
				for (let o of spOptions)
				lookup[o.sys.id] = o
			}
			if (clientOptions) {
				for (let o of clientOptions) {
				if (!o.source) o['source'] = 'CLIENT'
					lookup[o.sys.id] = o
				}
			}
			return Object.values(lookup)
		},
		async getProviderOptions() {
			this.loading = true
			try {
				const p1 = this.$httpGet(`/provider-option?id=${this.$store.state.selectedServiceProvider.sys.id}&itemType=${this.itemType}&locale=${this.selectedLocale}`)
				const p2 = this.getServiceProvider()
				let res = await p1

				this.ticketTypes = res.options.ticketType ?? []
				this.reductions = res.options.reduction ?? []
				await p2
			}
			catch(error) {
				this.errorTitle = this.$t('text.ERROR');
				if (error.response) {
					this.errorDetail = error.response.data.error;
				}
				else {
					this.errorDetail = error;
				}
			}
			this.loading = false
		},
		async getServiceProvider() {
			var id = this.$store.state.selectedServiceProvider?.sys?.id
			if (!id) return

			try {
				const res = await this.$httpGet(`/serviceprovider/${id}`)
				
				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
					console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
		},
		showAddOptionDialog(itemType) {
			this.clear();
			this.optionType = itemType
			this.$refs.serviceAddOptionDialog.show = true;
		},
		showEditOptionDialog(item, type) {
			if (item && !this.isChangeDefault) {
				//Fill in missing locales
				for (const locale of this.locales) {
					if (!item.fields.title[locale.code]) {
						item.fields.title[locale.code] = ''
					}
				}
				this.optionType = type
				this.originalItem = JSON.parse(JSON.stringify(item))
				this.editItem = JSON.parse(JSON.stringify(item))
				this.$refs.serviceEditOptionDialog.show = true
			}
		},
		clear() {
			this.errorTitle = ''
			this.errorDetail = ''
			this.successTitle = ''
			this.successDetail = ''

			this.requestSubmitted = false

			this.resetOption()
		},
		resetOption() {
			for (const locale of this.locales) {
				this.name[locale.code] = ''
			}
			this.editItem = JSON.parse(JSON.stringify(this.originalItem));
		},
		showError(detail, title) {
			console.warn('showError', detail, new Error())
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		getColor (status) {
			if (status === "pending")  {
				return '#ff9e21'
			}
			else if (status === "active") {
				return '#66BB6A'
			}
		},
		addOption() {
			this.loading = true;
			let data
			// service provider adds option
			data = {
				itemType: this.optionType,
				name: this.name,
				serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
				clientId: this.$store.state.selectedClient.sys.id,
			}

			this.$httpPost(`/option`, data).then(res => {
				this.clear()

				this.$refs.serviceAddOptionDialog.show = false

				this.successTitle = this.$t('text.SERVICE_OPTION_CREATED')
				this.successDetail = this.$t('text.serviceOptionCreatedSuccess')

				this.getProviderOptions()

				return true
			}).catch(error => {
				this.loading = false
				this.errorAlertVisible = true

				this.$refs.serviceAddOptionDialog.show = false

				this.errorTitle = this.$t('text.ERROR')

				if (error.response) {
					if (error.response.data.error) {
						error.response.data.error === 'Please provide at least one name for the option' ? this.errorDetail = this.$t('text.optionHasNoName') : this.errorDetail = error.response.data.error
						console.error(error)
					}
				} else {
					this.errorDetail = error
				}
			})
		},
		// TODO: needs to be adjusted for sp only
		editOption() {
			this.loading = true

			let data

			// service provider edits option
			data = {
				item: this.editItem,
				clientId: this.$store.state.selectedClient.sys.id,
				serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
			}

			this.$httpPut(`/option`, data).then(res => {
				this.clear()

				this.$refs.serviceEditOptionDialog.show = false

				this.successTitle = this.$t('text.SERVICE_OPTION_UPDATED')
				this.successDetail = this.$t('text.serviceOptionUpdatedSuccess')

				this.getProviderOptions()
				return true

			}).catch(error => {
				if (error.response?.status == 401) return this.$emit("show-login")
					this.showError(error.response ? error.response?.data.error : error)
			})
		},
		// TODO: needs to be adjusted for sp only
		deleteOption() {
			this.loading = true

			this.axios.delete(`/option`, {
				headers: this.requestHeaders(),
				data: {
					option: this.originalItem,
					clientId: this.$store.state.selectedClient.sys.id,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
				},
			}).then(res => {
				this.loading = false
				this.$refs.serviceEditOptionDialog.show = false

				if (res?.message === 'cannot delete option') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.serviceOptionInUseError')
				}
				else {
					this.successTitle = this.$t('text.OPTION_REQUEST')
					this.successDetail = this.$t('text.optionRequestDeleteSuccess')

					this.getProviderOptions()
				}

			}).catch(error => {
				if (error.response?.status == 401) return this.$emit("show-login")
					this.showError(error.response ? error.response?.data.error : error)
			})
		},
	},
	async mounted() {
		await this.getProviderOptions()
	},
}
</script>

<style scoped>
.tableCard {
  border-radius:5px;
  background-color:#808080 !important;
  top: 0px;
}
.clientOption {
  cursor: not-allowed;
  background-color: #f2f2f2 !important;
}
.dialogDiv {
  position:absolute;
  right:70px;
  top:55px;
  width:465px;
  max-width: calc(100vw - 20px);
  background:#ffffff;
  border-radius:5px;
  border:thin solid #cfcfcf;
  box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
  padding:20px;
  padding-bottom:0px;
  color:#acacac;
  z-index: 999;
}
.optionRow {
  min-height: 42px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-top: 10px;
  margin-bottom: 10px;
}
.buttonGroup {
	margin-top:-5px;
	height:20px;
	background: linear-gradient(0deg, #efefef 0%, #ffffff 100%);
	font-family: 'Inter', sans-serif;
	font-size: 12pt ;
	text-transform: none;
	letter-spacing: 0;
}
</style>
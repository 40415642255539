<template>
	<v-row justify="center">
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />

		<Disclosure :title="$t('text.commissions')" :error="sectionMessage.error" :message="sectionMessage.message" data-cy="commissionOverview" ref="SECTION_commissions" :expanded="true">
			<div v-if="!hasPeakId" class="warn">
				<span class="d-flex align-items-center mb-3 mt-3">
					<v-icon color="#ff9e21">mdi-alert</v-icon>&nbsp;&nbsp;
					<span class="warning-text">{{ $t('text.warning') }}</span>
				</span>
				{{ $t('text.spNotInPeak') }}
			</div>

			<div class="field left-border" style="display: flex; gap: 10px; flex-direction: column;">
				<p v-html="$t('text.commissionsHelpTitle')"/>
				<p class="text" v-html="$t('text.commissionsHelpText')"/>
				
				<!-- Enable Commissions -->
				<div style="display: flex; gap: 10px;">
					<div style="flex-grow: 1; width: 100%;">
						<h2 style="color:#000;line-height:1.1">{{ $t('text.enableCommissions') }}</h2>
						<p v-html="$t('text.enableCommissionsHelpText')"/>
					</div>
					<mys-switch
						v-model="clientCA.fields.enabledCommissions.de"
						@update:modelValue="toggleEnabledCommissions"
					/>
				</div>

				<!-- Enable Time-Based Commissions -->
				<div style="display: flex; gap: 10px;">
					<div style="flex-grow: 1; width: 100%;">
						<h2 style="color:#000;line-height:1.1">{{ $t('text.enableTimeBasedCommissions') }}</h2>
						<p v-html="$t('text.enableTimeBasedCommissionsHelpText')"/>
					</div>
					<mys-switch
						v-model="clientCA.fields.enabledTimeBasedCommissions.de"
						@update:modelValue="toggleTimeBased"
					/>
				</div>
			</div>

			<!-- Overall Default -->
			<div class="field left-border">
				<div style="display: flex; gap: 10px;">
					<h2 style="color: #000; flex-grow: 1;">{{ $t('text.overallDefault') }}</h2>

					<v-spacer/>

					<!-- View -->
					<v-row align="center" v-if="!showDefaultEdit" style="flex-grow: 0;">
						<v-text-field variant="outlined" density="compact" hide-details style="min-width: 80px; max-width: 80px;"
							disabled
							v-model="overallDefault" 
							append-inner-icon="mdi-percent"
						/>
						<v-btn class="none mini-btn" elevation="0" 
							v-if="clientCA.fields.enabledCommissions.de"
							@click="enableEditDefault()">
							<v-icon color="#000000">mdi-pencil</v-icon>
						</v-btn>
					</v-row>

					<!-- Edit -->
					<v-text-field variant="outlined" density="compact" hide-details style="min-width: 80px; max-width: 80px;"
						v-if="showDefaultEdit" 
						:disabled="!clientCA.fields.enabledCommissions.de"
						v-model="overallDefault" 
						@keypress="checkPercentage(overallDefault, $event)"
						@keyup="checkPercentage(overallDefault, $event)" 
						@blur="upsertOverallDefault"
						append-inner-icon="mdi-percent"
					/>
				</div>
				<v-label>{{ $t('text.overallDefaultCommission') }}</v-label>
				<p v-html="$t('text.overallDefaultCommissionHelpText')"/>
			</div>

			<!-- Specific Commissions -->
			<div class="field left-border">
				<h2 style="color: #000;">{{ $t('text.specificCommissions') }}</h2>
				<p v-html="$t('text.specificCommissionsHelpText')" />
				<div style="display: flex; gap: 10px; margin-top: 10px;">
					<h2 v-if="commissions.length===0" disabled>{{ $t('text.noSpecificCommissionsSet') }}</h2>
					<v-spacer/>
					<!--<v-btn class="gradientButton pr-5 mr-5" :disabled="isDisabled" elevation="0">{{$t('text.export')}}<v-icon>mdi-menu-down</v-icon></v-btn>-->
					<v-btn class="gradientButton" elevation="0" :disabled="isDisabled" @click="addCommission()"><v-icon>mdi-plus</v-icon>{{ $t('text.addCommission') }}</v-btn>
				</div>
				<v-card flat v-if="salesChannels.length">
					<div v-for="salesChannel in salesChannels" :key="salesChannel.website.id">
						<div>{{ salesChannel.website.name }}</div>
						<SalesChannelSummary
							:commissions="salesChannel.commissions"
							:isDisabled="isDisabled"
							@delete="sendDelete"
							@edit="showEdit"
						/>
					</div>
				</v-card>
			</div>
		</Disclosure>

		<!-- Add Commission -->
		<CommissionWizard ref="commissionWizard"
			:title="wizardTitle"
			:showStepper="showStepper"
			:serviceProvider="serviceProvider"
			:datesDisabled="datesDisabled"
			@update="sendUpdate"
		/>

		<!-- Edit Commission -->
		<CommissionDetail ref="commissionDetail"
			:commission="selectedCommission"
			:defaultCommissions="defaultCommissions"
			:existingCommissions="existingCommissions" 
			:serviceProvider="serviceProvider"
			:datesDisabled="datesDisabled"
			@update="sendUpdate"
		/>

		<!-- TODO: component for such warning confirm dialogs -->
		<!-- Confirm Disable -->
		<Dialog ref="disableCommsDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="disableCommissions"
			:cancel-handler="onDisableCancelled"
			:showClose="true"
			:title="$t('text.disableCommissions')" 
			height="350px"
			width="550px"
		>
			<template #content>
				<v-row align="center" justify="center">
					<v-col cols="4" style="text-align:center">
						<v-icon size="80" color="#FDB537">mdi-alert-rhombus</v-icon>
					</v-col>
					<v-col cols="8" class="pr-5">
						<h2>{{ $t('text.disableCommissions') }}</h2>
						<p>{{ $t('text.disableCommissionsText') }}</p>
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</v-row>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import CommissionWizard from './CommissionWizard.vue'
import CommissionDetail from './CommissionDetail.vue'
import SalesChannelSummary from './SalesChannelSummary.vue'
import { Commission } from '@/models/commission.ts'
import { CommissionType } from '@/plugins/enum.js'

export default {
	name: "CommissionsOverview",
	components: { Loading, Disclosure, Dialog, CommissionWizard, CommissionDetail, SalesChannelSummary },
	mixins: [Common],
	props: {
		serviceProvider: { type: Object, required: true },
		clientAssignment: { type: Object, required: true },
		commissions: Array,
		updateModel: Boolean
	},
	data() { return {
		loading: false,
		model: {},
		clientCA: {},
		initData: {},
		wizardTitle: '',
		showStepper: false,
		salesChannels: [],
		selectedCommission: null,
		defaultCommissions: [],
		existingCommissions: [],
		overallDefault: null,
		showDefaultEdit: false,
		tab: 0,
		isDisabled: false,
		datesDisabled: false,

		sectionMessage: {
			error: false,
			message: ''
		}
	}},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.clientCA = this.valueToModel(this.clientAssignment)
			this.formatCommissionsView()
		},
		commissions: {
			deep: true,
			handler() {
				this.formatCommissionsView()
			}
		}
	},
	computed: {
		hasPeakId() {
			return this.clientAssignment?.fields?.externalId?.de?.toString?.()?.length > 0
		}
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		validateAllFields() {
			this.resetSectionError(this.sectionMessage)

			if (this.userIsMainOperator) {
				if (this.clientCA.fields.enabledCommissions?.de === true &&
					!this.clientCA.fields.defaultCommission?.de) {
					this.setSectionError(this.sectionMessage, this.$t('text.defaultCommissionRequired'))
					return false
				}
			}
			
			return true
		},
		async toggleEnabledCommissions() {
			if (this.clientCA.fields.enabledCommissions?.de !== true) {
				this.$refs.disableCommsDialog.show = true
			} else {
				this.clientCA.fields.enabledCommissions = { de: true }
				this.isDisabled = false
				await this.enableCommissions()
			}
		},
		async disableCommissions() {
			try {
				this.loading = true
				const data = { clientAssignmentId: this.clientAssignment.sys.id }
				this.clientCA = await this.$httpPost('/disable-commissions', data)
				if (!this.clientCA.fields.hasOwnProperty('defaultCommission')) { this.clientCA.fields.defaultCommission = { de: null } }
				this.$emit('update-ca', this.clientCA)
				this.loading = false
			} catch (error) {
				this.showError(error)
			}

			this.isDisabled = true
			this.$refs.disableCommsDialog.show = false
		},
		async enableCommissions() {
			try {
				this.loading = true	
				const data = { clientAssignmentId: this.clientAssignment.sys.id }
				this.clientCA = await this.$httpPost('/enable-commissions', data)
				if (!this.clientCA.fields.hasOwnProperty('defaultCommission')) { this.clientCA.fields.defaultCommission = { de: null } }
				this.$emit('update-ca', this.clientCA)
				this.loading = false
				
			} catch (error) {
				this.showError(error)
			}
			
			this.isDisabled = false
			this.$refs.disableCommsDialog.show = false
		},
		async upsertOverallDefault() {
			if (!this.overallDefault) {
				this.overallDefault = 0
			}

			this.loading = true
			if (!this.clientCA.fields.defaultCommission?.de || this.clientCA.fields.defaultCommission.de.hasOwnProperty('error')) {
				let newCommission = new Commission()
				newCommission.sys.id = 'id_' + (this.model?.length ? this.model.length : '0')
				newCommission.fields.type = CommissionType.SALES_CHANNEL
				
				this.clientCA.fields.defaultCommission.de = newCommission
			}

			this.overallDefault = this.overallDefault > 100 ? 100 : this.overallDefault
			this.clientCA.fields.defaultCommission.de.fields.percentage = this.overallDefault

			const data = {
				commission: this.clientCA.fields.defaultCommission.de,
				clientAssignmentId: this.clientCA.sys.id
			}

			this.clientCA = await this.$httpPost('/default-commission', data)

			if (!this.clientCA.fields.hasOwnProperty('enabledCommissions')) { this.clientCA.fields.enabledCommissions = { de: false } }
			if (!this.clientCA.fields.hasOwnProperty('enabledTimeBasedCommissions')) { this.clientCA.fields.enabledTimeBasedCommissions = { de: false } }
			if (!this.clientCA.fields.hasOwnProperty('defaultCommission')) { this.clientCA.fields.defaultCommission = { de: null } }
			
			this.loading = false
			this.showDefaultEdit = false
			this.$emit('update-ca', this.clientCA)
		},
		async toggleTimeBased() {
			const timebasedEnabled = this.clientCA.fields.enabledTimeBasedCommissions.de

			if (!this.clientCA.fields.enabledCommissions?.de) {
				await this.enableCommissions()
			}

			this.loading = true
			const data = {
				clientAssignmentId: this.clientCA.sys.id,
				enabled: timebasedEnabled
			}

			this.clientCA = await this.$httpPost('/timebased-commissions', data)
			this.datesDisabled = !this.clientCA.fields.enabledTimeBasedCommissions.de

			this.loading = false
			this.$emit('update-ca', this.clientCA)
		},
		onDisableCancelled() {
			this.clientCA.fields.enabledCommissions.de = true
			this.$refs.disableCommsDialog.show = false
			this.isDisabled = false
		},
		addCommission() {
			this.wizardTitle = this.$t('text.newCommission')
			this.showStepper = true
			this.$refs.commissionWizard.show = true
		},
		showEdit(commission) {
			this.selectedCommission = commission
			this.existingCommissions = this.commissions?.filter(comm => comm?.fields?.type === commission.fields.type)
			this.$refs.commissionDetail.show = true
		},
		formatCommissionsView() {
			this.defaultCommissions = this.commissions?.filter(comm => comm?.fields?.hasOwnProperty('peakWebsiteId') === false || comm?.fields?.peakWebsiteId?.length === 0) ?? []
			
			const scs = [{
				website: {
					id: 0,
					name: this.$t('text.default'),
				},
				commissions: this.defaultCommissions,
			}]
			
			const clientSalesChannels = this.$store.state.selectedClient.fields.salesChannels.de
			
			for (const salesChannel of clientSalesChannels) {
				scs.push({
					website: {
// TODO V3: peakId is null in some cases (see serviceProvider 'zzz') - why? this cant be good for the comp..
//          object "Achensee Intermediate does not have a peak id..
						id: salesChannel.fields.peakId?.de,
						name: salesChannel.fields.title?.de,
					},
					commissions: this.commissions?.filter(comm => comm?.fields?.peakWebsiteId && comm?.fields?.peakWebsiteId === salesChannel.fields.peakId?.de) ?? []
				})
			}

			scs.sort((a, b) => {
				// TODO: we are relying on the name being 'Default' - this is not good
				//       can we determine defaults some other way?
				a = a.website.name == 'Default' ? '__' : a.website.name
				b = b.website.name == 'Default' ? '__' : b.website.name
				return a.localeCompare(b)
			})
			this.salesChannels = scs
		},
		sendUpdate(event) {
			this.$emit('update', event)
		},
		sendDelete(commission) {
			this.$emit('delete', commission)
		},
		enableEditDefault() {
			this.showDefaultEdit = true
		}
	},
	created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.clientCA = this.valueToModel(this.clientAssignment)

		if (!this.clientCA.fields.hasOwnProperty('enabledCommissions')) { this.clientCA.fields.enabledCommissions = { de: false } }
		if (!this.clientCA.fields.hasOwnProperty('enabledTimeBasedCommissions')) { this.clientCA.fields.enabledTimeBasedCommissions = { de: false } }
		if (!this.clientCA.fields.hasOwnProperty('defaultCommission')) { this.clientCA.fields.defaultCommission = { de: null } }
		
		this.isDisabled = !this.clientCA.fields.enabledCommissions.de
		this.overallDefault = this.clientCA.fields.defaultCommission.de?.fields?.percentage
		this.showDefaultEdit = !this.overallDefault
		this.datesDisabled = !this.clientCA.fields.enabledTimeBasedCommissions.de

		this.formatCommissionsView()
	},
}
</script>

<style scoped>
.v-application .primary--text { caret-color: #16B0EF !important; }
.v-tab { text-transform: none; letter-spacing: 0;}
.text { color: black !important; font-family: 'Inter', sans-serif !important; font-size: 13pt !important; }
h2 { color: #999999; font-size: 14pt; font-weight: normal; line-height: 50px; }
</style>
<template>
	<Teleport to="#app">
		<div v-if="show" :class="{backdrop}" @click="backdropClick">
		<div class="dialog-div" :style="`height:${height};width:${width}`">
			<div v-if="isBeta" class="beta-top">
			<div class="corner"></div>
			<div class="beta">beta</div>
			</div>
			<v-toolbar elevation="0" color="white" style="width: 100%" class="dialog-header">
			<div style="width: 100%; display: flex; align-items: center; justify-items: space-between;">
				<div style="display: flex; flex-direction: column;">
					<v-toolbar-title style="margin-left:20px;">{{ title }}</v-toolbar-title>
					<p v-if="subtitle" style="margin-left:20px;" v-html="subtitle"></p>
				</div>
				
				<!-- Stepper -->
				<StepBar :steps="steps" :clickable="false" :showDividers="true" class="StepBar" />

				<v-btn v-if="showClose" class="none" icon style="margin-right:10px; margin-bottom: 10px;" elevation="0"
						@click="closeDialog()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			</v-toolbar>
			<v-divider style="border-color:#646464"/>
	
			<v-layout row wrap style="max-height: 80vh; max-width: 80vw; overflow: hidden;">
				<v-col class="d-flex ma-0" style=" flex-grow: 1; padding: 0px;">
					<slot name="content" style="max-height: 80vh; max-width: 70vw"/>
				</v-col>
			</v-layout>

	
			<v-divider style="border-color:#646464"/>
			<v-toolbar elevation="0" color="white" style="width: 100%" height="60" class="dialog-footer">
			<v-spacer></v-spacer>
			<v-btn class="gradientButton" theme="dark" elevation="0" v-if="cancelLabel && !stepper"
				@click="cancel()"
				data-cy="cancel"
			>{{ cancelLabel }}</v-btn>
			<v-btn class="redButton" theme="dark" elevation="0" v-if="deleteLabel" 
				@click="remove()"
				data-cy="delete"
			><v-icon size="24px" color="#fb3640">mdi-delete</v-icon> {{ deleteLabel }}</v-btn>

			<v-btn class="gradientButton" theme="dark" elevation="0" v-if="previousStepLabel && stepper"
				@click="previousStep()"
				data-cy="previousStep"
			>{{ currentStep !== 1 ? previousStepLabel : cancelLabel }}</v-btn>

			<v-btn class="greenButton" theme="dark" elevation="0" v-if="nextStepLabel && stepper"
				@click="nextStep()"
				:disabled="!isValid"
				data-cy="nextStep"
			>{{ currentStep !== 3 ? nextStepLabel : confirmLabel }}</v-btn>
	
			<v-btn class="greenButton" theme="dark" elevation="0"  v-if="confirmLabel && !stepper" 
				@click="confirm()"
				:disabled="!isValid"
				data-cy="confirm"
			>{{ confirmLabel }}</v-btn>
			</v-toolbar>
		</div>
		</div>
	</Teleport>
  </template>
  
  <script>
  import StepBar from '@/components/common/StepBar.vue'
  export default {
	name: "MediaEditorDialog",
	components: {
	  StepBar
	},
  
	props: {
	  title: {
		type: String
	  },
	  subtitle: {
		type: String
	  },
	  stepper: {
		type: Boolean,
		default: false
	  },
	  header: {
		type: Boolean,
		default: true
	  },
	  Footer: {
		type: Boolean,
		default: true
	  },
	  confirmLabel: {
		type: String
	  },
	  cancelLabel: {
		type: String
	  },
	  nextStepLabel: {
		type: String
	  },
	  previousStepLabel: {
		type: String
	  },
	  deleteLabel: {
		type: String
	  },
	  confirmHandler: {
		type: Function
	  },
	  cancelHandler: {
		type: Function
	  },
	  closeHandler: {
		type: Function
	  },
	  deleteHandler: {
		type: Function
	  },
	  showBackdrop: {
		type: Boolean,
		default: true
	  },
	  closeOnOuterClick: {
		type: Boolean,
		default: false
	  },
	  showClose: {
		type: Boolean,
		default: true
	  },
	  height: String,
	  width: {
		type: String,
		default: '1100px'
	  },
	  isBeta: {
		type: Boolean,
		default: false
	  },
	  isValid: {
		type: Boolean,
		default: true
	  },
	  fixIndexes: {
		type: Boolean,
		default: true
	  }
	},
	data() {
	  return {
		show: false,
		backdrop: this.showBackdrop,
		currentStep: 1,
		steps: [
				{key: 1, label: this.$t('text.cropAndResize'), icon: 'mdi-crop', status: 'complete'},
				{key: 2, label: this.$t('text.focalPoint'), icon: 'mdi-image-filter-center-focus-weak', status: 'incomplete'},
				{key: 3, label: this.$t('text.imageOverview'), icon: 'mdi-eye', status: 'incomplete'}
			]
	  }
	},
	mounted() {
		console.log('mounted dialog -stepper', this.stepper)
	},
	watch: {
		show(n) {
			if (this.fixIndexes) this.fixZindexes(n)
		},
		currentStep(n) {
			console.log('step-changed', n)
			this.$emit('step-changed', n)
		}
	},
	methods: {
	  confirm() {
		let close = true
		if (this.confirmHandler && typeof this.confirmHandler === 'function') {
			close = this.confirmHandler();
		}
		if (close) {
			this.closeDialog();
		}
	  },

	  nextStep() {
		this.currentStep++
		
		if (this.currentStep > 3) {
			this.confirm()
			this.currentStep = 1
		}
		this.handleStep(this.currentStep)
	  },

	  previousStep() {
		this.currentStep--
		this.handleStep(this.currentStep)

		if (this.currentStep < 1) {
			this.cancel()
			this.currentStep = 1
		}
	  },
  
	  cancel() {
		if (this.cancelHandler && typeof this.cancelHandler === 'function') {
		  this.cancelHandler();
		}
		this.closeDialog();
	  },
  
	  remove() {
		if (this.deleteHandler && typeof this.deleteHandler === 'function') {
		  this.deleteHandler();
		}
		this.closeDialog();
	  },
  
	  closeDialog() {
		if (this.closeHandler && typeof this.closeHandler === 'function') {
		  this.closeHandler();
		}
		this.show = false;
		this.currentStep = 1
		this.handleStep(this.currentStep)
	  },
  
	  backdropClick() {
		if (this.closeOnOuterClick) {
		  this.closeDialog();
		}
	  },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup']) 
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		},
		handleStep(selectedStep) {
			for (let step of this.steps) {
				step.status = step.key <= selectedStep ? 'complete' : 'incomplete'
			}
		},
	},

	  beforeUnmount () {
		if (this.fixIndexes) this.fixZindexes(false)
	  },
  }
  </script>
  
  <style scoped lang="scss">
  .backdrop {
	position: fixed;
	height: calc(100vh - 64px);
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	top: 64px;
	left: 0;
  }
  
  .dialog-div {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 1100px;
	height: min(700px, calc(100vh - 20px));
	max-width: calc(100vw - 20px);
  
	background-color: #ffffff;
	border-radius: 10px !important;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999;
	overflow: hidden;
  
	i {
	  margin-right: 5px;
	}
  
	.dialog-header {
	  max-height: 72px;
	}
  
	.dialog-footer {
	  max-height: 60px;
  
	  .gradientButton {
		height: 40px !important;
		min-height: 40px !important;
		margin-left: 10px;
		margin-right: 10px;
		color: #000;
	  }
  
	  .greenButton {
		height: 40px !important;
		margin-left: 10px;
		margin-right: 10px;
	  }
  
	  .redButton {
		height: 40px !important;
		margin-left: 10px;
		margin-right: 10px;
	  }
	}
  }
  
  .dialogDivPrint {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 80%;
	height: 100%;
  
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999;
  }
  
  .scrollable {
	overflow-y: auto;
	overflow-x: hidden;
  }
  
  .beta-top .corner {
	  position: absolute;
	  right: 0px;
	  top: 0px;
	  width: 0px;
	  height: 0px;
	  border-top: 75px solid #ff7f29;
	  border-left: 75px solid transparent;
	  z-index: 9;
  }
  
  .beta-top .beta {
	  position: absolute;
	  right: 5px;
	  top: 15px;
	  text-transform: uppercase;
	  color: white;
	  font-size: 12pt;
	  font-weight: bold;
	  z-index: 10;
	  -ms-transform: rotate(45deg);
	  -webkit-transform: rotate(45deg); 
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  -sand-transform: rotate(45deg);
	  transform: rotate(45deg);
  }

.stepper-header {
  display: flex;
  align-items: center;
}

.stepper-divider {
  flex-grow: 1;
  height: 50px;
  background-color: #ccc;
  border-top-width: 4px; 
  width: 100px;
  margin-left: -28px !important;
  margin-right: -20px !important;
}
.step-divider-active {
  background-color: #62c522;
}

@media (max-width: 1000px) {
  .stepper-divider {
	width: 10px !important;
  }
}

span.v-stepper__step__step {
	margin: 0 !important;
}
.custom-stepper-step span.v-stepper__step__step {
	margin: 0 !important;
}
.v-stepper__step.v-stepper__step--active {
	margin: 0 !important;
}

.step-label {
	// put the text directly under the step icon
	position: absolute;
	top: 45px;
	left: -17px;
	width: 100px;
	text-align: center;
	font-size: 8pt;
	color: #000;
}

body .v-stepper__header {
	padding: 10px;
	height: 80px;
	margin-top: -15px;
	box-shadow: none;
}

.StepBar {
	padding-left: 0px;
	border: none;
}
</style>

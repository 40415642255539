<template>
	<div class="DatePickerCalendar" style="display: flex; gap: 10px;">
		<ColorCalendar
			ref="calendar"
			v-model="selectedDay"
			:calendar="calendar"
			:dates="dates"
			:focusStart="focusStart"
			:focusEnd="focusEnd"
			@focusRangeChanged="focusStart = $event.focusStart; focusEnd = $event.focusEnd"
		/>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import ColorCalendar from './ColorCalendar.vue'

// TODO: params for min and max date
// TODO: disallow certain dates?
// TODO: allow multiple selection?

export default {
	components: { ColorCalendar },
	props: {
		modelValue: [ String, Date ],
	},
	data: () => ({
		selectedDay: null,
		focusStart: moment().startOf('month'),
		focusEnd: moment().endOf('month'),
	}),
	computed: {
		// TODO: move some common concerns of these functions into exports of ColorCalendar
		dates() {
			// TODO
			// find min and max date
			let minDate = '2024-01-01'
			let maxDate = '2030-12-31'
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day').startOf('month')
			const toDate = moment(maxDate).endOf('day').endOf('month')
			const fromMonday = fromDate.clone().startOf('isoWeek')
			const toSunday = toDate.clone().endOf('isoWeek')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = todayDate.startOf('month').startOf('isoWeek').diff(fromDate, 'days')
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			const days = []
			let d = -1
			for (let currentDay = this.focusStart.clone().startOf('isoWeek');
				currentDay <= this.focusEnd.clone().endOf('isoWeek');
				currentDay = currentDay.add(1, 'days')
			) {
				d++
				const day: { day?: any, year?: string, month?: string, dom?: string, [ i: string ]: string | boolean | number } = { day: currentDay.clone()}
				day.weekday = day.day.format('dddd').toLowerCase()
				day.date = day.day.format('YYYY-MM-DD')
				day.dateObject = day.day.toDate()
				day.year = day.day.format('YYYY')
				day.month = day.day.format('MM')
				day.dom = day.day.format('DD')
				day.inRange = day.date >= this.dates.minDate && day.date <= this.dates.maxDate
				day.open = false // TODO?
				day.selected = false // this.isSelected(day)
				day.x = d % 7
				day.y = Math.floor(d / 7)
				days.push(day)
			}
			return days
		},
	},
	watch: {
		selectedDay(day) {
			this.$emit('update:modelValue', day?.date)
		},
		modelValue(date) {
			this.selectedDay = this.calendar.find(d => d.date == date)
		},
	},
	methods: {
		go(day) {
			this.$refs.calendar.go(day)
		},
	},
	beforeMount() {
		if (this.modelValue) {
			// we account for date strings like 2024-10-09T22:00:00.000Z
			const date = moment(this.modelValue).format('YYYY-MM-DD')
			this.selectedDay = { date }
		}
	},
}
</script>

<style scoped>
.DatePickerCalendar { --sel: lightskyblue; --focus: #295dc7; }
</style>
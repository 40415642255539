import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { en, de, fr, it, nl  } from 'vuetify/locale'
import * as components from 'vuetify/components'

export default createVuetify({
	locale: {
		locale: 'en',
		fallback: 'en',
		messages: { en, de, fr, it, nl },
	},
	components: {
		...components,
	}
})
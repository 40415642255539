<template>
	<Application :class="{ ServiceDesigner: true, isSideBarFixed }">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<h1>{{ title }}</h1>
		</template>

		<!-- TODO: use the slot instead of :navigation for the section navigation.
				wrap a comp SideBar2 around to handle the sectionBus concern?
			:clientStatuses="clientStatuses"
		-->
		<SideBar ref="sidebar" displayEditTranslations
			:actions="sidebarActions"
			:translations="translations"
			:notifications="sideBarNotifications"
			@action="handleAction($event)"
			@edit:translations="$refs.translationWizardDialog?.open"
		>
			<SectionIndex />
		</SideBar>

		<div class="content" style="margin-top: 60px">
			<!-- TODO: we actually want a step-like behaviour
				where we have a persisted "max step"
				and anything after this max step would not be shown as red on errors
			-->
			<StepBar2 :showDividers="false" showButtons class="step-bar" />
			
			<OffersStep v-if="packageDetails" v-model="packageDetails" :linkedEntries="linkedEntries" @update:linkedEntries="linkedEntries = $event" :salesChannels="salesChannels" :products="products" />
			<PackageSpecificStep v-if="packageDetails" v-model="packageDetails" :products="products" :linkedEntries="linkedEntries" />
			<TicketsStep v-if="packageDetails" v-model="packageDetails" :linkedEntries="linkedEntries" :salesChannels="salesChannels" :products="products" />
			<ContentStep v-if="packageDetails " v-model="packageDetails" :linkedEntries="linkedEntries" :salesChannels="salesChannels" :products="products" />
			<MiscStep v-if="packageDetails && packageDetails.fields.contentMode?.de == 'product' " v-model="packageDetails" :salesChannels="salesChannels" :products="products" />

			<div style="color: gray; font-size: smaller;">Guestcard ID: {{ packageDetails?.sys?.id?.replace?.(/PT_/, '') ?? 'N/A' }}</div>
			<div style="color: gray; font-size: smaller;">Configurable Product ID: {{ packageDetails?.fields?.peakProductId?.de ?? 'N/A' }}</div>
			<div style="color: gray; font-size: smaller;">Content-Hub Ticket ID: {{ packageDetails?.fields?.contentId?.de ?? 'N/A' }}</div>

			<IfFeatureFlag flag="FEATURE_TRANSLATION_WIZARD">
				<!-- Translations incomplete
					:handleConfirm="confirmUnsavedChanges"
					:handleCancel="handleEditTranslations"
				-->
				<TranslationsIncompleteDialog ref="translationsIncompleteDialog"
					:translations="translations"
				/>
				<TranslationWizardDialog ref="translationWizardDialog" typeName="Package" v-model="packageDetails" @update:modelValue="setPackageDetails" />
			</IfFeatureFlag>

			<QuickWizardDialog ref="quickWizardDialog" v-model="packageDetails" :is-railaway-package="isRailawayPackage" :is-general-package="isGeneralPackage" />

		</div>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Loading from '@/mixins/Loading.vue'
import Dialog from '@/components/common/Dialog.vue'
import SideBar from '@/components/common/SideBar.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import ContactInfo from '@/components/serviceDesigner/ContactInfo.vue'
import GeneralInfo from '@/components/serviceDesigner/GeneralInfo.vue'
import Application from '../Application.vue'
import GuestCardLocation from '@/views/applications/packageDesigner/GuestCardLocation.vue'
import GuestCardSettingsInfo from '@/components/serviceDesigner/GuestCardSettingsInfo.vue'
import IfFeatureFlag from '@/components/ifFeatureFlag/IfFeatureFlag.vue'
import TranslationsIncompleteDialog from '@/components/TranslationsIncompleteDialog.vue'
import TranslationWizardDialog from '@/components/TranslationWizardDialog.vue'
import StepBar2 from './StepBar2.vue'
import SectionIndex from './SectionIndex.vue'
import AddButton from './AddButton.vue'
import TestErrorDispatcher from './TestErrorDispatcher.vue'
import PackageSpecificStep from './PackageSpecificStep.vue'
import OffersStep from './OffersStep.vue'
import ContentStep from './ContentStep.vue'
import TicketsStep from './TicketsStep.vue'
import MiscStep from './MiscStep.vue'
import LanguageSidebar from '../../../components/common/LanguageSidebar.vue'
import TranslateableField from '../../../components/fields/TranslateableField.vue'
import DataDialog from '../../../components/common/DataDialog.vue'
import Alert from '../../../components/common/Alert.vue'
import SkuEditor from './SkuEditor.vue'
import ChildErrorReceiver from './ChildErrorReceiver.vue'
import QuickWizardDialog from '../../../components/packageDesigner/QuickWizardDialog.vue'

const state = { mounting: false }

export default {
	name: 'PackageDesigner',
	components: { SideBar, LanguageFlag, Dialog, GuestCardSettingsInfo, ContactInfo, GeneralInfo,
		Application, GuestCardLocation, IfFeatureFlag, TranslationsIncompleteDialog, TranslationWizardDialog,
		StepBar2, SectionIndex, AddButton, TestErrorDispatcher, PackageSpecificStep, OffersStep, ContentStep, TicketsStep, MiscStep, LanguageSidebar, TranslateableField, DataDialog, Alert, SkuEditor,
		QuickWizardDialog
	},
	mixins: [ Common, Loading, ChildErrorReceiver ],
	provide() {
		const injectedData = {}
		Object.defineProperty(injectedData, 'salesChannels', {
			enumerable: true,
			get: () => this.salesChannels,
		})
		Object.defineProperty(injectedData, 'products', {
			enumerable: true,
			get: () => this.products,
		})
		Object.defineProperty(injectedData, 'packageDetails', {
			enumerable: true,
			get: () => this.packageDetails,
		})
		injectedData.activePage = 'PackageDesigner'
		injectedData.packageId = this.$route.params.id
		injectedData.clientId = this.$store.state.selectedClient.sys.id
		return { injectedData }
	},
	data() {
		return {
			showPreview: false,
			serviceProvider: this.$store.state.selectedServiceProvider,
			currentStep: 1,
			changed: {},
			availabilities: [],
			packageDetails: null,
			linkedEntries: null,
			testValidationValue: '',
			salesChannels: null,
			products: null,
			// TODO: remove this and find a solution for using data dialog without the model or modifying the new package title directly in the data dialog instead of TranslateableField
			dummyModel: {},
		}
	},
	computed: {
		hasAddedValues() {
			return !!this.packageDetails?.fields?.addedValues?.de?.length
		},
		type() {
			return this.packageDetails?.fields?.packageType?.de ?? 'package-ov'
			// TODO: this was here before - why? this.model does not exist, why are we reading from the store here?
			//return this.model?.fields?.packageType?.de ?? this.$store.state?.selectedServiceType?.fields?.ticketType?.de ?? 'package-ov'
		},
		isActive() {
			return this.packageDetails?.fields?.active?.de || this.packageDetails?.fields?.status?.de === 'active'
		},
		isDeactivated() {
			// TODO: is this correct?
			return !this.packageDetails?.fields?.active?.de
		},
		sideBarNotifications() {
			let notifications = []
			return notifications
		},
		sidebarActions() {
			const actions = []

			//Service Provider View
			if (!this.userIsOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', disabled: !!this.childErrors.length && this.isActive, tooltip: this.$t('text.packageHasValidationErrors'), datacy: "save" })

				// TODO: i believe the status state machine is not completely implemented here

				if (!this.isActive && !this.packageDetailsChanged) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'togglePackageStatus', disabled: !!this.childErrors.length, params: 'active', tooltip: this.$t('text.packageHasValidationErrors'), datacy: "activate" })
				}

				if (!this.isActive && this.isDeactivated && !this.packageDetailsChanged) {
					actions.push({ icon: 'mdi-archive', style: 'red', dark: true, title: this.$t('text.archivePackage'), function: 'togglePackageStatus', params: 'archived', datacy: "archived" })
				}

				if (this.isActive && !this.packageDetailsChanged) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivatePackage'), function: 'togglePackageStatus', params: 'deactivated', datacy: "deactivate" })
				}

				// TODO: Not implementing for now. Needs discussion with Kerstin as per Miro app comments
				// if (!this.productIsExternal && this.$store.state.selectedServiceProvider?.sys?.id && this.$store.state.selectedProduct?.sys?.id) {
				// 	actions.push({ icon: 'mdi-plus-box-multiple', style: 'none', dark: false, title: this.$t('text.duplicatePackage'), function: 'duplicatePackage', datacy: "duplicate" })
				// }

				//actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}
			
			//Home Operator View
			if (this.userIsHomeOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (!this.isActive && (!this.$store.state.selectedServiceProvider?.addl || this.$store.state.selectedServiceProvider?.addl?.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deletePackage'), function: 'confirmDelete', datacy: "delete" })

				if (this.isActive) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivatePackage'), function: 'deactivateProduct', params: 'deactivate', datacy: "deactivate" })
				}
				
				actions.push({ icon: 'mdi-archive', style: 'outline', dark: false, title: this.$t('text.archivePackage'), function: 'deactivateProduct', params: 'archive', datacy: "archive" })
				//actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			//External Operator View
			if (this.userIsExternalOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (this.status != 'active' && (!this.$store.state.selectedServiceProvider?.addl || this.$store.state.selectedServiceProvider?.addl?.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				//actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			return actions
		},
		translations() {
			let translationProduct = JSON.parse(JSON.stringify(this.packageDetails))

			return this.getTranslationStatus('Package', translationProduct, 'model')
		},
		title() {
			const name = this.packageDetails?.fields?.name?.de
			return name ? name : this.$t('text.newPackage')
		},
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
		packageDetailsChanged() {
			if (!this.packageDetails || !this.initData) return false
			
			const compareFields = (obj1, obj2) => {
				const fields1 = { ...obj1.fields }
				const fields2 = { ...obj2.fields }
				delete fields1.ptUpdatedAt
				delete fields2.ptUpdatedAt
				return JSON.stringify(fields1) !== JSON.stringify(fields2)
			}
			
			return compareFields(this.packageDetails, this.initData)
		},
		isRailawayPackage() {
			return this.packageDetails?.fields.packageType?.de == 'package-ov' || this.packageDetails?.fields.packageType?.de == 'package-ov-skipass'
		},
		isGeneralPackage() {
			return this.packageDetails?.fields.packageType?.de == 'package-general'
		},
	},
	watch: {
		childErrors: {
			handler() {
				console.log('childErrors', this.childErrors)
			},
			deep: true,
		},
		linkedEntries: {
			async handler() {
				await this.loadProducts()
			},
			deep: true,
		},
	},
	methods: {
		async submit() {
			await this.loadingOverlay(async () => {
				const isValid = this.validate()
				if (!isValid) return

				const packageId = this.packageDetails.sys.id.replace('PT_', '')
				const packageDetails = JSON.parse(JSON.stringify(this.packageDetails))
				const serviceProviderData = {
					id: this.$store.state.selectedServiceProvider.sys.id,
					packageAssignmentMode: this.$store.state.selectedServiceProvider.fields.mainUserAccount?.fields.packageDesignerConfig?.de?.packageAssignmentMode?.de
				}
	
				// map websites to an array of id-s based on the backend package model
				packageDetails.fields.websites.de = packageDetails.fields.websites.de?.map(({ id }) => id) ?? []
				packageDetails.fields.personalisations.de = packageDetails.fields.personalisations.de?.split(',') ?? []
				try {
					await this.$httpPut('/packageTravel/package/' + packageId, { packageDetails, serviceProviderData })
					this.showSuccess(this.$t('text.saved'))
	
					await this.getPackageDetails()
					// change tracking
					// this.initData = JSON.parse(JSON.stringify(this.packageDetails))
				}
				catch (error) {
					if (error.response?.status === 401) this.$emit("show-login")
					this.showError(error)
				}
			}, 'submitPackageUpdate')
		},
		showSuccess(message) {
			this.successTitle = this.$t('text.SUCCESS')
			this.successDetail = message
		},
		confirmDelete() {},
		async togglePackageStatus(status) {
			await this.loadingOverlay(async () => {
				try {
					const clientId = this.$store.state.selectedClient.sys.id
					const { name, active } = await this.$httpPut(`/packageTravel/package/${this.packageDetails.sys.id}/status?clientId=${clientId}`, { status, packageContents: this.packageDetails })
					this.packageDetails.fields.active.de = active
					this.packageDetails.fields.status.de = status
					this.packageDetails.fields.name.de = name
					this.showSuccess(this.$t('text.statusUpdated'))

					// change tracking
					this.initData = JSON.parse(JSON.stringify(this.packageDetails))
				}
				catch (error) {
					if (error.response?.status === 401) this.$emit('show-login')
					this.showError(error)
				}
			}, 'status')
		},
		togglePreview() {
			this.showPreview = !this.showPreview
		},
		goback() {
			this.$router.push('/packages')
		},
		showError(error) {
			const detail = error?.response?.data?.error ?? error?.error ?? error

			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
		},
		async loadSalesChannels() {
			await this.loadingOverlay(async () => {
				const clientId = this.$store.state.selectedClient.sys.id
				const r = await this.$httpGet(`/packageTravel/getSalesChannels?clientId=${clientId}`)
				this.salesChannels = r.salesChannels
			}, 'salesChannels')
		},
		async getPackageDetails() {
			await this.loadingOverlay(async () => {
				const clientId = this.$store.state.selectedClient.sys.id
				const packageId = this.$route.params.id
				const { entry, linkedEntries } = await this.$httpGet(`/packageTravel/package/${clientId}/${packageId}`)
				this.packageDetails = entry
				this.linkedEntries = linkedEntries
			}, 'details')

			// keep track of the original package details for change tracking purposes
			this.initData = JSON.parse(JSON.stringify(this.packageDetails))
			console.log('packageDetails', this.packageDetails)
			console.log('linkedEntries', this.linkedEntries)
		},
		setPackageDetails(data) {
			this.packageDetails = data
		},
		async loadProducts() {
			const skus = []
			for (const av of Object.values(this.linkedEntries ?? [])) {
				// TODO: is this snake_case right?
				if (av?.sys?.content_type?.sys?.id !== 'AddedValue') continue
				skus.push(...av?.fields?.sku?.de ?? [])
			}
			if (!skus.length) return
			await this.loadingOverlay(async () => {
				const clientId = this.$store.state.selectedClient.sys.id
				const r = await this.$httpPost(`/packageTravel/products?clientId=${clientId}`, { skus, type: this.type })
				this.products = r.products
				this.products = r.products
			}, 'products')
		},
		validate() {
			let isValid = true
			if (!this.packageDetails.fields.name.de) {
				isValid = false
				this.showError(this.$t('text.packageNameRequired'))
			}
			return isValid
		}
	},
	async mounted() {
		// ATT: we need some weird workaround to debounce loading
		// i am not sure exactly why, but the v-navigation-drawer in Sidebar causes remounting 102 times for this comp
		if (state.mounting == false) {
			state.mounting = true
			await this.getPackageDetails()
			// we need to do this in nexttick, so the newPackageDialog is added to the DOM
			await this.loadProducts()
			/*
			if (!this.$store.state.locales?.length) {
				await this.$root.app.getLocales()
			}
			*/
			await this.loadSalesChannels()

			if (this.$route.query.isNew) {
				this.$refs.quickWizardDialog.open()
			}

			state.mounting = false
		}
	},
}
</script>

<style scoped>
h1 { flex-shrink: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 13pt; font-weight: bold; }
.content { padding-top: 1px; width: 100%; }
.step-bar { position: fixed; right: 0; left: 0; top: 76px; background: white; }
button.forTable { border: 1px solid #1c455e; color: #1c455e; border-radius: 4px; padding: 8px 15px; font-size: 16px; line-height: initial; }
button.forTable:hover { background: #1c455e33; }
button.forTable .v-icon { zoom: 0.85 !important; }
</style>

<style>
.todo { background-color: yellow; font-size: 10px; }
</style>
<template>
	<div class="info">
		<span :showCharCount="showCharCount" class="status" :class="{ error }" v-if="value !== null && value !== undefined">
			{{ value ? value.length : 0 }}
			{{ $t('text.characters') }}
		</span>
		<span class="capacity" v-if="max && !min">{{ $t('text.genericMaxChars', { number: max }) }}</span>
		<span class="capacity" v-if="!max && min">{{ $t('text.genericMinChars', { number: min }) }}</span>
		<span class="capacity" v-if="max && min">{{ $t('text.genericMinMaxCharacters', { min, max }) }}</span>
	</div>
</template>

<script>
export default {
	name: 'FieldConstraintsInfo',
	props: {
		value: [ Number, String ],
		error: Boolean,
		min: Number,
		max: Number,
		showCharCount: { type: Boolean, default: false, },
	},
}
</script>

<style>
.info {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	font-size: 0.875rem;
	margin-top: 0.5rem;
	color: rgb(65, 77, 99);
}

.info .status.error {
	color: var(--col-error);
}
</style>

<template>
  <div>
    <v-label>
      <slot name="label"></slot>
    </v-label>
    <div style="width: 290px;">
      <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        transition="scale-transition"
      >
        <template #activator="{ props }">
          <v-text-field
            variant="outlined"
            density="compact"
            hide-details
            readonly
            :data-cy="dataCy"
            :value="displayDate"
            v-bind="props"
            :error-messages="errors"
          />
        </template>
        <v-date-picker
          v-model="dateModel"
          :min="min"
          hide-actions
          color="green-lighten-1"
        />
      </v-menu>
    </div>
    <slot name="helperText"></slot>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      default: undefined,
    },
    errors: {
      type: String,
      default: undefined,
    },
    dataCy: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isOpen: false,
      dateModel: null,
    };
  },
  computed: {
    displayDate() {
      if (!this.modelValue) {
        return ''
      }
      return this.formatDate()
    },
  },
  watch: {
    dateModel() {
      this.update()
    },
  },
  methods: {
    update() {
      const s = this.formatDate()
      this.isOpen = false
      this.$emit('update:modelValue', s)
    },
    formatDate() {
      let d = this.dateModel ?? new Date(this.modelValue)
      const YYYY = d.getFullYear()
      const MM = (d.getMonth() + 1).toString().padStart(2, '0')
      const DD = d.getDate().toString().padStart(2, '0')
      let s = `${YYYY}-${MM}-${DD}`
      return s
    },
  },
}
</script>

<template>
	<Step id="tickets" icon="mdi-ticket-outline" v-if="modelValue">
		<Section id="tickets" open>
			<TicketsInfo ref="ticketsSection"
				:modelValue="modelValue.fields.tickets.de"
				:pricingType="modelValue.fields.pricingType.de"
			/>
		</Section>
		<Section v-if="isGeneralPackage && modelValue.fields.tickets.de?.length > 0" id="skipassAgeGroupMapping" open>
			<SkipassAgeGroupMapping ref="skipassAgeGroupMappingSection"
				:modelValue="modelValue.fields.tickets.de"
				:linkedEntries="linkedEntries"
			/>
		</Section>
		<Section id="additionalSettings" open>
			<AdditionalSettings ref="additionalSettingsSection" v-model="modelValue.fields.personAmountPerAgeGroup.de" />
		</Section>
	</Step>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import TicketsInfo from './TicketsInfo.vue'
import AdditionalSettings from './AdditionalSettings.vue'
import SkipassAgeGroupMapping from '../../../components/fields/SkipassAgeGroupMapping.vue'
import IfFeatureFlag from '../../../components/ifFeatureFlag/IfFeatureFlag.vue'
import Common from '../../../mixins/Common.vue'

export default {
	components: { Section, Step, TicketsInfo, AdditionalSettings, SkipassAgeGroupMapping, IfFeatureFlag },
	mixins: [ Common ],
	props: {
		modelValue: Object,
		linkedEntries: Object,
		salesChannels: Array,
		products: Array,
	},
	mounted() {
		if (!this.modelValue.fields.personAmountPerAgeGroup?.de)
			this.modelValue.fields.personAmountPerAgeGroup = { de: [] }
	},
}
</script>

<style scoped>
</style>

<template>
  <div>
    <div v-if="app === 'BusinessProfile' || app === 'ServiceDetail'" justify="center">
      <Disclosure :title="$t('text.categoryInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" data-cy="categoryInfo" ref="SECTION_categoryInfo" :expanded="true">
		<div class="field left-border">
          <v-label>{{$t('text.productCategory')}} <span class="error-text">({{ $t('text.required') }})</span></v-label>
          <div class="row" style="z-index:2; position:relative">
            <div class="chips-selector" ref="selector" @click.stop="openDialog" :data-cy="dataCy">
              <div class="chips-search">
                <div class="selected-chips">
                  <span v-for="chip in chips" :key="'selected_product_category_' + chip.id">{{ chipsLabel(chip) }}</span>
                </div>
              </div>
            </div>

            <Dialog ref="productCategoriesDialog"
                    :confirmLabel="$t('text.confirmSelection')"
                    :cancelLabel="$t('text.discardChanges')"
                    :confirm-handler="confirmDialog"
                    :width="'800px'"
                    :title="$t('text.productCategory')">
              <template #content>
                <InheritedSelector
                    :items="items"
                    :selected-items="selectedItems"
                    :sub-level-name="'subProductCategories'"
                />
              </template>
            </Dialog>
          </div>
          <p v-if="categoryErrors.length"><span style="color:#ff5252 !important;">{{categoryErrors[0]}}</span></p>
          <p class="helpText" v-html="$t('text.productCategoryDesc')"/>
        </div>
      </Disclosure>
    </div>
    <div v-else class="field left-border">
      <v-label>{{$t('text.productCategory')}} <span class="error-text">({{ $t('text.required') }})</span></v-label>
      <div class="row" style="z-index:2; position:relative">
        <div class="chips-selector" ref="selector" @click.stop="openDialog" :data-cy="dataCy">
          <div class="chips-search">
            <div class="selected-chips">
              <span v-for="chip in chips" :key="'selected_product_category_' + chip.id">{{ chipsLabel(chip) }}</span>
            </div>
          </div>
        </div>

        <Dialog ref="productCategoriesDialog"
                :confirmLabel="$t('text.confirmSelection')"
                :cancelLabel="$t('text.discardChanges')"
                :confirm-handler="confirmDialog"
                :width="'800px'"
                :title="$t('text.productCategory')">
          <template #content>
            <InheritedSelector
                :items="items"
                :selected-items="selectedItems"
                :sub-level-name="'subProductCategories'"
            />
          </template>
        </Dialog>
      </div>
      <p v-if="categoryErrors.length"><span style="color:#ff5252 !important;">{{categoryErrors[0]}}</span></p>
      <p class="helpText" v-html="$t('text.productCategoryDesc')"/>
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import InheritedSelector from '@/components/common/selectors/InheritedSelector.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'

// TODO: the binding in this class is quite non-standard, we  should fix this.
//       this already created issues where all dropdowns got broken.

export default {
  name: "ProductCategoriesSelector",

  components: {
    Dialog,
    InheritedSelector,
    Disclosure
  },
  mixins: [Common],

  props: {
    app: String,
    dataPayload: Object,
    updateModel: Boolean,
    disabled: Boolean,
    serviceProvider: Object,
    workflowStatus: String,
    dataCy: { type: String, default: undefined }
  },

  data() {
    return {
      categories: [],
      selectedCategories: [],
      initSelectedCategories: [],
      items: [],
      chips: [],
      selectedItems: [],
      sectionMessage: {
        error: false,
        message: ''
      },
      categoryErrors: [],
      // we need this flag because sometimes the validateAllFields method is called from the parent via refs and sometimes the code within this method is executed before the method initialSelectedCategories in created 
      initialCategoriesSet: false
    }
  },
  watch: {
	  selectedCategories: { 
      handler(newModel) {
        this.selectedCategoriesChanged()
    },
    immediate: true
    },
    async updateModel() {
      this.model = this.valueToModel(this.dataPayload)
      await this.getProductCategories()
      this.initialSelectedCategories()
    },
    categoryErrors(n) {
      if (n.length > 0) {
        this.setSectionError(this.sectionMessage, this.$t('text.categoryRequiredError'))
      } else {
        this.resetSectionError(this.sectionMessage)
      }
    },
  },
  async created() {
    this.model = this.valueToModel(this.dataPayload)
    await this.getProductCategories()
    this.initialSelectedCategories()
  },
  computed: {
    getChips() {
      // TODO: return the chips based on the selected categories
      return null
    },
  },
  methods: {
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    sendData() {
      const data = {
        productCategories: {
          de: []
        },
      }
      //Remove duplicates
      data.productCategories.de = Array.from(new Set(this.selectedItems))

      for (let selectedItem of this.selectedItems) {
        delete selectedItem.sys.selected
      }

      const selected = this.removeSubCategoriesAndIconsForDiff(this.selectedItems)
      const initSelected = this.removeSubCategoriesAndIconsForDiff(this.initSelectedCategories)

      data.changed = !isEqual(selected, initSelected)
      
      return data
    },
    validateAllFields(action) {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)
      if (!this.validateCategory(action)) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.categoryRequiredError'))
			}
	
			return allFieldsAreValid
		},
    validateCategory(action) {
        let isValid = true
				this.categoryErrors = []
				if (this.initialCategoriesSet && this.selectedItems.length === 0) {
          isValid = false
					this.categoryErrors.push(this.$t('text.categoryRequiredError'))
				}
        const spTrustLevel = this.serviceProvider.fields.trustLevel?.de
        if (!this.userIsOperator && !isValid && spTrustLevel <= 2 && this.workflowStatus !== 'approved' && action !== 'approve' && action !== 'activate') {
          isValid = true
          this.categoryErrors = []
        }
        return isValid
		},
    selectedCategoriesChanged() {
      if (this.selectedCategories && this.selectedCategories.length > 0) {
        if (!this.items || !this.items.length) {
          this.items = JSON.parse(JSON.stringify(this.categories))
          this.selectedItems = JSON.parse(JSON.stringify(this.selectedCategories))
        }
        this.chips = [];
        this.initialSelect(this.items, this.selectedItems, null)
        this.addMissingParentCategories()
        this.addChipsIntoInput()
      }
    },
    removeSubCategoriesAndIconsForDiff(data) {
      //Remove Subcategories for lodash compare otherwise it returns a false positive
      const copyData = JSON.parse(JSON.stringify(data))
      if (copyData?.length) {
        for (const category of copyData) {
          delete category?.fields?.subProductCategories
          delete category?.fields?.icon
        }
      }
      return copyData
    },
    openDialog() {
      if (this.disabled) return
      this.$refs.productCategoriesDialog.show = true;
      this.items = JSON.parse(JSON.stringify(this.categories))
    },
    chipsLabel(chip) {
      return chip.count ? chip.title[this.selectedLocale] + ' +' + chip.count : chip.title[this.selectedLocale];
    },
    confirmDialog() {
      this.chips = []
      this.selectedItems = []
      this.updateSelected();
      this.addChipsIntoInput()
      this.validateCategory()

      this.$forceUpdate();
      return true
    },
    editSelected(item) {
      this.selectedItems.push(item)
    },
    updateSelected(items) {
      items = items || this.items;
      for (const item of items) {
        if (item.sys.selected === true) {
          this.editSelected(item);
        }

        if (item.fields?.subProductCategories?.de) {
          this.updateSelected(item.fields.subProductCategories.de);
        }
      }
    },
    addChipsIntoInput(subCategories, categoriesCount) {
      if (!subCategories) {
        for (const item of this.items) {
          if (!categoriesCount && categoriesCount !== 0) {
            if (item.sys.selected) {
              this.chips.push({
                title: item.fields.title,
                id: item.sys.id,
                count: item.fields.subProductCategories ? this.addChipsIntoInput(item.fields.subProductCategories.de, 0) : 0
              });
            } else if (item.fields.subProductCategories?.de && item.fields.subProductCategories?.de?.length > 0) {
              this.addChipsIntoInput(item.fields.subProductCategories.de);
            }
          }
        }
      } else if (subCategories) {
        for (const subCategory of subCategories) {
          if (!categoriesCount && categoriesCount !== 0 && subCategory.sys.selected) {
            this.chips.push({
              title: subCategory.fields.title,
              id: subCategory.sys.id,
              count: subCategory.fields.subProductCategories ? this.addChipsIntoInput(subCategory.fields.subProductCategories.de, 0) : 0
            });
          } else {
            if (subCategory.sys.selected) {
              ++categoriesCount;
            }
          }
        }
        return categoriesCount;
      }
    },
    initialSelect(items, selectedItems, parentCategory) {
      if (items) {
        for (const item of items) {
          if (item.fields) {
              if (selectedItems.some(el => el.sys?.id === item.sys?.id)) {
                item.sys.selected = true

                if (parentCategory) {
                  parentCategory.sys.selected = true
                }
              }

              if (item.fields.subProductCategories?.de && selectedItems) {
                this.initialSelect(item.fields.subProductCategories.de, selectedItems, item)
              }
            }
          }
      }
    },
    addMissingParentCategories() {
      for (const item of this.items) {
        var haveParentCategory = false

        if (item.sys.selected) {
          for (const selectedItem of this.selectedCategories) {
            if (selectedItem.sys.id === item.sys.id) {
              haveParentCategory = true
              break
            }
          }
          
          if (!haveParentCategory) {
            this.selectedItems.push(item)
          }
        }
      }
    },
    initialSelectedCategories() {
      const clientAssignment = this.model.fields.clientAssignments.de.find(
        (el) => el.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id
      )

      this.selectedCategories = clientAssignment?.fields?.productCategories?.de ?? []
      this.initSelectedCategories = JSON.parse(JSON.stringify(this.selectedCategories))
      this.initialCategoriesSet = true
    },
    async getProductCategories() {
			try {
        this.categories = this.$store.state.selectedClient.fields.productCategories.de.filter(x => x.fields?.mainCategory?.de === true)
				if (this.categories) {this.categories.sort(this.compare)}

				if (this.categories?.length > 0) {
					for (let categories of this.categories) {
						if (categories.fields?.subProductCategories?.de) {categories.fields.subProductCategories.de.sort(this.compare)}
					}
				}
			}
			catch (error) {
				this.showError(error)
			}
		}
  },
}
</script>

<style scoped lang="scss">
.chips-selector {
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.86);
  }

  .chips-search {
    i {
      position: absolute;
      right: 0;
      top: 0;
      height: 48px;
      width: 48px;
      cursor: pointer;
      transition: all .3s cubic-bezier(.25, .8, .5, 1);

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .selected-chips {
    min-height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
      border: thin #dddddd solid !important;
      color: #000;
      padding: 7px 18px;
      border-radius: 10px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 36px;
      align-items: center;
      background: rgb(245, 245, 245);
      background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

</style>

<template>
	<ProfileColorCalendar v-model="profiles" ref="calendar" multiple
		v-if="profiles"
		:calcProfiles="calcProfiles"
		@paint="paint"
		:startExpanded="hasNoPrices"
		:itemVisible="(profile) => mode == 'exception' && profile.dates !== undefined || mode == 'timespan' && profile.dates == undefined"
	>
		<template #header>
<!-- TODO: remove - this is only for testing
-->
<button @click="calcPeaksolutionPriceList">calcPeaksolutionPriceList</button>
			<AddButton :buttonText="mode == 'exception' ? 'exception' : 'timeSpan'" @click="addProfile" dataCy="add-timespan" />
			<div style="display: flex; gap: 10px; flex-wrap: wrap; height: 30px; overflow: hidden;" class="header">
				<div style="width: 20px;" />
				<div style="width: 180px;">{{ $t('text.seasonName') }} <HelpIcon keyPrefix="Season_name" /></div>

				<div style="display: flex; gap: 5px;" v-if="mode == 'timespan'">
					<div class="startdate">{{ $t('text.startDate') }} <HelpIcon keyPrefix="Season_start" /></div>
					<div class="enddate">{{ $t('text.endDate') }} <HelpIcon keyPrefix="Season_end" /></div>
				</div>
				<div class="weekdays" v-if="mode == 'exception'">{{ $t('text.weekdays') }} <HelpIcon keyPrefix="Season_weekdays" /></div>

				<div class="dates" v-if="mode == 'exception'">{{ $t('text.dates') }} <HelpIcon keyPrefix="Season_dates" /></div>
			</div>
		</template>
		<template #item-header="{ profile: timespan }">
			<div style="display: flex; gap: 10px; flex-wrap: wrap;">
				<!-- TODO: how to cleverly "connect" the date inputs with the calendar?
					- click one of the selected dates -> add it to the exceptions
					- click one of the unselected dates -> add it to the available list
					- click one of the exceptions -> remove it from the exceptions
					- click one of the weekday headers -> add/remove all of that timespan.weekdays
					- how to set start/end dates? when focus is in the start / end field? day menu + set start / set end?
					- mark start / end input red on invalid input
					- marking a date -> select the corresponding timespan
				-->
				<input type="text" style="width: 180px;" v-model="timespan.name" />
				<input type="date" style="width: 130px;" v-if="!timespan.dates" v-model="timespan.start" @focus="timespanFocus = 'start'; go(timespan.start); selectedDay = null" @blur="timespanFocus = null" @keyup="go(timespan.start)" />
				<input type="date" style="width: 130px;" v-if="!timespan.dates" v-model="timespan.end" :min="timespan.start" @focus="timespanFocus = 'end'; go(timespan.end); selectedDay = null" @blur="timespanFocus = null" @keyup="go(timespan.end)" />
				<slot name="timeslotDetail" v-bind:timespan="timespan" v-bind:weekdays="weekdays"></slot>
				<!-- TODO: use detail-slot in a wrapper comp once this is finished -->
				<mys-tags v-if="!timespan.dates" v-model="timespan.weekdays"
					style="flex: 1 0; min-width: 200px;"
					:options="weekdays"
					:itemLabel="(wd) => typeof wd == 'string' ? $t('text.' + wd)?.substring?.(0, 2)?.toUpperCase?.() : wd"
					emptyLabel="allWeekdays"
				/>
				<MultiDatePicker v-if="timespan.dates" v-model="timespan.dates" multiple
					:profile="timespan"
					:profiles="profiles"
					:calcProfiles="calcProfiles"
				/>
			</div>
		</template>
		<template #item-body="{ profile: timespan }">
			<PriceMatrix v-model="timespan.prices" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
		</template>
		<template #day-hover="{ day }">
			<div v-if="day?.profiles">
				<div style="display: flex; margin-bottom: 10px; gap: 10px;">
					<h1 style="flex: 1; margin: 0; padding: 0; font-size: larger; font-weight: bold; line-height: 100%;">
						{{ $t('text.prices') }}
					</h1>
					<div style="white-space: nowrap; color: silver;">{{ day.date }}</div>
				</div>
				<PriceMatrixMerged :modelValue="calcPriceMatrix(day)" :profiles="profiles" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
			</div>
		</template>
		<!-- TODO: put this on the ProfileCal instead? i think we dont need a hint slot here..
		<template #selected-item-hint>
			{{ $t('text.paintOntoTheCalendar') }} &rarr;
		</template>
		-->
		<template #validation v-if="mode == 'timespan'">
			<!-- TODO: orange -->
			<div v-for="overlap of overlaps" :key="overlap.profile.id" class="validationMessage">
				<div style="font-size: smaller;">
					<span :style="{ backgroundColor: overlap.profile.color }" class="profile">{{ overlap.profile.name }}</span>
					overlaps with
					<span v-for="o, i of overlap.overlaps" :key="o.profile.id">
						<span :style="{ backgroundColor: o.profile.color }" class="profile">{{ o.profile.name }}</span>
						({{ o.span.start }} - {{ o.span.end }})
						<span v-if="i < overlap.overlaps.length - 1"> and </span>
					</span>
				</div>
			</div>
		</template>
	</ProfileColorCalendar>
</template>

<script lang="ts">
import AddButton from './AddButton.vue'
import DraggableDisclosureList from './DraggableDisclosureList.vue'
import PriceMatrix from './PriceMatrix.vue'
import ProfileColorCalendar from './ProfileColorCalendar.vue'
import PriceMatrixMerged from './PriceMatrixMerged.vue'
import { defaultColors } from './ColorPalette.vue'
import MultiDatePicker from './MultiDatePicker.vue'
import HelpIcon from './HelpIcon.vue'
import { PricingTimespan, mergePricesInto, calcPriceMatrix, intersects, timespanMatches, calcOverlaps, calcPeaksolutionPriceList } from '../../../../../shared/seasonal-prices'

// TODO: should probably be moved to PriceMatricCalendar

// TODO: make painting switchable in ProfileCal
// TODO: ProfileCal deletes wrongly - in "multiple" case it needs to clear the arrays instead of nulling

export default {
	components: { ProfileColorCalendar, AddButton, PriceMatrix, DraggableDisclosureList, PriceMatrixMerged, MultiDatePicker, HelpIcon },
	props: {
		mode: { type: String, default: 'timespan' }, // exception | timespan
		modelValue: Array, // PricingTimespan[] (aka profiles)
		salesChannels: Array,
		products: Array,
		ticketTypes: Array,
		ticketCategories: Array,
		basePrices: Array, // TicketPrice[]
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		timespans: [],
		selectedTimespan: null,
		timespanFocus: null, // 'start' | 'end' | 'weekdays' | null
		profiles: null
	}),
	computed: {
		// TODO: also calc overlaps for exceptions? separate func?
		overlaps() {
			return calcOverlaps(this.profiles)
		},
	},
	watch: {
		modelValue() { this.profiles = this.modelValue },
		profiles() { this.$emit('update:modelValue', this.profiles) },
	},
	methods: {
		mergePricesInto,
		intersects,
		timespanMatches,
		calcPriceMatrix(day) {
			return calcPriceMatrix(this.profiles, this.basePrices, day)
		},
// TODO: remove - this is only for testing
calcPeaksolutionPriceList() {
	console.log('calcPeaksolutionPriceList2')
	const r = calcPeaksolutionPriceList(this.profiles, this.basePrices, 'd1', 'adult')
	console.log(r)
},
		go(day) {
			this.$refs.calendar.go(day)
		},
		isSelected(day) {
			return this.timespanMatches(this.selectedTimespan, day)
		},
		isFieldFocussed(day) {
			if (!this.selectedTimespan) return false
			if (this.timespanFocus == 'start' && this.selectedTimespan.start == day.date) return true
			if (this.timespanFocus == 'end' && this.selectedTimespan.end == day.date) return true
			return false
		},
		anyTimespanMatches(day) {
			return this.timespans.some(ts => this.timespanMatches(ts, day))
		},

		// TODO?
		paint() {},
		addProfile() {
			const maxId = Math.max(...this.profiles.map(p => p.id), 1)
			// find first unused color
			const color = defaultColors.find(c => !this.profiles.some(p => p.color == c)) || '#cccccc'
			const newProfile: PricingTimespan = {
				id: maxId + 1,
				color,
				name: 'new',
				start: null,
				end: null,
				weekdays: [],
				dates: this.mode == 'exception' ? [] : undefined,
				prices: [],
			}
			this.profiles.push(newProfile)
			this.$refs.calendar?.expandProfile?.(newProfile)
		},
		calcProfiles(day): number[] {
			const prices = this.calcPriceMatrix(day)
			const r: { [ id: number ]: boolean } = {}
			for (const price of prices) {
				r[ price.sourceId ] = true
			}
			return (Object.keys(r) as any as number[]).filter(n => n > 0).sort((a, b) => a - b)
		},
		hasNoPrices(profile: PricingTimespan) {
			if (!profile?.prices) return true
			return !Object.values(profile.prices).find(p => p.price != 0)
		},
	},
	beforeMount() {
		this.profiles = this.modelValue
	},
}
</script>

<style scoped>
input { border: 1px solid silver; width: 50px; text-align: center; border-radius: 4px; }

.header .weekdays { width: 130px; }
.header .startdate { width: 130px; }
.header .enddate { width: 130px; }

.validationMessage { margin: 2px 4px; }
.validationMessage .profile { padding: 0px 5px; border-radius: 10px; display: inline-block; }
</style>
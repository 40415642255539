<template>
	<div class="FAQView">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<Disclosure :title="$t('text.faq')" :expanded="true" style="margin-top: 65px;">
			<loading v-if="loading" :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading"></loading>

			<FieldSet id="faqs" infoText="faqHelpText">
				<AddButton @click="addFaqGroup" buttonText="faq" />
				<SectionTable
					:columns="tableColumns"
					:items="tableItems"
					:options="options"
					no-data-text="noFaqsAdded"
					@option-edit="editFaq"
					@option-delete="deleteFaq"
				/>
				<TranslationWizardDialog class="translationWizardDialog" ref="translationWizardDialog"
					typeName="FAQGroup"
					v-model="faqGroup"
					@update:modelValue="upsertFaqGroup"
				/>
				<DialogV2 
					ref="deleteDialog"
					:height="'360px'"
					:width="'540px'"
					:title="$t('text.delete')"
					:confirmLabel="$t('text.confirm')"
					:isDelete="true"
					:onConfirm="confirmDelete"
				>
					<template #content>
						<v-row justify="center" align="center" style="padding: 10px">
							<v-col class="warning-icon-col">
								<img class="warningDialogImage" src="@/assets/icons/icon-trash.png" />
							</v-col>
							<v-col class="text-col">
								<span class="dialogText">{{ $t('text.confirmFaqDelete') }}</span>
							</v-col>
						</v-row>
					</template>
				</DialogV2>
			</FieldSet>
		</Disclosure>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import I18nStringDialog from '../../../../components/I18nStringDialog.vue'
import TranslationWizardDialog from '@/components/TranslationWizardDialog.vue'
import TextField from '../../../../components/common/TextField.vue'
import SectionTable from '@/views/applications/packageDesigner/SectionTable.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import AddButton from '@/views/applications/packageDesigner/AddButton.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'

export default {
	name: 'FAQView',
	mixins: [ Common, SettingsMixin ],
	components: { Loading, Alert, Disclosure, I18nStringDialog, TranslationWizardDialog, TextField, SectionTable, DialogV2, AddButton, FieldSet },
	data: () => ({
		startRecCount: 0,
		endRecCount: 25,
		pageRecCount: 25,
		totalRecCount: 0,
		faqGroups: [],
		faqGroup: {},
		faqGroupId: '',
		searchString: '',
		status: 'published',
		sectionMessage: {
			checklistSettings: { error: false, warning: false, message: '' },
		},
		options: [
			{ title: 'text.edit', icon: 'mdi-pencil', action: 'edit' },
			{ title: 'text.delete', icon: 'mdi-delete', action: 'delete' },
		],
	}),
	watch: {
		searchString: {
			handler() {
				console.log('searchString changed', this.searchString)
			},
			deep: true,
		},
	},
	computed: {
		filteredFaqs() {
			return this.faqGroups.filter(faqGroup => {
				const locales = Object.keys(faqGroup.fields.title)
				return locales.some(locale => faqGroup.fields.title[locale].toLowerCase().includes(this.searchString.toLowerCase()))
			})
		},
		tableColumns() {
			return [
				{ id: 'name', type: 'string' },
				{ id: 'questions', type: 'number' },
				{ id: 'lastUpdated', type: 'date' },
				{ id: 'createdAt', type: 'date' },
				// TODO: we need to show this when we have the logic for the translation status
				// { id: 'state', type: 'string' },
				{ id: '', type: 'options', width: '0%' },
			]
		},
		tableItems() {
			return this.filteredFaqs.map(faqGroup => {
				return {
					sys: faqGroup.sys,
					name: faqGroup.fields.title[this.serviceLocale],
					questions: faqGroup.fields.references.de.length,
					lastUpdated: faqGroup.sys.updatedAt,
					createdAt: faqGroup.sys.createdAt,
					// TODO: we need to show this when we have the logic for the translation status
					// state: 'published',
					editMode: false,
				}
			})
		},
	},
	methods: {
		// TODO: needs to be updated to use the backend model or another common model
		faqGroupFactory() {
			return {
				sys: {
					id: '',
					type: 'Entry',
					createdAt: '',
					updatedAt: '',
					contentType: { sys: { id: 'faqGroup', type: 'Link', linkType: 'ContentType', }, },
				},
				fields: {
					title: { de: '', en: '', fr: '', it: '', nl: '', },
					references: { de: [] },
				},
			}
		},
		editFaq(item) {
			this.faqGroup = this.faqGroups.find(faqGroup => faqGroup.sys.id === item.sys.id)
			this.$refs.translationWizardDialog?.open()
		},
		deleteFaq(item) {
			this.faqGroup = this.faqGroups.find(faqGroup => faqGroup.sys.id === item.sys.id)
			this.faqGroupId = this.faqGroup.sys.id
			this.$refs.deleteDialog.open()
		},
		validate() {
			let isValid = true

			for (const locale of this.locales) {
				const faqs = this.faqGroup?.fields?.references?.de ?? []
				for (const faq of faqs) {
					const { question, answer } = faq.fields
					if ((question[locale.code] || answer[locale.code]) && !this.faqGroup.fields.title[locale.code]) {
						isValid = false
						break
					}
					if ((question[locale.code] && !answer[locale.code]) || (!question[locale.code] && answer[locale.code])) {
						isValid = false
						break
					}
				}
				if (!isValid) {
					break
				}
			}
			return isValid
		},
		confirmDelete() {
			this.$refs.deleteDialog.close()
			this.deleteFAQ(this.faqGroupId)
		},
		/* Server Functions */
		getFAQs() {
			this.loading = true

			this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}&skip=${this.startRecCount}&limit=${this.pageRecCount}`).then(res => {
				this.faqGroups = res.faqGroups
				this.loading = false
			}).catch(error => {
				this.errorAlertVisible = true
				this.errorTitle = this.$t('text.ERROR')

				if (error.response) {
					this.errorDetail = error.response.data.error
				} else {
					this.errorDetail = error
				}
			})
		},
		addFaqGroup() {
			this.faqGroup = this.faqGroupFactory()
			this.$refs.translationWizardDialog?.open()
		},
		upsertFaqGroup() {
			if (this.faqGroup.sys.id !== '') {
				this.updateFAQ()
			}
			else {
				this.createFAQ()
			}
		},
		search() {

		},
		async updateFAQ() {
			this.loading = true
			this.showPreview = false
			try {
				if (this.validate()) {
					for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
						for (var locale of this.locales) {
							if (!faq.fields.answer[locale.code]) continue
							if (!(faq.fields.answer[locale.code]).indexOf("\r\n") < 0) continue
							faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace("\r\n", "\n").replace("\r", "\n")
						}
					}
					const res = await this.$httpPut('/faq', this.faqGroup)
					await this.getFAQDetail("update", res.faqGroupId)
				} else {
					this.showError(this.$t('text.faqValidationError'))
				}
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error.response.data?.error)
				}
			}
			this.loading = false
		},
		async createFAQ() {
			this.loading = true
			try {
				const isValid = this.validate()
				if (isValid) {
					for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
						for (var locale of this.locales) {
							// TODO: why is this line different than in updateFAQ?
							if (!faq.fields.answer[locale.code]) continue
							faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace(/\n\r?/g, '\r\n');
						}
					}
					const requestData = {
						serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
						faqGroup: this.faqGroup,
					}
					const res = await this.$httpPost('/faq', requestData)
					await this.getFAQDetail("create", res.faqGroupId)
				} else {
					this.showError(this.$t('text.faqValidationError'))
				}
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async deleteFAQ(faqGroupId) {
			this.loading = true

			try {
				const payload = {
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					faqGroupId: faqGroupId
				}
				const res = await this.$httpDelete('/faq-group', payload)

				if (res?.message === 'cannot delete linked entry') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.faqInUseError')
				} else {
					this.successTitle = this.$t('text.FAQ_DELETED')
					this.successDetail = this.$t('text.faqDeletedSuccess')
					this.faqGroups = this.faqGroups.filter(faqGroup => faqGroup.sys.id !== faqGroupId)
				}
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}

			this.loading = false
		},
		async getFAQDetail(action, faqGroupId) {
			this.loading = true
			try {
				const res = await this.$httpGet(`/faq?faqId=${faqGroupId}`)
				// TODO: status should be connected to the chatGPT integration ??
				this.status = 'published'

				if (action === 'create') {
					this.faqGroups.unshift(res.faqGroup)
					this.successTitle = this.$t('text.FAQ_CREATED')
					this.successDetail = this.$t('text.faqCreateSuccess')
				}
				if (action === 'update') {
					this.successTitle = this.$t('text.FAQ_UPDATED')
					this.successDetail = this.$t('text.faqUpdateSuccess')
				}
			}
			catch (error) {
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
	},
	async mounted() {
		this.getFAQs()
	},
}
</script>

<style scoped>
/* .translationWizardDialog {
	z-index: 9999 !important;
} */
</style>
<template>
	<div>
		<!-- Mobile App View -->
		<div v-if="view == 'mobile'">
			<MobileAppView @toggle-view="toggleView" />
		</div>

		<!-- Web App View -->
		<div v-if="view == 'web'">
			<WebAppView @toggle-view="toggleView" />
		</div>
	</div>
</template>

<script>
import MobileAppView from './MobileAppView.vue'
import WebAppView from './WebAppView.vue'
import Common from '@/mixins/Common.vue'

export default {
	components: { MobileAppView, WebAppView },
	mixins: [ Common ],
	data: () => ({
		view: 'mobile',
	}),
	methods: {
		toggleView() {
			this.view = this.view === 'mobile' ? 'web' : 'mobile'
		}
	},
}
</script>
import { Entity, EntityRepositoryType, ManyToOne, PrimaryKey, Property } from '@mikro-orm/postgresql'
//import { Dto } from '../rmi/entry-decorators'
import { EntityRepository } from '@mikro-orm/postgresql'
import { v4 as uuid } from 'uuid'
import type { Policy } from '../../util/permission-util'

// TODO: move to orm-util?
export class BaseEntity {
	@PrimaryKey()
	id: string = uuid()

	type: string

	// TODO: i cannot insert, i get "null value in column \"created_at\" violates not-null constraint"
	//       but how? i have a default value onCreate..
	@Property({ onCreate: () => new Date() })
	createdAt: Date = new Date()

	@ManyToOne(() => User, { nullable: true, fieldName: 'created_by' })
	createdBy: string

	@Property({ onUpdate: () => new Date() })
	updatedAt: Date = new Date()

	@ManyToOne(() => User, { nullable: true, fieldName: 'updated_by' })
	updatedBy: string

	// TODO: the onUpdate leads to a weird failing query - how to fix this?
	@Property()//{ onUpdate: () => (value: number) => value + 1 })
	version: number = 0

	constructor() {}
}

class RoleRepository extends EntityRepository<Role> {}

@Entity({ repository: () => RoleRepository })
export class Role extends BaseEntity {
	[EntityRepositoryType]?: RoleRepository

	@Property()
	name: string

	constructor(name: string) {
		super()
		this.name = name
	}
}

class UserRepository extends EntityRepository<User> {}

//@Dto()
@Entity({ repository: () => UserRepository })
export class User extends BaseEntity {
	[EntityRepositoryType]?: UserRepository

	@Property()
	name: string

	@ManyToOne()
	role: Role

	@Property({ type: 'json', nullable: true })
	policy: Policy

	// TODO: keycloak id

	constructor(name: string) {
		super()
		this.name = name
	}
}

export class App extends BaseEntity {

// TODO: should we have some kind of ETL for creating apps from contentful?
// TODO: props
// data props in mys db
//    what needs to be joined in the db?
//    what does the server need?
// - clients?
// - server hooks?
// content props in contenthub
//    non-user-content
//    no mys ui needed, only editing only in contenthub
//       this may be required in the future when we allow plugin devs to create their own apps
//    no referential integrity needed
// - name
// - tooltip / description
// - url
// - type
// - icon
// - color
//   -> need to pick color picker for CH
// - permissions?
// - config
// - hooks: app, ...

	constructor() {
		super()
	}
}
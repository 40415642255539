<template>
	<div class="ProFeatureTag">
		<div class="chip">
			<v-icon size="small">mdi-alert-decagram</v-icon>
			{{ $t('text.proFeature') }}
		</div>
		<HelpIcon keyPrefix="proFeature" />
	</div>
</template>

<script lang="ts">
import HelpIcon from './HelpIcon.vue'

export default {
	components: { HelpIcon },
}
</script>

<style scoped>
.ProFeatureTag { display: flex; align-items: center; gap: 12px; color: #0b222f; }
.chip { display: flex; gap: 4px; align-items: center; padding: 2px 7px; border-radius: 4px; background: rgb(162, 226, 249); font-size: smaller; }
</style>
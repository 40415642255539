<script>
import moment from 'moment'
import ContentfulObject from '../common/ContentfulObject.js'
import countries from '@/json/countries.json'
import flatten from 'lodash/flatten'
import sumBy from 'lodash/sumBy'
import isArray from 'lodash/isArray'

export default {
	// TODO: on App this currently does not work as that is the earliest we can provide..
	//       so currently we see an error 'Injection "activePage" not found' on every page load.
	//       we should probably build a wrapper around App or refactor it not to need Common
	//       we could also just move getSchema() to App (its only needed there) and i think then we can remove the Common mixin from App.
	inject: [ 'activePage' ],
	data() { return {
		loading: false,
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		schema: this.$store.state.schema,
	}},
	computed: {
		yesNoOptions() {
			return [
				{ value: true, label: this.$t('text.radioOptionYes') },
				{ value: false, label: this.$t('text.radioOptionNo') },
			]
		},
		locales: {
			get() {
				return this.$store.state.locales
			},
			async set(newValue) {
				await this.$store.commit('setLocales', newValue)
			}
		},
		defaultLocale: {
			get() {
				return this.$store.state.defaultLocale
			},
			async set(newValue) {
				await this.$store.commit('setDefaultLocale', newValue)
			}
		},
		serviceLocale: {
			get() {
				return this.$store.state.serviceLocale
			},
			async set(newValue) {
				await this.$store.commit('setServiceLocale', newValue)
			}
		},
		selectedLocale: {
			get() {
				return this.$store.state.selectedLocale
			},
			async set(newValue) {
				await this.$store.commit('setSelectedLocale', newValue)
			}
		},
		showImpersonationInfo() {
			return this.userIsImpersonating === true ? true : false
		},
		userIsImpersonating() {
			return !!this.$store.state.isImpersonation
		},
		userIsOperator() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator' && this.$store.state.isImpersonation === false
		},
		userIsMainOperator() {
			return this.$store.state.loggedInUser?.fields?.mainOperator?.de && this.$store.state.isImpersonation === false
		},
		userMayEditFields() {
			return !this.userIsOperator || this.userIsHomeOperator
		},
		userIsServiceProviderOrImpersonating() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'serviceprovider' || this.$store.state.isImpersonation === true
		},
		phoneFormat() {
			switch(this.$store.state.userSpecificGerman) {
				case 'ch':
					return '+41 79 123 4567'
				case 'at':
					return '+43 79 123 4567'
				case 'de':
				default:
					return '+49 79 123 4567'
			}
		},
		componentName() {
			return this.$options.name
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDesigner and ClientAssignments currently
		clientAssignments() {
			if (!this.$data.hasOwnProperty('serviceProvider')) return

			// TODO: currently these implementations are the same - in the past we looked at the product clientAssignments on SD.
			//       if this stays the same, we can simplify this function
			if (this.componentName == 'BusinessProfile' || this.activePage == 'BusinessProfile') {
				if (!this.serviceProvider?.sys?.id) return undefined
				return this.serviceProvider.fields.clientAssignments?.de
			}
			if (this.componentName == 'ServiceDesigner' || this.activePage == 'ServiceDesigner') {
				if (!this.serviceProvider?.sys?.id) return undefined
				return this.serviceProvider.fields.clientAssignments?.de
			}
			return undefined
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDetail and ClientAssignments currently
		userIsHomeOperator() {
			if (!this.userIsOperator) return false
			let cas = this.clientAssignments ?? this.$store.state.selectedServiceProvider?.fields?.clientAssignments?.de
			if (!cas) return true
			for (let ca of cas || []) {
				if (!ca.fields?.isHomebase?.de) continue
				for (let client of this.$store.state.loggedInUser.fields.clients.de) {
					if (client.sys.id != ca.fields.client.de.sys.id) continue
					return true
				}
				return false
			}
			return false
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDesigner and ClientAssignments currently
		userIsExternalOperator() {
			if (!this.userIsOperator) return false
			let cas = this.clientAssignments ?? this.$store.state.selectedServiceProvider?.fields?.clientAssignments?.de
			if (!cas) return false
			if (this.userIsHomeOperator) return false
			return true
		},
	
		// Defines the breakpoint form which the SideBar component is changed from fixed to collapsible
		isSideBarFixed() {
			return this.$vuetify.display.mdAndUp
		},
		showAuditLogs() {
			return !!this.$store.state.showAuditLogs
		},
		isGeneralPackage() {
			return this.modelValue?.fields?.packageType?.de === 'package-general' || this.injectedData?.packageDetails?.fields?.packageType?.de === 'package-general'
		},
	},
	methods: {
		getCountries() {
			return countries.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
		},
		sortClients(clients, mp) {
			// the sorting below sorts clients by: homebase client is first in the list; if there are selected clients they are second on the list and the others are sorted alphabetically
			clients.sort((a, b) => {
				if (a.sys.id === mp.fields.homeClient.de.sys.id) return -1
				if (b.sys.id === mp.fields.homeClient.de.sys.id) return 1
				if (this.model[a.sys.id] && !this.model[b.sys.id]) return -1
				if (!this.model[a.sys.id] && this.model[b.sys.id]) return 1
				return a.fields.title.de.localeCompare(b.fields.title.de)
			})
		},
		setSectionError(sectionMessage, message) {
			sectionMessage.error = true
			sectionMessage.message = message
		},
		resetSectionError(sectionMessage, message = '') {
			sectionMessage.error = false
			sectionMessage.message = message
		},
		setSectionWarning(sectionMessage, message) {
			sectionMessage.warning = true
			sectionMessage.message = message
		},
		resetSectionWarning(sectionMessage, message = '') {
			sectionMessage.warning = false
			sectionMessage.message = message
		},
		featureEnabled(featureName) {
			const clientFeature = this.$store.state.selectedClient.fields.features.de.find(x => x.id === featureName)
			return clientFeature?.status === 'enabled' ? true : false
		},
		compare(a, b) {
			const objA = a?.fields?.title?.[this.selectedLocale] ? a?.fields?.title?.[this.selectedLocale]?.toUpperCase() : a?.fields?.title?.de ? a.fields.title.de : a ? a?.de : ''
			const objB = b?.fields?.title?.[this.selectedLocale] ? b?.fields?.title?.[this.selectedLocale]?.toUpperCase() : b?.fields?.title?.de ? b.fields.title.de : b ? b?.de : ''
			return objA ? objA.localeCompare(objB, `${this.selectedLocale}`, {ignorePunctuation: true}) : -1
		},
		compareCountry(a, b) {
			const objA = a?.name[this.selectedLocale] ? a?.priority+a?.name[this.selectedLocale].toUpperCase() : a 
			const objB = b?.name[this.selectedLocale] ? b?.priority+b?.name[this.selectedLocale].toUpperCase() : b 
			return objA && typeof(objA) === 'string' ? objA?.localeCompare(objB, `${this.selectedLocale}`, {ignorePunctuation: true}) : -1
		},
		cfoClean(o) {
			o = ContentfulObject.cleanSys(o)
			return o
		},
		cfoFlatten(o) {
			return ContentfulObject.flatten(o)
		},
		cfoLink(o) {
			return ContentfulObject.link(o)
		},
		// equivalent to contentService.getAssetUrl
		getAssetUrl(asset, locale = 'de') {
			const url = asset.fields?.file?.[locale]?.url
			if (url?.length) {
				if (!url && locale != 'de') return asset.fields.file.de?.url
				if (url.substr(0, 2) == '//') return 'https:' + url
				return url
			}
		},
		// returns a map statusName => true
		// SP: collect status
		// OP: search for the status in the right client
		getStatusMap(object) {
			let status = {}

			for (let ca of object.fields.clientAssignments?.de ?? []) {
				if (ca.fields?.client?.de?.sys?.id && ca.fields?.status?.de) {
					if (this.userIsOperator && ca.fields.client.de.sys.id == this.$store.state.selectedClient.sys.id)
						return { [ca.fields.status.de]: true }
					status[ca.fields.status.de] = true
				}
			}
			return status
		},
		getStatusClient(object) {
			for (const ca of object.fields.clientAssignments?.de ?? []) {
				// TODO: can it happen, that a sp does not have a home client assignment?
				// for the service provider we show the home client status
				if (!this.userIsOperator && !ca.fields.isHomebase?.de) continue
				// for the operator we show the status of his client
				if (this.userIsOperator && ca.fields.client.de.sys.id !== this.$store.state.selectedClient.sys.id) continue

				return ca.fields.status.de
			}

			return 'INVALID'
		},
		// SP: update all statuses
		// OP: search for the status in the right client and update it
		updateStatus(object, status) {
			if (this.userIsOperator) {
				for (let ca of object.fields.clientAssignments?.de ?? []) {
					if (ca.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
						return ca.fields.status.de = status
				}
			} else {
				for (let ca of object.fields.clientAssignments?.de ?? []) {
					ca.fields.status.de = status
				}
			}
		},
		async impersonate(sp) {
			this.loading = true
			try {
				//Set info for audit logging
				await this.$store.commit('setSelectedServiceProvider', sp)
				await this.$store.commit('setIsImpersonation', true)
				await this.$store.commit('setImpersonatedServiceProvider', sp)
				await this.$store.commit('setImpersonationStartTime', moment().toISOString())

				const res = await this.$httpGet(`/serviceprovider/${sp.sys.id}?startImpersonation=true`)

				if (!res.serviceProvider?.fields?.mainUserAccount) throw this.$t('text.impersonationError')

				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
				await this.$store.commit('setImpersonatedServiceProvider', res.serviceProvider)
				await this.$store.commit('setComponents', res.serviceProvider.fields.mainUserAccount.fields.applications.de)
				
				this.$emit("show-dashboard")
			}
			catch (error) {
				if (error.response?.status == 401) {
					this.$emit("show-login")
				}
				else if (error.response) {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response.error
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error
				}
			}
			this.loading = false
		},
		async exitImpersonation() {
			let impersonatedServiceProvider = this.$store.state.impersonatedServiceProvider

			if (!impersonatedServiceProvider || Object.keys(impersonatedServiceProvider).length== 0) {
				await this.$store.commit('setIsImpersonation', false);
			} else {
				const res = await this.$httpPost(`/exit-impersonation`)
			}
			if (this.$store.state.loggedInUser.fields.type.de == 'serviceprovider') {
				await this.$store.commit('setSelectedServiceProvider', this.$store.state.loggedInUser.fields.serviceProvider.de)
			} else {
				await this.$store.commit('setIsImpersonation', false)
				await this.$store.commit('setSelectedServiceProvider', null)
				await this.$store.commit('setImpersonatedServiceProvider', null)
				await this.$store.commit('setPeakProducts', null)
			}

			await this.$store.commit('setComponents', this.$store.state.loggedInUser.fields.applications.de)
			await this.$store.commit('setSelectedComponent', {})

			this.$emit("show-dashboard", 'exitImpersonation')
		},
		$tn(key, param) {
			return this.$t(key).replace('{n}', param)
		},
		$validateGeneric(validation) {
			if (!validation) return []
			// listen to the dirty event as this function is used by computed fields
			validation.$dirty
			const errors = []
			if (validation.required === false) errors.push(this.$tn('text.generic_required'))
			if (validation.minLength === false) errors.push(this.$tn('text.generic_minLength', validation.$params.minLength.min))
			if (validation.maxLength === false) errors.push(this.$tn('text.generic_maxLength', validation.$params.maxLength.max))

//			validation.maxLength || errors.push(this.$tn('text.genericMaxChars'), validation.$params.maxLength.max)

			return errors
		},
		convertLink(input){
			if (input && typeof input === 'string') {
				input = input.replaceAll(/href="(?!http)/g, 'href="http://')
			}
			
			return input
		},
		isDecimal(value, evt) {
			evt = (evt) ? evt : window.event
			//Restrict to only one decimal
			if (evt.key === "." && value.indexOf(".") > -1) {
				evt.preventDefault()
				return false
			} else {
				//Disallow alphas
				if (evt.key !== "." && isNaN(evt.key)) {
					evt.preventDefault()
					return false
				}
			}
			
			return true
		},
		isNaturalNumber(evt) {
			evt = (evt) ? evt : window.event

			//Disallow alphas
			if (isNaN(evt.key)) {
				evt.preventDefault()
				return false
			}
		
			return true
		},
		checkPercentage(value, event) {
			if (this.isDecimal(value, event)) {
				if (event.target.value > 100) {
					event.target.value = 100
					event.preventDefault()
					return false
				}
			}
		},
		validateTextLocales(text) {
			let haveText = false
			if (this.locales) {
				if (this.locales.length === 0) {
				  // if the async locale initialization is not yet done, before this function is executed
				  return true;
				}
				for (const locale of this.locales) {
					if (text[locale.code]?.length > 0 && text[locale.code] !== '<p></p>') {
						haveText = true
						break
					}
				}
			}
			return haveText
		},
		objectValues(object) {
			let haveValues = true
			for (const key of Object.keys(object)) {
				if (object[key].length === 0) {
					haveValues = false
				}
			}
			return haveValues
		},
		validEmail(email) {
			let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
      		return re.test(String(email).toLowerCase())
		},
		showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error
			
			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		requestHeaders() {
			let selectedComponentId = this.$store.state.selectedComponent?.sys?.id ? this.$store.state.selectedComponent.sys.id : ''
				
			//Get Application Id for Service Designer if the current selectedComponent = 'Manage Services'
			if (this.$store.state.selectedComponent?.fields?.title?.en === 'Manage Services') {
				selectedComponentId = this.$store.state.spApplications.find(app => app.fields?.title?.en === 'Service Designer')?.sys?.id
			}

			//Get Application Id for Business Profile if the current selectedComponent = 'Service Providers'
			if (this.$store.state.selectedComponent?.fields?.title?.en === 'Service Providers') {
				selectedComponentId = this.$store.state.spApplications.find(app => app.fields?.title?.en === 'Business Profile')?.sys?.id
			}
			
			return {
				Authorization: 'Bearer ' + this.$store.state.loggedInUser?.kc_token,
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				'mys-user-id': this.$store.state.loggedInUser?.sys?.id ? this.$store.state.loggedInUser.sys.id : '',
				'mys-user-type': this.$store.state.loggedInUser?.fields?.type?.de ? this.$store.state.loggedInUser.fields.type.de : '',
				'mys-client-id': this.$store.state.selectedClient?.sys?.id ? this.$store.state.selectedClient.sys.id : '',
				'mys-app-id': selectedComponentId,
				'mys-tx-id': moment().valueOf()
			}
		},
		checkRequiredForAllLocales(locales, fieldValue) {
			let hasTextForAllLocales = true
			for (const locale of locales) {
				if (!fieldValue[locale.code]?.length) {
					hasTextForAllLocales = false
					break
				}
			}
			return hasTextForAllLocales
		},
		async loadCoreConfig() {
			try {
				const coreConfig = await this.$httpGet(`/content/entry/CORE-CONFIG`)
				await this.$store.commit('setCoreConfig', coreConfig)

			} catch (error) {
				if (error.response?.status == 401)
					return this.$emit("show-login")
				this.showError(error)
			}
    }	,

		// Service Designer
		hasField(sectionName, fieldName, product) {
			let serviceType
			if (product.sys?.id === '') {
				// New Product
				serviceType = this.$store.state.selectedServiceType
			}
			else {
				// Existing Product
				serviceType = product.fields?.serviceType?.de
			}
			if (!serviceType) return true
			return serviceType?.fields?.template?.de?.[sectionName]?.[fieldName]
		},
		validateRefOnActivate(refName, refs) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateAllFields()
			}
			return isValid
		},
		validateRefOnSave(refName, refs, runValidation) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateTitle(runValidation)
			}
			return isValid
		},
		getPattern(patternSource) {
			const parts = patternSource.split('/')
			let images

			switch (parts[0]) {
				case 'red': images = import.meta.glob(`@/assets/patterns/red/*.png`, { eager: true, import: 'default' }); break
				case 'orange': images = import.meta.glob(`@/assets/patterns/orange/*.png`, { eager: true, import: 'default' }); break
				case 'yellow': images = import.meta.glob(`@/assets/patterns/yellow/*.png`, { eager: true, import: 'default' }); break
				case 'green': images = import.meta.glob(`@/assets/patterns/green/*.png`, { eager: true, import: 'default' }); break
				case 'blue': images = import.meta.glob(`@/assets/patterns/blue/*.png`, { eager: true, import: 'default' }); break
				case 'purple': images = import.meta.glob(`@/assets/patterns/purple/*.png`, { eager: true, import: 'default' }); break
			}
			return images[`/src/assets/patterns/${patternSource}`]
		},
		spEnabledSetting(settings, settingToCheck) {
			if (this.userIsOperator) return true

			const setting = settings?.find(setting => setting.id === settingToCheck)
			return setting?.enabled === true ? true : false 
		},
		async getSchema() {
			if (!this.$store.state.schema) {
				const schema = await this.$httpGet(`/schema`)
				await this.$store.commit('setSchema', schema)
			}
		},
		// 'schema' is the old way
		// 'model' is the new model definition way with the Entry framework
		getTranslationStatus(contentType, entry, mode = 'schema') {
			if (isArray(entry)) {
				const allTranslations = flatten(entry.map(singleEntry => this.getTranslationStatus(contentType, singleEntry, mode)))

				return (this.locales ?? []).map(locale => {
					const sameLocales = allTranslations.filter(translation => translation.locale.code === locale.code)
					return { locale, percentage: sumBy(sameLocales, 'percentage') / sameLocales.length }
				})
			}

			let fields
			if (mode == 'schema') fields = this.$store.state.schema?.contentTypes?.[contentType]?.fields
			if (mode == 'model') fields = Object.values(this.$store.state.fieldModels?.find?.(m => m.name == contentType)?.fields ?? {})

			const entryHas = (field, locale) => {
				const val = entry?.fields?.[field.id]?.[locale.code]
				return val?.length && val !== '<p></p>'
			}

			// does any locale have a certain field?
			const anyLocaleHas = {} // field.id => true
			for (const field of fields ?? []) {
				if (mode == 'schema' && field.type != 'I18nString') continue
				if (mode == 'model' && !field.localized) continue
				for (const locale of this.locales ?? []) {
					if (entryHas(field, locale))
						anyLocaleHas[field.id] = true
				}
			}

			const r = []
			for (const locale of this.locales ?? []) {
				let total = 0
				let translated = 0
				const missingFieldIds = []
				for (const field of fields ?? []) {
					if (mode == 'schema' && field.type != 'I18nString') continue
					if (mode == 'model' && !field.localized) continue
					// HACK: define a better way to control language-validation
					if (field.id == 'serviceProviderName') continue
					if (anyLocaleHas[field.id] || field.required) {
						total++
						if (entryHas(field, locale))
							translated++
						else
							missingFieldIds.push(field.id)
					}
				}
				const percentage = !total ? 100 : Math.round(100 * translated / total)
				r.push({ locale, total, translated, percentage, missingFieldIds })
			}
			// console.log('>>> getTranslationStatus', contentType, entry, fields, r)
			return r
		},
		getTranslations(contentType, entry) {
console.warn('DEPRECATED')
			const localisedFields = this.getLocalisedFieldsForTranslation(contentType, entry)

			let translations = []
			for (const locale of this.locales ?? []) {
				let percentage = this.calcTranslationPercentage(locale, localisedFields, entry)

				translations.push({
					locale: locale,
					percentage: Math.round(percentage)
				})
			}
			return translations
		},
		getLocalisedFieldsForTranslation(contentType, entry) {
console.warn('DEPRECATED')
			const fields = this.$store.state.schema?.contentTypes?.[contentType]?.fields
			const localisedFields = fields?.filter(field => field.type === 'I18nString' /*&& field.required*/).map(item => item.id) ?? []
			const filteredFields = []

			for (const field of localisedFields) {
				let haveValue = false
				for (const locale of this.locales ?? []) {
					if (entry?.fields?.[field]?.[locale.code] !== undefined) {
						haveValue = true
						break
					}
				}

				if (haveValue === true) {
					filteredFields.push(field)
				}
			}

			return filteredFields
		},
		calcTranslationPercentage(locale, localisedFields, entry) {
console.warn('DEPRECATED')
			if (!localisedFields?.length) return 100

			let localePercentage = 0
			const percentageSplit = 100 / localisedFields.length

			for (const field of localisedFields) {
	
				if (entry?.fields?.[field]?.[locale.code]?.length && entry?.fields?.[field]?.[locale.code] !== '<p></p>') {
					localePercentage += percentageSplit
				}
			}
			
			return localePercentage
		},
		// combine multiple translations results into one. the resulting percentages are the average
		averageTranslationStatuses(tss) {
			const r = {}
			for (const locale of this.locales ?? [])
				r[locale.code] = { locale, percentage: 0 }
			for (const ts of tss)
				for (const t of ts)
					r[t.locale.code].percentage += t.percentage / tss.length
			return Object.values(r)
		},
		getClientAssignmentForCurrentClient(clientAssignments) {
			return clientAssignments?.find(assignment => assignment.fields.client?.de?.sys?.id === this.$store.state.selectedClient?.sys?.id)
		},
		getLocalizedText(obj, locale) {
			if (obj === null || obj === undefined) return null

			const text = obj[locale]

			if (text) return text

			if (!text) {
				//If the locale is e.g. "en" and the i18n object has locales with format "en-GB", "de_DE", etc.,
				//try to find a locale which starts with "en"
				const locales = Object.keys(obj)
				const similarLocale = locales.find((key) => key.startsWith(locale))

				if (similarLocale) return obj[similarLocale]
			}

			return text
		},
	},
}
</script>

<style lang="scss">
.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}
</style>
<template>
	<div style="display: flex; margin: 10px 0; pointer-events: none;">
		<div style="flex: 1;" />
		<v-btn
			class="gradientButton"
			elevation="0"
			@click.stop="$emit('click', $event)"
			:data-cy="dataCy"
			style="margin-left: 10px; pointer-events: all;"
		>
			<v-icon>{{ update ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
			{{ $t('text.' + (update ? 'btnEdit' : 'btnAdd'), { x: $t('text.' + buttonText) }) }}
		</v-btn>
	</div>
</template>

<script>
export default {
	props: {
		buttonText: { type: String, default: '' },
		update: { type: Boolean, default: false },
		dataCy: { type: String, default: 'add-button' }
	},
}
</script>

<style scoped>
</style>

// see documentation here:
// https://chillimetrix.alturos.com/confluence/display/MYS/MYS+-+Pattern+-+RMI

// Executive classes form the top layer of the services.
// they also replace the classical controllers in the MVC pattern,
// while the technical concerns to the controller are handled by the rmi-controller.

// UI:				TestRmi >
// Framework (UI):		TestExecutive(container) >
// Framework (Server):		rmi.controller > rmi.service >
// Executive Layer:				TestExecutive >
// other names: Business, Logic, ..
// Technical Layer:					TestTechnical >
// other names? Primitive, Domain, Lego, Blocks, ..
// Storage Layer:						PackagePeaksolutionDatabase >
//											MagentoDatabase

//import { Entry2 } from './entry'
import { proxyClient } from './rmi-client-util'
import { jsonToObject, objectToJson } from './serialization-util'

export class BadRequestError extends Error {
	constructor(public detail: any) {
		super(detail)
	}
}

export class RmiRequest {
	targetClass: string
//	targetState: any
	method: string
	args: any[]

	// TODO? taken from nest.. needed?
	targetKey?: string
}

export function isBrowser() {
	return Object.getPrototypeOf(Object.getPrototypeOf(globalThis)) !== Object.prototype
}

// TODO: what about different scopes?
//       currently every instance will add itself
export const controllers = {}

export default class Executive {
	public isServer: boolean
	public endpoint = '/api2fastify/rmi'
//	private container

	constructor(container) {
//		this.container = container
		this.isServer = !isBrowser()
		if (this.isServer) {
			this.initServer()
		}
		else {
			return proxyClient(this, container)
		}
	}

	// 1. client sends request to server
	async requestToJson(object: RmiRequest) {
		return objectToJson(object)
	}

	// 2. server receives request
	async jsonToRequest(text: string): Promise<RmiRequest> {
		return jsonToObject(text)
	}

	// TODO: maybe the server should respond with a wrapper object instead?
	// 3. server sends response to client
	async resultToJson(object: any) {
		return objectToJson(object)
	}

	// 4. client receives response
	async jsonToResult(text: string) {
		return jsonToObject(text)
	}

	isStreamMethod(request: { method: string, args: any[]}) {
		if (request.args.some(a => a == 'f()')) return true
		const method = request.method.toLocaleLowerCase()
		return method.includes('stream') || method.includes('chunked')
	}

	// Executives may be cached, these methods provide some facilities for that.

	// getKey() may for example return the client id if we want an instance per client id.
	getKey() {}
	// expired() may return true if the instance is no longer valid.
	expired() { return false }

	// lifecycle methods
	initServer() {}
	destroyServer() {}
}
import moment from 'moment'

// this model is used here:
//   product.seasonalPrices: PricingTimespan[]
// and the correlating UI component:
//   <PriceMatrixCalendar v-model="product.seasonalPrices" ... />

export class PricingTimespan {
	id: number
	color: string
	name: string
	start?: string
	end?: string
	weekdays?: number[]
	dates?: string[]
	prices: TicketPrice[]
}

export class TicketPrice {
	ticketType: string
	ticketCategory: string
	price: number
	// only set for merged prices
	sourceId?: number
}

class DayMinimal {
	date: string
	weekday: string
}

// the following functions are used at:
// - the client to calculate the display of the price matrix
// - the server side to convert the price matrix to a price list that Peaksolution needs

export function timespanMatches(timespan: PricingTimespan, day) {
	if (!timespan) return false
	return day.date >= timespan.start
		&& day.date <= timespan.end
		&& (timespan.weekdays.includes(day.weekday) || timespan.weekdays.length == 0)
}

// merges the pricematrig "source" into "target"
// keeps track of sourceId so we know the origin of a price later
export function mergePricesInto(sourceId: number, source: TicketPrice[], target: TicketPrice[]) {
	for (const price of source) {
		if (!price?.price) continue
		const i = target.findIndex(p => p.ticketType == price.ticketType && p.ticketCategory == price.ticketCategory)
		if (i == -1)
			target.push({ ...price, sourceId })
		else
			target[i] = { ...price, sourceId }
	}
}

export function calcPriceMatrix(profiles: PricingTimespan[], basePrices: TicketPrice[], day: DayMinimal): TicketPrice[] {
	const prices: TicketPrice[] = []
	mergePricesInto(-1, basePrices, prices)
	// TODO: is order correct? or should we reverse? or order based on start date?
	for (const profile of profiles as PricingTimespan[]) {
		if (day && profile.dates) {
			if (profile.dates.includes(day.date) || profile.dates.includes(day.weekday)) {
				mergePricesInto(profile.id, profile.prices, prices)
			}
			continue
		}
		if (!timespanMatches(profile, day)) continue
		mergePricesInto(profile.id, profile.prices, prices)
	}
	return prices
}

export function intersects(a: PricingTimespan, b: PricingTimespan) {
	return a.end > b.start && a.start < b.end
}

export function calcOverlaps(profiles: PricingTimespan[]) {
	const r = {}
	for (const a of profiles) {
		if (a.dates) continue
		for (const b of profiles) {
			if (b.dates) continue
			if (a == b) continue
			if (!intersects(a, b)) continue
			// found overlap
			if (!r[ a.id ]) r[ a.id ] = { profile: a, overlaps: [] }
			const overlapStart = a.start > b.start ? a.start : b.start
			const overlapEnd = a.end < b.end ? a.end : b.end
			r[ a.id ].overlaps.push({
				profile: b,
				span: { start: overlapStart, end: overlapEnd }
			})
		}
	}
	return Object.values(r)
}

export class PeaksolutionPriceListItem {
	// TODO: verify naming + types with Ilia
	// id?
	start: string
	end: string
	price: number
	metadata: string
}

export function calcPeaksolutionPriceList(
	profiles: PricingTimespan[],
	basePrices: TicketPrice[],
	ticketType: String,
	ticketCategory: String,
): PeaksolutionPriceListItem[] {
	// TODO: find real first date (?)
	//       similar for last date
	const start = moment().format('YYYY-MM-DD')
	const end = moment().add(10, 'years').format('YYYY-MM-DD')

	// TODO: this approach is doing a lot of useless work because we calculate the matrix
	//       for every day, but then throw away everything except one matrix cell.
	//       it would be better to calculate for all variants at once and then split..

	// calculate the price matrix for every day in the range
	const dayMatrices: { day: DayMinimal, matrix: TicketPrice[] }[] = []
	for (let date = moment(start); date.isBefore(end); date.add(1, 'day')) {
		const day = {
			date: date.format('YYYY-MM-DD'),
			weekday: date.format('dddd').toLowerCase()
		}
		const matrix = calcPriceMatrix(profiles, basePrices, day)
		dayMatrices.push({ day, matrix })
	}

	const profileNameById = profiles.reduce((acc, profile) => { acc[profile.id] = profile.name; return acc }, {})

	// filter out the prices for the requested ticket type and category
	const dayPrices: { date: string, price: number, profileName: string }[] = []
	for (const dayMatrix of dayMatrices) {
		const price = dayMatrix.matrix.find(p => p.ticketType == ticketType && p.ticketCategory == ticketCategory)
		if (!price) continue
		dayPrices.push({
			date: dayMatrix.day.date,
			price: price.price,
			profileName: profileNameById[ price.sourceId ] ?? 'BASE',
		})
	}

	const prices: PeaksolutionPriceListItem[] = []
	let currentPrice = null
	for (const dayPrice of dayPrices) {
		if (currentPrice && currentPrice.price == dayPrice.price
			&& currentPrice.metadata == dayPrice.profileName
		) {
			currentPrice.end = dayPrice.date
		}
		else {
			currentPrice = {
				start: dayPrice.date,
				end: dayPrice.date,
				price: dayPrice.price,
				metadata: dayPrice.profileName
			}
			prices.push(currentPrice)
		}
	}
	return prices.filter(p => p.metadata != 'BASE')
}
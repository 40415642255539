<template>
	<v-container fluid style="padding: 0px;">
		<v-layout row>
			<!-- Sidebar for step 1 -->
			<div v-show="step === 1" class="fixedSidebar">
			<div class="sidebarText">
				<p class="helpText" v-html="$t('text.cropAndResize')"/>
				<p class="helpText" v-html="$t('text.cropAndResizeHelp')"/>
			</div>

			<v-container>
				<v-row no-gutters>
					<v-col  v-for="aspectRatio in aspectRatios" :key="aspectRatio.value">
						<v-card
							class="ma-2"
							:class="{ 'selected': aspectRatio.value === selectedRatio }"
							max-width="100"
							height="100"
							@click="setAspectRatio(aspectRatio.value)"
							style="display: flex; flex-direction: column; align-items: center;"
							>
							<v-img
								v-if="aspectRatio.value !== -1"
								:width="Math.min(100, aspectRatio.value * 50)"
								:height="Math.min(100 / aspectRatio.value, 50)"
								:aspect-ratio="aspectRatio.value"
								cover
								:src="currentImageUrl"
							></v-img>
							<v-img
								v-else
								:width="Math.min(100, originalImageWidth / originalImageHeight * 50)"
								:height="Math.min(100 / (originalImageWidth / originalImageHeight), 50)"
								:aspect-ratio="originalImageWidth / originalImageHeight"
								cover
								:src="currentImageUrl"
							></v-img>

							<v-card-title class="fixed-title">
								<div>{{ $t(`text.${aspectRatio.text}`) }}</div>
							</v-card-title>
						</v-card>
					</v-col>
				</v-row>
				
				<div class="dimensionsText">
					<v-row>
						<p :class="isImageTooSmall ? 'error-text' : 'helpText'" v-html="$t('text.selectedSize')"/>
						<v-icon v-if="isImageTooSmall" color="red" style="margin-left: 10px;;">mdi-alert-circle</v-icon>
					</v-row>
					<v-row>{{croppedWidth}} x {{croppedHeight}}</v-row>
				</div>

				<v-row>
					<v-col>
						<v-btn class="gradientButtonStretched" @click="resetImage" ><v-icon>mdi-restart</v-icon>{{ $t('text.reset') }}</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<div v-if="isImageTooSmall" class="error-text">{{ $t('text.imageQualityTooLow') }}</div>
					</v-col>
				</v-row>
			</v-container>
			
			<br/>

			</div>
			
			<!-- Sidebar for step 2 -->
			<div v-show="step === 2" class="fixedSidebar px-5">
				<div class="sidebarText">
					<v-label>{{ $t('text.focalPoint') }}</v-label>
					<p class="helpText" v-html="$t('text.focalPointHelp')"/>
					<br />
				</div>
				<v-row>
					<v-col>
						<v-btn class="gradientButtonStretched" @click="resetFocalPoint" ><v-icon>mdi-restart</v-icon>{{ $t('text.reset') }}</v-btn>
					</v-col>
				</v-row>
			</div>

			<!-- Sidebar for step 3 -->
			<div v-show="step === 3" class="fixedSidebar">
				<div class="sidebarText">
					<v-label>{{ $t('text.imageOverview') }}</v-label>
					<p class="helpText" v-html="$t('text.imageOverviewHelp')"/>
					<br />
				</div>
			</div>

			<!-- Step 1 - Image Cropping Area -->
			<div style="background-color: #f2f2f2; flex: 2;">
			<div v-show="step === 1" style="max-height: calc(100vh - 200px)">
				<!-- 20px is the padding and margin of the container and cropper -->
				<vue-cropper
					ref="cropper"
					:src="currentImageUrl"
					alt="Source Image"
					:aspectRatio="selectedRatio"
					:viewMode="2"
					:zoomable="false"
					:toggleDragModeOnDblclick="false"
					:auto-crop-area="1"
					:background="false"
					:max-canvas-width="4096"
					:max-canvas-height="4096"
					@crop="onCropMove($event.detail)"
					containerStyle="height: 640px; max-height: calc(100vh - 200px);"
				>
				</vue-cropper>
			</div>

			<!-- Step 2 - Image Focus Area -->
			<div v-show="step === 2">
					<div class="image-container" :style="{ width: containerWidth + 'px', height: containerHeight + 'px' }" >
						<img :src="currentCroppedImageUrl" :width="imgWidth" :height="imgHeight" :style="{ 'object-position': focalPoint }">
						<div class="grid-container">
							<div v-for="(position, index) in positions" :key="index" :class="['grid-item', { active: focalPoint === position }]" @click="setFocalPoint(position)"></div>
						</div>
					</div>
			</div>

			<!-- Step 3 - Image preview -->
			<div v-show="step === 3" class="pa-5">
				<p class="helpText" style="margin-left: 10px" v-html="$t('text.cropsPreview')"/>
				<v-row>
					<v-col
						v-for="aspectRatio in aspectRatios.slice(1)"
						:key="aspectRatio.value"
						class="mb-4"
						cols="3"
					>
						<div>
							<div class="text-subtitle-2">{{ $t(`text.${aspectRatio.text}`) }}</div>
							<div class="image-container" >
							<v-img width="300" cover :aspect-ratio="aspectRatio.value" :src="currentCroppedImageUrl" style="border-radius:4px"></v-img>
							<div class="grid-container">
								<div v-for="(position, index) in positions" :key="index" :class="['grid-item-preview', { active: focalPoint === position }]" ></div>
							</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</div>
		</div>
		</v-layout>
	</v-container>
</template>
  
<script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import Common from '@/mixins/Common.vue'
  
  export default {
  
	name: 'ImageEditor',
  
	components: { VueCropper },
  
	mixins: [Common],
  
	props: {
	  selectedImage: {
		type: Object,
		required: true
	  },
	  step: {
		type: Number,
		required: true
	  },
	  edit: {
		type: Boolean,
		required: true
	  }
	},
	data() {
	  return {
		aspectRatios: [
		  { text: 'original', value: -1 },
		  { text: 'square1x1', value: 1 },
		  { text: 'landscape16x9', value: 16/9 },
		  { text: 'landscape4x3', value: 4/3 },
		  { text: 'landscape3x2', value: 3/2 },
		  { text: 'portrait3x4', value: 3/4 },
		],
		selectedRatio: -1,
		croppedWidth: 0,
		croppedHeight: 0,
		focalPoint: 'center',
		positions: [
			'top left',
			'top',
			'top right',
			'left',
			'center',
			'right',
			'bottom left',
			'bottom',
			'bottom right',
		],
		currentImageUrl: '',
		currentCroppedImageUrl: '',
		displayedWidth: 0,
		displayedHeight: 0,
		originalImageWidth: 0,
		originalImageHeight: 0,
		dimensionsDetected: false
	  }
	},
	created() {
		this.currentImageUrl = this.edit ? this.selectedImage.fields.media.de.fields.file.de.url : this.selectedImage.fields.file.dataURL
	},
	mounted() {
	  this.focalPoint = this.selectedImage.fields.imageFocusArea?.de ? this.selectedImage.fields.imageFocusArea?.de : 'center'
	  this.croppedDimensions()
	},

	watch: {
		isImageTooSmall: {
			handler: function (val, oldVal) {
				this.$emit('poor-quality', val)
			},
			immediate: true
		},
		croppedWidth: {
			handler: function (val, oldVal) {
				if (val > 0) {
					this.dimensionsDetected = true
				}
			},
			immediate: true
		},
		dimensionsDetected: {
			handler: function (val, oldVal) {
				if (val) {
					this.originalImageHeight = this.croppedHeight
					this.originalImageWidth = this.croppedWidth
				}
			},
			immediate: true
		},
		// when the array aspectRatio changes, this function will run
		selectedRatio: {
			handler: function (val, oldVal) {
				this.croppedDimensions()
			},
			deep: true
		},
		step: {
			handler: function (val, oldVal) {
				if (val === 2) {
					this.currentCroppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
					this.displayedWidth = this.$refs.cropper.getCropBoxData().width
					this.displayedHeight = this.$refs.cropper.getCropBoxData().height
				}
			},
			deep: true
		}
	},

	computed: {
		isImageTooSmall () {
			if (this.croppedWidth > 0 && this.croppedHeight > 0) return this.croppedWidth < 450 || this.croppedHeight < 450
			return true
		},
		containerWidth() {
			return this.displayedWidth	
		},
		containerHeight() {
			return this.displayedHeight
		},
		imgWidth() {
			return this.displayedWidth
		},
		imgHeight() {
			return this.displayedHeight
		},

	},

	methods: {
		setFocalPoint(position) {
			this.focalPoint = position
		},
		getCroppedImageUrl() {
			const canvas = this.$refs.cropper.getCroppedCanvas({
				minWidth: 256,
				minHeight: 256,
				maxWidth: 4096,
				maxHeight: 4096,
				fillColor: '#fff',
				// imageSmoothingEnabled: false,
				// imageSmoothingQuality: 'high',
			});
			const data = this.$refs.cropper.getData()
			
			const dataURL = canvas.toDataURL("image/jpeg", 1);

			return dataURL;
		},
		resetImage() {
			this.selectedRatio = -1;
			this.setAspectRatio(this.selectedRatio);
			this.$refs.cropper.reset();
		},
		onCropMove(detail) {
			this.croppedWidth = Math.round(detail.width)
			this.croppedHeight = Math.round(detail.height)
		},
		setAspectRatio(ratio) {
			this.selectedRatio = ratio
			this.$refs.cropper.setAspectRatio(ratio)
		},
		croppedDimensions() {
			const cropper = this.$refs.cropper
			const canvas = cropper.getData()
			if (!canvas) {
			return null
			}
			
			this.croppedWidth = Math.round(canvas.width),
			this.croppedHeight = Math.round(canvas.height)
		},
		resetFocalPoint() {
			this.focalPoint = 'center'
		}
  },
}
</script>
  
<style scoped>
.aspect-ratio-card:hover {
  background-color: #E6F0FB;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.aspect-ratio-card--selected {
  border: 2px solid #1E88E5 !important;
}

.ratio-img {
  width: 100%;
  height: 50%;
  object-fit: contain;
}

.image-container {
  margin: auto;
  position: relative;
}

.grid-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  
}

.grid-item {
  /* background-color: rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  /* border: 1px dashed rgba(225, 225, 225, 0.5); */
  border: 1px solid rgba(225, 225, 225, 0.5);
  /* border:#dddddd solid thin; */

}
.grid-item-preview {
	cursor: auto;
}

.grid-item.active {
  background-color: rgba(255, 180, 0, 0.4);
}
.grid-item-preview.active {
  background-color: rgba(255, 180, 0, 0.4);
}

.gradientButtonStretched {
  /* display: inline-block; */
  cursor: pointer;
  width: 100%;

  min-height: 48px;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%);
  border: thin #dddddd solid;
  /* justify-content: space-around; */
  align-items: center;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 12pt;
  text-transform: none;
  letter-spacing: 0;
}

.fixedSidebar {
	flex: 1;
	overflow-y: auto;
	max-height: 620px;
}
.sidebarText {
	margin: 20px 12px 0px 12px
}

.dimensionsText {
	margin: 12px 80px 12px 12px;
}

.image-container2 {
    border: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px; /* set a height for the container */
	
  }
  .aspect-ratio-image {
	object-fit: contain;
    /* max-width: 100%;
    max-height: 100%; */
    max-width: 40px;
    max-height: 30px;
	background-size: cover
  }

  .selected {
	border: 1px solid #00aeef !important;
  }

  .fixed-title {
	/* put the title on the bottom of the v-card with an absolute position, no matter the length of the image in the v-card */
	/* position: absolute;
	bottom: 0;
	left: 0;
	right: 0; */
	/* padding: 10px; */
	/* background-color: rgba(0, 0, 0, 0.5); */
	/* color: black;
	font-size: 12px; */
	text-align: center; font-size: 12px;
	line-height: 1.2; 
  }

/* @media (min-width: 960px) {
  .image-container2 {
    height: 150px;
  }
} */

</style>

<template>
	<div style="width: 100%;" ref="root">
		<v-data-table fixed-header
			:headers="ordersHeaders"
			:items="orderItems"
			item-key="from"
			class="elevation-0"
			:items-per-page="1000"
			:item-value="item => item"
		>
			<template #item="{ item }">
				<tr @click="showOrder(item)">
					<td>{{ item.order_id }}</td>
					<td style="white-space: nowrap;">{{ formatDateTime(item.order_date) }}</td>
					<!--<td>{{ item.client_name }}</td>-->
					<td>{{ item.customer.firstname }} {{ item.customer.lastname }}</td>
					<td></td>
				</tr>
			</template>
			<template #bottom></template>
		</v-data-table>
		<p class="helpText" v-html="$t('text.cmOrdersHelp')" />
		<DialogV2 ref="orderDetailDialog"
			title="Order"
			@close="selected = null"
		>
			<template #content>
				<OrderDetailView embedded />
			</template>
		</DialogV2>
	</div>
</template>

<script>
import OrderDetailView from '../../../views/applications/orders/OrderDetailView.vue'
import DialogV2 from '../../common/DialogV2.vue'

export default {
	components: { OrderDetailView, DialogV2 },
	props: {
		product: Object,
		date: String,
		time: String,
	},
	data: () => ({
		orderItems: [],
		selected: null,
	}),
	computed: {
		ordersHeaders() {
			return [
				{ title: 'ID', key: 'orderId', width: '15%', sortable: false },
				{ title: this.$t('text.orderDate') , key: 'orderDate', width: '15%', sortable: false },
				//{ title: this.$t('text.marketplace') , key: 'marketplace', width: '15%', sortable: false },
				{ title: this.$t('text.customerName') , key: 'customerName', width: '15%', sortable: false },
				{ title: '', key: 'viewOrder', width: '5%', sortable: false, align: 'center' },
			]
		},
	},
	methods: {
		// TODO: should we actually use this endpoint to get Reservations instead of orders at first?
		//       http://contingentservice.stage.alturos.com/api/contingent/reservations?from=2023-01-01T00%3A00%3A00Z&spaceId=592584&until=2023-11-01T23%3A00%3A00Z
		async load() {
			// TODO: indicator
			try {
				//this.time = `${ time.split("T")[1].substring(0,5) }:00`

				let sku = ''
				if (this.product?.fields?.clientAssignments?.de?.length) {
					for (const clientAssignment of this.product.fields.clientAssignments.de) {
						if (clientAssignment?.fields?.sku?.de) {
							sku = clientAssignment.fields.sku.de
							break
						}
					}
				}

				const res = await this.$httpGet(`/order-items?sku=${ sku }&eventDate=${ this.date }&timeslot=${ this.time }:00`)
				this.orderItems = res.orderItems

				// TODO: keep mock commented out
				/*
				this.orderItems = [
					{ order_id: '2900015865', order_date: '2023-06-01T09:00:00.000Z', client_name: 'Test', customer: { firstname: 'John', lastname: 'Doe' } },
				]
				*/
			}
			catch (error) {
				console.error(error)
				// TODO
				//this.showError(error)
			}
		},
		showOrder(item) {
			item = JSON.parse(JSON.stringify(item))
			item.client_id = this.$store.state.selectedClient.sys.id
			// NOTE: service currently does not use SP id
			item.service_provider_id = 'TODO'

			this.selected = item
			this.$store.state.selectedOrder = item
			this.$refs.orderDetailDialog.open()
		},
	},
	mounted() {
		this.load()
	},
}
</script>